import React, { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

const CuratedOrdersFinalizedOrdersTab: FunctionComponent = () => {
  return (
    <Box mt={6}>
      <Typography color={'#5D5D5E'} fontSize={'18px'} fontWeight={400}>
        No Finalized orders
      </Typography>
    </Box>
  );
};

export default CuratedOrdersFinalizedOrdersTab;
