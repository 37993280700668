import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';
import { IEnterpriseUser } from 'store/types/enterprise';

interface IResponseEnterprise {
  count: number;
  page: number;
  users: IEnterpriseUser[];
}

const companyUsers = createAsyncThunk<IResponseEnterprise, any>(
  EEnterprise.fetchUsersList,
  async ({ filter, key, ...params }) => {
    const { data } = await instance.get(`enterprise/companies/users`, {
      params: { ...filter, ...params },
    });
    return data;
  },
);

export default companyUsers;
