import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { IOrderBalance } from '../../types/order';
import { IRole } from '../../types/common';
import { ERole } from '../../../models/consts';

interface IOrderBalanceProps {
  id: number;
  role: IRole;
  isPurchase?: boolean;
}

const fetchOrderBalance = createAsyncThunk<IOrderBalance, IOrderBalanceProps>(
  EOrder.getOrderBalance,
  async ({ id, role, isPurchase }) => {
    let url = `orders/${id}/balance`;
    if (
      role.name === ERole.CUSTOMER_ADMIN ||
      role.name === ERole.CUSTOMER ||
      role.name === ERole.CUSTOMER_TEAM_ADMIN
    ) {
      url = isPurchase ? `enterprise/orders/${id}/balance` : `orders/${id}/balance`;
    } else if (role.name === ERole.AGENCY_ADMIN || role.name === ERole.RESOURCE_MANAGER) {
      url = `agency/orders/${id}/balance`;
    } else if (role.name === ERole.LEAD_MANAGER) {
      url = `/lead-managers/orders/${id}/balance`;
    }

    const { data } = await instance.get(url);

    return data;
  },
);

export default fetchOrderBalance;
