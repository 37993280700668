import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { EOrderFilter, ESorting, orderTabStatuses } from 'models/consts';

import { StyledOrdersBlock, StyledTitleOrdersBlock } from './styled';

import { useAppSelector } from 'store/hooks';
import { isOrdersLoadedSelector, orderListSelector } from 'store/selectors/getOrderSelector';
import {
  getPurchaseOrdersSelector,
  hasCurrentPurchaseOrdersSelector,
  loadedPurchaseOrdersSelector,
} from 'store/selectors/getEnterpriseSelector';

import { OrderListTab } from './index';
import PurchaseListPagination from 'views/Purchase/PurchaseList/PurchaseListPagination';
import EmptyList from './EmptyList';

const CurrentOrderListTab: FunctionComponent = () => {
  const waitingOrdersListKey = [EOrderFilter.WAITING_FOR_BILLING, 4, ESorting.ASC].join(':');
  const prepaidOrdersListKey = [EOrderFilter.PREPAID, 4, ESorting.ASC].join(':');

  const waitingOrdersList = useAppSelector(orderListSelector(waitingOrdersListKey));
  const waitingOrdersLoaded = useAppSelector(isOrdersLoadedSelector(waitingOrdersListKey));
  const prepaidOrdersList = useAppSelector(orderListSelector(prepaidOrdersListKey));
  const prepaidOrdersLoaded = useAppSelector(isOrdersLoadedSelector(prepaidOrdersListKey));
  const purchaseOrders = useAppSelector(getPurchaseOrdersSelector);
  const loadedPurchaseOrders = useAppSelector(loadedPurchaseOrdersSelector);
  const hasActivePurchaseOrders = useAppSelector(hasCurrentPurchaseOrdersSelector);

  return (
    <Box sx={{ height: '100%' }}>
      {(!!waitingOrdersList.length || !waitingOrdersLoaded) && (
        <StyledOrdersBlock>
          <StyledTitleOrdersBlock>
            <Typography variant="h3">
              <strong>Waiting For Billing</strong>
            </Typography>
            {!!waitingOrdersList.length && waitingOrdersList.length >= 4 && (
              <MuiLink color="secondary" component={Link} to={'orders/waiting-for-billing'}>
                View All
              </MuiLink>
            )}
          </StyledTitleOrdersBlock>
          <OrderListTab
            limit={4}
            filter={EOrderFilter.WAITING_FOR_BILLING}
            sortStartDate={ESorting.ASC}
          />
        </StyledOrdersBlock>
      )}
      {(!!prepaidOrdersList.length || !prepaidOrdersLoaded) && (
        <StyledOrdersBlock>
          <StyledTitleOrdersBlock>
            <Typography variant="h3">
              <strong>Prepaid</strong>
            </Typography>
            {!!prepaidOrdersList.length && prepaidOrdersList.length >= 4 && (
              <MuiLink color="secondary" component={Link} to={'orders/prepaid'}>
                View All
              </MuiLink>
            )}
          </StyledTitleOrdersBlock>
          <OrderListTab limit={4} filter={EOrderFilter.PREPAID} sortStartDate={ESorting.ASC} />
        </StyledOrdersBlock>
      )}
      {((!!purchaseOrders.length && hasActivePurchaseOrders) || !loadedPurchaseOrders) && (
        <StyledOrdersBlock>
          <StyledTitleOrdersBlock>
            <Typography>Payment Authorization</Typography>
          </StyledTitleOrdersBlock>
          <PurchaseListPagination filter={EOrderFilter.CURRENT_PURCHASE_ORDERS} />
        </StyledOrdersBlock>
      )}
      {!waitingOrdersList.length && !prepaidOrdersList.length && !purchaseOrders.length && (
        <EmptyList />
      )}
    </Box>
  );
};

export default CurrentOrderListTab;
