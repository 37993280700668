import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECustomer } from 'store/constants';
import { IBasicUser } from 'store/types/user';

interface IUpdateCustomer {
  firstName: string;
  lastName: string;
}

const updateCustomerUserProfile = createAsyncThunk<IBasicUser, IUpdateCustomer>(
  ECustomer.updateCustomerUserProfile,
  async payload => {
    const response = await instance.patch(`customer/user-profile`, payload);

    return response.data;
  },
);

export default updateCustomerUserProfile;
