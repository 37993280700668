import { createSlice } from '@reduxjs/toolkit';

import { CommonEntities } from '../types/common';

import { DEFAULT_ENTITY } from '../../models/consts';

import createCuratedOrderDraft from 'store/query/curated-order/createCuratedOrderDraft';
import fetchCuratedOrdersList from 'store/query/curated-order/fetchCuratedOrdersList';
import deleteCuratedOrder from 'store/query/curated-order/deleteCuratedOrder';
import updateCuratedOrderDraft from 'store/query/curated-order/updateCuratedOrderDraft';

export interface CuratedOrdersState {
  curatedOrders: CommonEntities<any[]>;
}

const initialState: CuratedOrdersState = {
  curatedOrders: DEFAULT_ENTITY,
};

const { actions, reducer } = createSlice({
  name: 'curated-orders',
  initialState,
  reducers: {
    /**
     * Resets the curatedOrders state to its default value.
     * @param {object} state - The current state.
     */
    resetCuratedOrders: state => {
      state.curatedOrders = DEFAULT_ENTITY;
    },
  },
  extraReducers: builder => {
    builder
      /**
       * Handles the pending state of creating a curated order draft.
       */
      .addCase(createCuratedOrderDraft.pending, state => {
        state.curatedOrders = {
          ...state.curatedOrders,
          items: state.curatedOrders.items || [], // Ensure items is always an array
          loading: true,
          loaded: false,
        };
      })
      /**
       * Handles the fulfilled state of creating a curated order draft.
       * Updates the curatedOrders with the new data.
       */
      .addCase(createCuratedOrderDraft.fulfilled, (state, action) => {
        const data = action.payload; // Destructure payload for clarity

        state.curatedOrders = {
          items: [data], // Ensure items is always an array
          loading: false,
          loaded: true,
        };
      })
      //Update curated Order
      .addCase(updateCuratedOrderDraft.pending, state => {
        state.curatedOrders = {
          ...state.curatedOrders,
          loading: true,
          loaded: false,
        };
      })
      /**
       * Handles the fulfilled state of updating a curated order draft.
       */
      .addCase(updateCuratedOrderDraft.fulfilled, (state, action) => {
        const data = action.payload;
        state.curatedOrders = {
          items: state.curatedOrders.items.map(item => (item.id === data.id ? data : item)), // Update the specific item
          loading: false,
          loaded: true,
        };
      })

      //fetch curated orders array
      .addCase(fetchCuratedOrdersList.pending, state => {
        state.curatedOrders = {
          ...state.curatedOrders,
          items: state.curatedOrders.items || [], // Ensure items is always an array
          loading: true,
          loaded: false,
        };
      })
      .addCase(fetchCuratedOrdersList.fulfilled, (state, action) => {
        const data = action.payload; // Destructure payload for clarity

        state.curatedOrders = {
          items: data,
          loading: false,
          loaded: true,
        };
      })
      //fetch curated orders array
      .addCase(deleteCuratedOrder.pending, state => {
        state.curatedOrders = {
          ...state.curatedOrders,
          items: state.curatedOrders.items || [], // Ensure items is always an array
          loading: true,
          loaded: false,
        };
      })
      .addCase(deleteCuratedOrder.fulfilled, (state, action) => {
        const { ids } = action.payload;

        state.curatedOrders = {
          ...state.curatedOrders,
          items: state.curatedOrders.items.filter(item => !ids.includes(item.id)),
          loading: false,
          loaded: true,
        };
      });
  },
});

export const { resetCuratedOrders } = actions;
export default reducer;
