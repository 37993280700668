import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';
import { CreateCuratedOrderDraftProps } from './createCuratedOrderDraft';

export type UpdateCuratedOrderDraftProps = CreateCuratedOrderDraftProps & {
  id: number;
};

const updateCuratedOrderDraft = createAsyncThunk(
  EOrder.updateCuratedOrderDraft,
  async (payload: UpdateCuratedOrderDraftProps) => {
    const { data } = await instance.patch('draft-orders', payload);

    return data;
  },
);

export default updateCuratedOrderDraft;
