import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { EEnterprise } from 'store/constants/enterprise';
import { IEnterpriseUser, IInviteUser } from 'store/types/enterprise';
import { ITeam } from 'store/types/teams';
import { ICompany, IRole } from 'models';

export interface IInvitedUserResponse {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean | null;
  lastChangeActiveAt: string | null;
  role: IRole;
  teamId: number;
  team?: ITeam;
  company?: ICompany;
}

const inviteUser = createAsyncThunk<IInvitedUserResponse[], IInviteUser>(
  EEnterprise.invitationUser,
  async values => {
    const { data } = await instance.post('enterprise/companies/users/invite-user', {
      data: [values],
    });

    return data;
  },
);

export default inviteUser;
