import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';
import { Capacitor } from '@capacitor/core';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { Popper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { IExpertiseBasis } from 'models/inner-models';

import CheckIcon from 'styles/icons/CheckIcon';

import BootstrapInput from '../BootstrapInput';
import SortableContainer from './SortableContainer';
import MobileSelect from './MobileSelect';
import { IConsultantExpertises } from '../../../store/types/expertise';
import { InputProps as MuiInputProps } from '@mui/material/Input';
import { StyledPaperBox } from './styled';
import MobileNativePopper from '../MobileNativeTopPopper';

const filter = createFilterOptions<any>({
  matchFrom: 'any',
});

export type FormValues = {
  name: any;
  onChange: (value: any[]) => void;
  value: any;
};

interface AutocompleteProps {
  autoHighlight?: boolean;
  classes?: object;
  disabled?: boolean;
  groupBy?: (option: any) => string;
  getOptionDisabled?: (option: any) => boolean;
  ListboxComponent?: any;
  multiple: true | undefined;
  onInputChange?: (event: SyntheticEvent, value: string) => void;
  renderGroup?: (params: any) => ReactNode;
}

interface IInputProps extends MuiInputProps {
  dataTest?: string;
  disabled?: boolean;
  error?: boolean | undefined;
  readOnly?: true | undefined;
  helperText?: string | false;
  sx?: any;
}

interface ICustomAutocomplete {
  alternativeId?: string;
  autocompleteProps?: AutocompleteProps;
  field: FormValues;
  options: any[];
  // customFilter?: (options: any, inputValue: string) => ITags[] | ICustomFilter[];
  customFilter?: (options: any, inputValue: string) => any[];
  handleChange?: (event: any, fieldName: keyof (IExpertiseBasis | IConsultantExpertises)) => void;
  placeholder?: string;
  isMainTag?: true | undefined;
  inputProps?: IInputProps;
  isSortable?: boolean;
  primaryItem?: number | null;
  onPrimaryItemChange?: (id: number) => void;
  sx?: object;
  isLoading?: boolean;
  CustomOptionView?: any;
  disabledMobile?: boolean;
  freeSolo?: boolean;
  disabled?: boolean;
  CustomBannerView?: any;
  onOpen?: () => void;
  onClose?: () => void;
  mobilesx?: object;
  maxLength?: number;
}

const CustomAutocomplete: FC<ICustomAutocomplete> = ({
  alternativeId,
  autocompleteProps,
  customFilter,
  inputProps: inputCustomProps,
  isSortable,
  field,
  isMainTag,
  options = [],
  handleChange,
  placeholder,
  primaryItem,
  onPrimaryItemChange,
  isLoading,
  CustomOptionView,
  disabledMobile,
  freeSolo = false,
  disabled,
  sx,
  CustomBannerView,
  onOpen,
  onClose,
  mobilesx,
  maxLength,
}) => {
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md')) && !disabledMobile;
  const isMobileNative = Capacitor.isNativePlatform();

  const isSearchable = !!autocompleteProps?.onInputChange;
  const isMultiple = !!autocompleteProps?.multiple;

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Autocomplete
      {...field}
      getOptionDisabled={(option: any) => option.isActive === false || option.disabled}
      {...autocompleteProps}
      value={field.value || (isMultiple ? [] : null)}
      key={`${field.name}-autocomplete`}
      open={isOpen}
      onOpen={() => handleOpen()}
      onClose={() => (mobile ? undefined : handleClose())}
      id={`${alternativeId || field.name}-autocomplete`}
      options={options}
      disableClearable
      disableCloseOnSelect={isMultiple || (mobile && !isSearchable)}
      getOptionLabel={(option: any) => {
        return option?.name || option?.email || option;
      }}
      fullWidth={true}
      renderInput={({
        InputLabelProps,
        inputProps: { onChange, ...inputProps },
        ...params
      }: any) => (
        <BootstrapInput
          {...params}
          inputProps={{ ...inputProps, maxLength }}
          readOnly={isMobileNative && !isSearchable}
          {...inputCustomProps}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (/^(?!.* {2,})[\S ]*$/.test(event.target.value)) {
              onChange(event);
            }
          }}
          sx={sx}
          placeholder={(!field?.value?.length && placeholder) || undefined}
        />
      )}
      onChange={(event: any, newValue: any) => {
        if (handleChange) {
          handleChange(newValue, field.name);
          field.onChange(newValue);
        } else {
          field.onChange(newValue);
        }
        if (mobile && !isMultiple) {
          handleClose();
        }
      }}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        if (customFilter && inputValue && !isLoading) {
          return customFilter(options, inputValue);
        }
        return filtered;
      }}
      filterSelectedOptions={isMultiple && !mobile}
      renderOption={({ id, ...props }: any, option: any) => (
        <Typography
          {...props}
          disabled={props['aria-disabled']}
          key={id}
          component="li"
          sx={{ justifyContent: 'space-between !important' }}
          id={`autocomplete-${option.id}`}
        >
          {CustomOptionView ? (
            <CustomOptionView id={id} option={option} />
          ) : (
            <span>{option.create || option.name || option?.email}</span>
          )}
          {option.isActive === false && <Typography component="span">Coming soon</Typography>}
        </Typography>
      )}
      renderTags={(value: any, getTagProps: any) =>
        (!isSortable &&
          value.map((option: any, index: number) => (
            <Chip
              {...getTagProps({ index })}
              key={getTagProps({ index }).key}
              variant="filled"
              label={option.name || option.email}
              color="secondary"
              icon={isMainTag && option.id === primaryItem ? <CheckIcon /> : undefined}
              onClick={onPrimaryItemChange ? () => onPrimaryItemChange(option.id) : undefined}
              data-test={`autocomplete-chip-${index}`}
            />
          ))) || (
          <SortableContainer
            getTagProps={getTagProps}
            onChange={values => field.onChange(values)}
            value={value}
            dataTest="autocomplete"
          />
        )
      }
      isOptionEqualToValue={(options: any, value: any) => options.id === value.id}
      loading={isLoading}
      PopperComponent={
        !mobile || isSearchable
          ? isMobileNative
            ? MobileNativePopper
            : undefined
          : ({ open, children, className }: any) => (
              <MobileSelect
                className={className}
                isOpen={open}
                multiple={isMultiple}
                placeholder={placeholder}
                setOpen={setOpen}
                mobilesx={mobilesx}
                id={`${field.name}-autocomplete-listbox`}
                dataTest={field.name}
              >
                {children}
              </MobileSelect>
            )
      }
      PaperComponent={
        !mobile || isSearchable
          ? ({ children }: any) => (
              <StyledPaperBox
                sx={{
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%)',
                  maxHeight: '40vh',
                  overflowY: 'auto',
                }}
              >
                {CustomBannerView && CustomBannerView({})}
                {children}
              </StyledPaperBox>
            )
          : (props: any) => (
              <StyledPaperBox sx={{ overflowY: 'auto' }}>
                {CustomBannerView && CustomBannerView({ setOpen })}
                {props.children}
              </StyledPaperBox>
            )
      }
      freeSolo={freeSolo}
      disabled={disabled}
    />
  );
};

export default CustomAutocomplete;
