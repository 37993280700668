import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECustomer } from 'store/constants';
import { IBasicCustomer } from 'store/types/customer';

interface IUpdateCustomer {
  jobTitle?: string;
  location?: string;
  mobilePhone?: string;
  countryIso?: string;
}

const updateCustomer = createAsyncThunk<IBasicCustomer, IUpdateCustomer>(
  ECustomer.updateCustomer,
  async payload => {
    const response = await instance.patch(`customer`, payload);

    return response.data;
  },
);

export default updateCustomer;
