import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';

import RefreshArrowIcon from '@mui/icons-material/Autorenew';

import {
  EOrderFilter,
  ERole,
  ESorting,
  keyStateByRoleName,
  LIMITS,
  orderTabStatuses,
} from 'models/consts';
import { IBasicOrders, IFullOrders } from 'store/types/order';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  hasMoreSelectorSelector,
  isOrdersLoadedSelector,
  isOrdersLoadingSelector,
  orderListSelector,
} from 'store/selectors/getOrderSelector';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';

import fetchOrderList from 'store/query/order/fetchOrderList';

import OrderItem from '../OrderItem/OrderItem';
import Loader from '../../Loader/Loader';
import EmptyList from './EmptyList';
import {
  getPurchaseOrdersSelector,
  hasPastPurchaseOrdersSelector,
  loadedPurchaseOrdersSelector,
} from '../../../store/selectors/getEnterpriseSelector';
import { StyledOrdersBlock, StyledTitleOrdersBlock } from './styled';
import { Typography } from '@mui/material';
import PurchaseListPagination from '../../../views/Purchase/PurchaseList/PurchaseListPagination';

interface IToggleOrders {
  filter: EOrderFilter;
  limit?: number;
  sortStartDate: ESorting;
}

const OrderListTab: FunctionComponent<IToggleOrders> = ({ filter, limit, sortStartDate }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const ordersListKey = (
    (id && [filter, id, sortStartDate]) ||
    (limit && [filter, limit, sortStartDate]) || [filter, 'nonEnterprise', sortStartDate]
  ).join(':');

  const [editableOrder, setEditableOrder] = useState<IBasicOrders | IFullOrders | undefined>(
    undefined,
  );

  const role = useAppSelector(getMeRoleSelector);

  const orderList = useAppSelector(orderListSelector(ordersListKey));
  const orderLoaded = useAppSelector(isOrdersLoadedSelector(ordersListKey));
  const orderLoading = useAppSelector(isOrdersLoadingSelector(ordersListKey));
  const hasMore = useAppSelector(hasMoreSelectorSelector(ordersListKey));
  const purchaseOrders = useAppSelector(getPurchaseOrdersSelector);
  const loadedPurchaseOrders = useAppSelector(loadedPurchaseOrdersSelector);
  const purchaseOrderLoad = keyStateByRoleName[role as ERole] === ERole.CUSTOMER;
  const hasPastPurchaseOrders = useAppSelector(hasPastPurchaseOrdersSelector);

  const loadMoreOrders = useCallback(() => {
    dispatch(
      fetchOrderList({
        params: {
          limit: limit || LIMITS.order,
          offset: orderList?.length,
          sortStartDate: sortStartDate,
        },
        id,
        filter: filter,
        key: ordersListKey,
        role,
      }),
    );
  }, [dispatch, id, filter, limit, orderList, role, sortStartDate, ordersListKey]);

  useEffect(() => {
    if (!orderLoaded && !orderLoading && hasMore) {
      loadMoreOrders();
    }
  }, [hasMore, loadMoreOrders, orderLoaded, orderLoading]);

  return (
    <>
      {!!orderList.length && (
        <>
          <Grid container spacing={3} sx={{ mt: { xs: 0.5 } }}>
            {orderList?.map((order: any) => (
              <Grid lg={4} sm={6} xxl={3} xs={12} key={`order-${order.id}`}>
                <OrderItem
                  order={order}
                  filter={filter}
                  onOrderEdit={() => setEditableOrder(order)}
                />
              </Grid>
            ))}
          </Grid>
          {hasMore && (
            <Box display="flex" my={3} justifyContent="center">
              <Button color="secondary" onClick={loadMoreOrders} startIcon={<RefreshArrowIcon />}>
                Upload more
              </Button>
            </Box>
          )}
        </>
      )}
      {((purchaseOrderLoad && !loadedPurchaseOrders) ||
        (filter === EOrderFilter.PAST_ORDERS &&
          !!purchaseOrders.length &&
          hasPastPurchaseOrders)) && (
        <StyledOrdersBlock>
          <StyledTitleOrdersBlock>
            <Typography>Payment Authorization</Typography>
          </StyledTitleOrdersBlock>
          <PurchaseListPagination filter={EOrderFilter.PAST_PURCHASE_ORDERS} />
        </StyledOrdersBlock>
      )}
      {orderLoading && (
        <Box height={499}>
          <Loader />
        </Box>
      )}
      {!orderList.length && !purchaseOrders.length && !orderLoading && orderLoaded && !hasMore && (
        <EmptyList />
      )}
    </>
  );
};

export default OrderListTab;
