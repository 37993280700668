import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';
import { EAccessRequestsType } from 'models/consts';

export interface ITeamAccessRequestCreation {
  type: EAccessRequestsType;
  teamName?: string;
  requestText?: string;
  companyId?: number;
  userId?: number;
  updatedAt?: string;
  createdAt?: string;
}

const teamAccessRequest = createAsyncThunk<any, ITeamAccessRequestCreation>(
  EEnterprise.teamAccessRequest,
  async accessRequestParams => {
    const { data } = await instance.post(`access-requests`, [{ ...accessRequestParams }]);
    return data;
  },
);

export default teamAccessRequest;
