import getCompaniesLogos from '../query/consultant/getCompaniesLogos';

export enum EConsultant {
  getExperience = 'consultant/getExperience',

  //refactored
  addAvailability = 'consultant/addAvailability',
  addCertificate = 'consultant/addCertificate',
  addOutDates = 'consultant/addOutDates',
  addCertificateFile = 'consultant/addCertificateFile',
  addExperience = 'consultant/addExperience',
  addConsultantExperience = 'consultant/addConsultantExperience',
  addConsultantExpertise = 'consultant/addConsultantExpertise',
  createAgreement = 'consultant/createAgreement',
  deleteOutDates = 'consultant/deleteOutDates',
  deleteCertificate = 'consultant/deleteCertificate',
  fetchCalendar = 'consultant/fetchCalendar',
  fetchCapacities = 'consultant/fetchCapacities',
  fetchConsultant = 'consultant/fetchConsultant',
  getOrderReview = 'consultant/getOrderReview',
  getCertificates = 'consultant/getCertificates',
  getCompaniesLogos = 'consultant/getCompaniesLogos',
  getProductsExpertise = 'consultant/getProductsExpertise',
  getPipeStatus = 'consultant/getPipeStatus',
  getExperiences = 'consultant/getExperiences',
  setExperiences = 'consultant/setExperiences',
  removeCompanyExperience = 'consultant/removeCompanyExperience',
  removeSubExperience = 'consultant/removeSubExperience',
  saveAvatar = 'consultant/saveAvatar',
  updateCertificate = 'consultant/updateCertificate',
  updateAvailability = 'consultant/updateAvailability',
  updateCapacity = 'consultant/updateCapacity',
  updateConsultantExpertise = 'consultant/updateConsultantExpertise',
  updateConsultantExperience = 'consultant/updateConsultantExperience',
  updateExperience = 'consultant/updateExperience',
  updateOutDates = 'consultant/updateOutDates',
  addCVUpload = 'consultant/uploadCV',
  downloadCV = 'consultant/downloadCV',
  downloadAnyConsultantResume = 'consultant/fetchCV',
}
