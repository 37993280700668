import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EOrderFilter, ERole } from 'models/consts';

import { IBasicPreOrder } from '../../types/preOrder';
import { IPagination } from '../../types/inner-models';
import { IRole } from '../../types/common';

import { EPreOrder } from '../../constants';

export interface IOrderPaginated extends IPagination {
  params: IOrderParams;
  filter: EOrderFilter;
  key: string;
  role: IRole;
}

interface IOrderParams {
  limit: number;
  sortStartDate?: string;
  offset?: number;
  statuses?: string[];
}

const fetchPreOrderList = createAsyncThunk<IBasicPreOrder[], IOrderPaginated>(
  EPreOrder.fetchPreOrderList,
  async ({ filter, params, role }) => {
    let url = '/lead-managers/pre-orders';

    if (
      [ERole.AGENCY_ADMIN, ERole.RESOURCE_MANAGER].includes(role.name) &&
      ![EOrderFilter.CURRENT, EOrderFilter.FINISH_ORDERS].includes(filter)
    ) {
      url = '/agency/pre-orders';
    } else if (
      ERole.LEAD_MANAGER === role.name &&
      [EOrderFilter.CURRENT, EOrderFilter.FINISH_ORDERS].includes(filter)
    ) {
      url = '/lead-managers/orders';
    } else if (
      [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER, ERole.CUSTOMER_TEAM_ADMIN].includes(role.name)
    ) {
      url = '/enterprise/pre-orders';
    }

    const { data } = await instance.get(url, { params });

    return data;
  },
);

export default fetchPreOrderList;
