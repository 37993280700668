import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { EOrderFilter, ESorting } from 'models/consts';
import { IBasicUser } from 'store/types/user';
import { IRole } from 'store/types/common';

import fetchOrderList from 'store/query/order/fetchOrderList';
import { isOrdersLoadingSelector, orderListSelector } from 'store/selectors/getOrderSelector';

import { StyledTitleContainer } from './styled';

import OrderItem from '../OrderItem/OrderItem';
import Loader from '../../Loader/Loader';
import EmptyList from '../OrderList/EmptyList';
import { addCustomerOrderId, removeCustomerOrderId } from 'store/reducers/agency';

interface IPurchaseItem {
  id: number;
  role: IRole;
  enterpriseCustomer?: boolean;
  filter: EOrderFilter;
  groupedList: IBasicUser;
  route: string;
  title: string;
}

const GroupedListOrders: FunctionComponent<IPurchaseItem> = ({
  id,
  filter,
  groupedList,
  role,
  route,
  title,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const three = useMediaQuery(theme.breakpoints.between('lg', 'xxl'));

  const key = [filter, id, 'ASC'].join(':') as string;

  const orderList = useAppSelector(orderListSelector(key));
  const orderListLoading = useAppSelector(isOrdersLoadingSelector(key));

  useEffect(() => {
    if (groupedList.id) {
      dispatch(
        fetchOrderList({
          params: {
            limit: (three && 3) || 4,
            sortStartDate: ESorting.ASC,
          },
          id,
          key,
          filter,
          role,
        }),
      );
    }
  }, [dispatch, id, filter, key, groupedList.id, role, three]);

  // on assign order to the specialist
  const handleOrderAssign = () => {
    // check is it last order for customer in this group
    if (orderList.length === 1 && !orderListLoading) {
      // remove customer from unassignedOrders list
      dispatch(
        removeCustomerOrderId({
          customerId: id,
          key: EOrderFilter.UNASSIGNED,
        }),
      );
    }
    // always check is the customer in the currentKey group
    dispatch(
      addCustomerOrderId({
        customerId: id,
        key: EOrderFilter.CURRENT,
      }),
    );
  };

  return (
    <Box mb={3}>
      <StyledTitleContainer>
        <Typography component="h3" variant="h1">
          {title}
        </Typography>
        <MuiLink color="secondary" component={Link} to={`${route}/${id}`}>
          View All
        </MuiLink>
      </StyledTitleContainer>
      {!orderListLoading ? (
        (!!orderList.length && !orderListLoading && (
          <Grid container spacing={3}>
            {orderList?.map((order: any) => (
              <Grid xxl={3} lg={4} sm={6} xs={12} key={`order-${order.id}`}>
                <OrderItem filter={filter} order={order} onOrderAssign={handleOrderAssign} />
              </Grid>
            ))}
          </Grid>
        )) || <EmptyList />
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default GroupedListOrders;
