import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ROUTES from 'router/constants';

import { IBasicConsultant } from 'store/types/consultant';

import { StyledBoxImpersonate } from '../styled';

const FinalizeConsultant: FunctionComponent<{ consultant: IBasicConsultant }> = ({
  consultant,
}) => {
  const navigate = useNavigate();

  const navigateToMyProfile = () => {
    navigate(`${ROUTES.CONSULTANT}`);
  };

  return (
    <>
      {consultant?.callScheduled && !consultant?.isApproved && (
        <StyledBoxImpersonate data-test="finalize-header">
          <Typography variant="body2">
            Your profile is not fully vetted yet. Feel free to&nbsp;
            <Link
              sx={{ color: 'text.primary', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={navigateToMyProfile}
            >
              finalize your profile
            </Link>
            &nbsp;while you wait.
          </Typography>
        </StyledBoxImpersonate>
      )}
    </>
  );
};

export default FinalizeConsultant;
