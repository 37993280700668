import React, { FunctionComponent, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import PlusIcon from 'styles/icons/PlusIcon';
import SortDescIcon from 'styles/icons/SortDescIcon';

import { useAppSelector } from 'store/hooks';

import ROUTES from 'router/constants';
import { EOrderFilter, ESorting } from 'models/consts';

import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import { orderListSelector } from 'store/selectors/getOrderSelector';

import tabOrder from 'components/Order/OrderList/tabOrder';
import { OrderListTab } from 'components/Order/OrderList';
import CurrentOrderListTab from 'components/Order/OrderList/CurrentOrderListTab';
import Typography from '@mui/material/Typography';

const PurchaseList: FunctionComponent = () => {
  const { id } = useParams();

  const role = useAppSelector(getMeRoleSelector);
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = React.useState<EOrderFilter>(EOrderFilter.CURRENT);
  const [sorting, setSorting] = useState<ESorting>(ESorting.ASC);

  const ordersListKey = ((id && [filter, id, sorting]) || [filter, 'nonEnterprise', sorting]).join(
    ':',
  );

  const orderList = useAppSelector(orderListSelector(ordersListKey));

  const handleChangeFilter = (event: any, newFilter: any) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const changeSorting = () => {
    setSorting(sorting => (sorting === ESorting.ASC ? ESorting.DESC : ESorting.ASC));
  };

  return (
    <>
      {!isPhone && (
        <Box pt={2} sx={{ display: { xs: 'flex', md: 'none' } }} justifyContent="flex-end">
          <Button
            id="create-an-order"
            component={Link}
            variant="contained"
            color="secondary"
            startIcon={<PlusIcon />}
            sx={{ height: 44, px: 2.5 }}
            to={ROUTES.ORDER_CREATION + '/1'}
          >
            Create an order
          </Button>
        </Box>
      )}
      <Typography mt={{ xs: 2.5, md: 7 }} variant="h1" data-test="agency-order-list-header">
        My Orders
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1.5}>
        <Tabs
          value={filter}
          variant="scrollable"
          onChange={handleChangeFilter}
          aria-label="Orders"
          textColor="secondary"
          indicatorColor="primary"
        >
          {tabOrder.map(
            tab =>
              tab.roles.includes(role.name) && (
                <Tab disableRipple key={tab.value} value={tab.value} label={tab.label} />
              ),
          )}
        </Tabs>
        {filter !== EOrderFilter.CURRENT && !!orderList.length && (
          <IconButton
            onClick={changeSorting}
            size="small"
            sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
          >
            {sorting === ESorting.DESC ? (
              <SortDescIcon />
            ) : (
              <SortDescIcon sx={{ transform: 'rotateX(180deg)' }} />
            )}
          </IconButton>
        )}
      </Box>
      {filter !== EOrderFilter.CURRENT && <OrderListTab filter={filter} sortStartDate={sorting} />}
      {filter === EOrderFilter.CURRENT && <CurrentOrderListTab />}
    </>
  );
};

export default PurchaseList;
