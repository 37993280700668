import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

interface IApproveOrderComplete {
  order: {
    ids: number[];
  };
  notification: {
    ids: number[];
  };
  purchaseOrderId: number;
}

const approveOrderComplete = createAsyncThunk<AxiosResponse, IApproveOrderComplete>(
  EOrder.approveOrderComplete,
  async ({ order, purchaseOrderId }) => {
    let url = 'orders/approve-complete';
    if (purchaseOrderId) {
      url = 'enterprise/' + url;
    }
    const { data } = await instance.patch(url, order);

    return data;
  },
);

export default approveOrderComplete;
