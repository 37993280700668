import React, { FC, useState, ChangeEvent, FormEvent, useRef } from 'react';
import { RefCallBack } from 'react-hook-form';
import { IconButton, Button, Typography, Box, Stack } from '@mui/material';
import { styled } from '@mui/material';

import { UploadIcon } from 'styles/icons/UploadIcon';

import { useAppDispatch } from 'store/hooks';
import uploadConsultantCV from 'store/query/consultant/addCVUpload';

interface ICVFileUpload {
  creatorId: number;
  onChange: (data: any) => void;
  inputRef?: RefCallBack;
  originalFileName?: string | undefined;
}

const StyledButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  paddingLeft: '10px',
  paddingRight: '10px',
  width: '100% !important',
  maxWidth: '100%',
}));

const CVFileUpload: FC<ICVFileUpload> = ({ creatorId, originalFileName, inputRef, ...field }) => {
  const dispatch = useAppDispatch();

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const localFileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    const fileTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain',
      'application/rtf',
      'application/vnd.oasis.opendocument.text',
    ];
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile) {
      if (!fileTypes.includes(selectedFile.type)) {
        setError('File type not supported. Please upload a PDF, DOC, DOCX, TXT, RTF, or ODT file.');
        setFile(null);
        return;
      }
      if (selectedFile.size > maxSize) {
        setError('File size exceeds the 5MB limit.');
        setFile(null);
        return;
      }
      handleSubmitCV(selectedFile);
    }
  };

  const handleSubmitCV = async (file: File) => {
    await dispatch(uploadConsultantCV({ creatorId, file: file }))
      .unwrap()
      .then((data: any) => {
        setError('');
        setFile(file);
        field.onChange(data.id);
      })
      .catch((e: any) => {
        setError('Something went worng, please try again later');
      });
  };

  const handleIconClick = () => {
    setError('');
    localFileInputRef.current?.click();
  };

  return (
    <Box sx={{ paddingTop: error ? '20px' : undefined, width: '100%' }}>
      <StyledButton
        color="secondary"
        data-test="cv-file-upload"
        disableRipple
        onClick={handleIconClick}
        variant="outlined"
        startIcon={<UploadIcon className="upload-icon" />}
        sx={{ alignSelf: 'center' }}
      >
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {file ? file.name : originalFileName ? originalFileName : 'Upload Resume'}
        </Typography>
        <input
          type="file"
          accept=".pdf,.doc,.docx,.txt,.rtf,.odt"
          className="d-none"
          ref={e => {
            localFileInputRef.current = e;
            if (inputRef) {
              inputRef(e);
            }
          }}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </StyledButton>
      {error && (
        <Typography color="error" style={{ alignItems: 'center' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default CVFileUpload;
