import React, { FunctionComponent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ToastService } from 'services';

import changeUserRole from 'store/query/enterprise/changeUserRole';
import changeUserStatus from 'store/query/enterprise/changeUserStatus';
import teamUsersById from 'store/query/enterprise/teamUsersById';
import resendInviteUser from 'store/query/enterprise/resendInviteUser';
import {
  getEnterpriseUsersByPageSelector,
  hasMoreEnterpriseUsersSelector,
  loadingEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';
import { getCustomerSelector } from 'store/selectors/getUserSelector';

import PlusIcon from 'styles/icons/PlusIcon';

import FilterTeamUsers from 'components/TeamComponents/FilterUsers';
import ModalWrapper from 'components/Modal/Modal';
import AddMember from 'components/TeamComponents/AddMember';
import ManageTable from 'components/TeamComponents/ManageTable';
import ManageListMobile from 'components/TeamComponents/ManageListMobile';
import ToastMessage from 'components/Toast/ToastMessage';
import ReviewAccessRequest from 'components/TeamComponents/ReviewAccessRequest';
import { outputQuery } from 'helpers/decorators';
import { LIMITS } from 'models/consts';
import { IEnterpriseUser } from 'store/types/enterprise';
import { EUsersListModalsTypes } from '../UsersList/constants';
import { IExtendUserCustomer } from 'store/types/customer';

const TeamList: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setModalOpen] = React.useState<string | undefined>(undefined);
  const [activeUser, setActiveUser] = React.useState<IExtendUserCustomer | undefined>(undefined);
  const [page, setPage] = React.useState(0);
  const [firstTimeOpen, setFirstTimeOpen] = React.useState<boolean>(true);

  const customer = useAppSelector(getCustomerSelector);
  const users = useAppSelector(getEnterpriseUsersByPageSelector(page + 1));
  const loading = useAppSelector(loadingEnterpriseUsersSelector);
  const hasMore = useAppSelector(hasMoreEnterpriseUsersSelector);

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      filterByEmail: undefined,
      filterByStatus: undefined,
      filterByRole: undefined,
      property: undefined,
      type: 'asc',
    },
  });

  const { getValues, setValue, watch } = methods;
  const { filterByEmail, filterByRole, filterByStatus, property, type } = getValues();

  useEffect(() => {
    if (customer?.teams?.length) {
      dispatch(
        teamUsersById({
          limit: LIMITS.team,
          offset: 0,
          teamId: customer?.teams[0].id,
        }),
      );
    }
  }, [dispatch, customer?.teams]);

  useEffect(() => {
    if ((firstTimeOpen || hasMore) && !users.length && !loading && customer?.teams?.length) {
      dispatch(
        teamUsersById({
          filter: outputQuery({
            filterByEmail,
            filterByRole,
            filterByStatus,
            property,
            type,
          }),
          limit: LIMITS.team,
          offset: LIMITS.team * page,
          teamId: customer.teams[0].id,
        }),
      )
        .unwrap()
        .then(() => {
          setFirstTimeOpen(false);
        });
    }
  }, [
    dispatch,
    filterByEmail,
    filterByRole,
    filterByStatus,
    hasMore,
    loading,
    property,
    type,
    page,
    users?.length,
    firstTimeOpen,
    customer?.teams,
  ]);

  const handleClickAddTeamMember = () => {
    setModalOpen(EUsersListModalsTypes.ADD_TEAM_MEMBER);
  };
  const handleReviewClick = (row: any) => {
    setModalOpen(EUsersListModalsTypes.REVIEW_ACCESS_REQUEST);
    setActiveUser(row);
  };

  const handleClose = () => {
    setModalOpen(undefined);
    activeUser && setActiveUser(undefined);
  };

  const handleChangeRole = (event: SelectChangeEvent, userId: number) => {
    dispatch(changeUserRole([{ roleId: parseInt(event.target.value), userId }]))
      .unwrap()
      .then(() => {
        ToastService.success(ToastMessage({ title: 'Success', body: 'Role is changed!' }), {
          toastId: userId,
        });
      });
  };

  const handleChangeStatus = (user: IEnterpriseUser, status?: any) => {
    if (status !== 'refresh') {
      dispatch(changeUserStatus([{ userId: user.id }]))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Status is changed!' }), {
            toastId: user.id,
          });
        });
    } else {
      dispatch(resendInviteUser({ email: user.email, roleId: user.role.id }))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Resend invite!' }), {
            toastId: user.id,
          });
        });
    }
  };

  const onSearch = (searchTerm: any) => {
    if (customer?.teams?.length) {
      dispatch(
        teamUsersById({
          filter: searchTerm,
          limit: LIMITS.team,
          offset: 0,
          teamId: customer.teams[0].id,
        }),
      )
        .unwrap()
        .then(() => setPage(0));
    }
  };

  const debouncedSearch = debounce(onSearch, 500);

  const handleSearch = (value: any, field: string) => {
    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type,
      [field]: value,
    };

    debouncedSearch(outputQuery(filter));
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = watch('property') === property && watch('type') === 'asc';

    setValue('property', property);
    setValue('type', isAsc ? 'desc' : 'asc');

    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type: isAsc ? 'desc' : 'asc',
    };

    dispatch(teamUsersById({ filter: outputQuery(filter), limit: LIMITS.team, offset: 0 }))
      .unwrap()
      .then(() => setPage(0));
  };

  const handleChangePage = (newPage: number, limit: number, offset: number) => {
    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type,
    };
    setPage(newPage);
    dispatch(teamUsersById({ filter: outputQuery(filter), limit, offset }));
  };

  return (
    <Box data-test="team-page">
      <Box
        sx={{
          display: 'flex',
          mt: { xs: 3.5, md: 7 },
          mb: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1">Team Access</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClickAddTeamMember}
          startIcon={<PlusIcon />}
          size={(mobile && 'small') || 'medium'}
          data-test="add-team-member-button"
        >
          Add Team Member
        </Button>
      </Box>

      <FormProvider {...methods}>
        <FilterTeamUsers onSearchUsers={handleSearch} />
        {!mobile && (
          <ManageTable
            page={page}
            onChangeRole={handleChangeRole}
            onChangeStatus={handleChangeStatus}
            onChangePage={handleChangePage}
            onRequestSort={handleRequestSort}
            onReviewClick={handleReviewClick}
          />
        )}
        {mobile && (
          <ManageListMobile
            page={page}
            onChangeRole={handleChangeRole}
            onChangeStatus={handleChangeStatus}
            onReviewClick={handleReviewClick}
            onChangePage={handleChangePage}
          />
        )}
      </FormProvider>

      {!!customer?.teams?.length && customer?.teams[0].id && (
        <ModalWrapper
          dialogProps={{
            open: open === EUsersListModalsTypes.ADD_TEAM_MEMBER,
            onClose: handleClose,
          }}
          title={{ title: 'Add Team Member' }}
        >
          <AddMember onClose={() => handleClose()} teamId={customer.teams[0].id} />
        </ModalWrapper>
      )}

      {activeUser && (
        <ModalWrapper
          dialogProps={{
            open: open === EUsersListModalsTypes.REVIEW_ACCESS_REQUEST,
            onClose: handleClose,
          }}
          title={{ title: 'Review Access Request' }}
        >
          <ReviewAccessRequest onClose={handleClose} user={activeUser} />
        </ModalWrapper>
      )}
    </Box>
  );
};

export default TeamList;
