import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';
import { IExtendUserCustomer } from 'store/types/customer';

interface IResponse {
  count: number;
  page: number;
  users: IExtendUserCustomer[];
}

const teamUsersById = createAsyncThunk<IResponse, any>(
  EEnterprise.fetchUsersList,
  async ({ filter, key, teamId, ...params }) => {
    const { data } = await instance.get(`enterprise/companies/teams/${teamId}/users`, {
      params: { ...filter, ...params },
    });
    return data;
  },
);

export default teamUsersById;
