import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { EAgency } from 'store/constants/agency';
import { IAgencyUser } from '../../types/agency';

interface IInviteUserProps {
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
}
const inviteUser = createAsyncThunk<IAgencyUser, IInviteUserProps>(
  EAgency.invitationUser,
  async payload => {
    const { data } = await instance.post('agency/users/invite-user', payload);

    return { firstName: payload?.firstName, lastName: payload?.lastName, ...data };
  },
);

export default inviteUser;
