import React, { FunctionComponent, SyntheticEvent, useState, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';

import { useAppDispatch } from 'store/hooks';
import inviteUser from 'store/query/enterprise/inviteUser';
import instance from 'store/middlewares/api';

import BootstrapInput from 'components/CustomFields/BootstrapInput';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { ITeam } from 'store/types/teams';
import { RolesForTeamManaging, EMAIL_REGEX, LIMITS } from 'models/consts';

interface IAddMember {
  onClose: () => void;
  teamId?: number;
}

const AddMember: FunctionComponent<IAddMember> = ({ onClose, teamId }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [teams, setTeams] = useState<ITeam[] | undefined>(undefined);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({ mode: 'onChange' });

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    await dispatch(
      inviteUser({
        email: data.email,
        roleId: data.role.id,
        teamId: teamId || data.team.id,
      }),
    )
      .unwrap()
      .then(() => onClose());
  };

  const onSearch = async (searchTerm?: any) => {
    try {
      const { data } = await instance.get(`enterprise/companies/teams`, {
        params: { filterByName: searchTerm, limit: LIMITS.team, offset: 0 },
      });
      setTeams(data?.teams);
    } catch (error: any) {
      setTeams([]);
    }
  };

  useEffect(() => {
    if (!teamId) {
      onSearch();
    }
  }, [teamId]);

  const debouncedSearch = debounce(onSearch, 500);
  const handleTeamSearch = (event: SyntheticEvent, value: string) => {
    setLoading(true);
    debouncedSearch(value);
  };

  return (
    <>
      <DialogContent>
        <form id="add-team-member" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <InputLabel>Email</InputLabel>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  inputRef={ref}
                  error={!!errors.email}
                  placeholder="Enter your email"
                  helperText={errors.email?.message || 'Incorrect data'}
                  type="email"
                />
              )}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email',
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <InputLabel>Choose role</InputLabel>
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <CustomAutocomplete
                  autocompleteProps={{
                    multiple: undefined,
                  }}
                  field={field}
                  options={RolesForTeamManaging}
                  placeholder="Select a role"
                />
              )}
              rules={{ required: 'This field is required' }}
            />
          </FormGroup>

          {!teamId && (
            <FormGroup>
              <InputLabel>Team</InputLabel>
              <Controller
                name="team"
                control={control}
                rules={{
                  required: !teamId && 'This field is required',
                }}
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: undefined,
                      onInputChange: handleTeamSearch,
                    }}
                    inputProps={{
                      error: errors.team as any,
                      helperText: (errors.team as any)?.message || 'Incorrect data',
                    }}
                    field={field}
                    options={teams || []}
                    placeholder="Type a team name"
                    isLoading={isLoading}
                    disabledMobile
                  />
                )}
              />
            </FormGroup>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid || isSubmitting}
          color="secondary"
          form="add-team-member"
          fullWidth
          type="submit"
          variant="contained"
          data-test="add-team-member"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AddMember;
