import React, { FunctionComponent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import ROUTES from 'router/constants';
import { useAppDispatch } from 'store/hooks';
import CuratedOrderDetails from 'components/CuratedOrders/CuratedOrderDetails';

import ArrowLeftIcon from 'styles/icons/ArrowLeft';
import CuratedOrdersScope from 'components/CuratedOrders/CuratedOrdersScope';
import { IBasicOrders, IFullOrder } from 'store/types/order';
import { calcWeeks } from 'utils/dateFormatter';
import createCuratedOrderDraft from 'store/query/curated-order/createCuratedOrderDraft';
import updateCuratedOrderDraft from 'store/query/curated-order/updateCuratedOrderDraft';

const CuratedCreateOrder: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isOrderEdit = location?.state?.order;
  const theme = useTheme();

  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isWeeklyCommitment = isOrderEdit?.timeLine?.flexibleHours;

  const methods: any = useForm({
    mode: 'onChange',
    defaultValues: {
      customer: isOrderEdit?.customer ? isOrderEdit.customer : undefined,
      specialist: isOrderEdit?.worker ? isOrderEdit.worker : undefined,
      orderName: isOrderEdit?.name ? isOrderEdit.name : '',
      description: isOrderEdit?.description ? isOrderEdit.description : '',
      customerRate: isOrderEdit?.customerRate ? isOrderEdit.customerRate / 100 : '',
      specialistRate: isOrderEdit?.specialistRate ? isOrderEdit.specialistRate / 100 : '',
      levelId: isOrderEdit?.levelId ? isOrderEdit.levelId : '',
      modules: isOrderEdit?.expertise?.modules ? isOrderEdit?.expertise?.modules : [],
      works: isOrderEdit?.expertise?.works ? isOrderEdit?.expertise?.works : [],
      product: isOrderEdit?.expertise?.product ? isOrderEdit?.expertise?.product : '',
      scheduleType: isOrderEdit?.timeLine
        ? !isWeeklyCommitment
          ? { id: 1, name: 'Weekly Commitment' }
          : { id: 2, name: 'Number of Hours' }
        : '',
      totalHours: isOrderEdit?.timeLine?.allHours
        ? parseInt(isOrderEdit?.timeLine?.allHours)
        : undefined,
      searchStartDate: isOrderEdit?.timeLine?.startDate
        ? dayjs(isOrderEdit?.timeLine?.startDate).utc().format('YYYY-MM-DD')
        : '',
      searchEndDate: isOrderEdit?.timeLine?.endDate
        ? dayjs(isOrderEdit?.timeLine?.endDate).utc().format('YYYY-MM-DD')
        : '',
    },
  });

  const {
    orderName,
    customer,
    specialist,
    description,
    customerRate,
    specialistRate,
    modules,
    works,
    product,
    levelId,
    scheduleType,
    totalHours,
    searchStartDate,
    searchEndDate,
  } = methods.watch();

  const onSubmit = () => {
    const weeks = calcWeeks(searchEndDate, searchStartDate);
    const availabilityWeek = scheduleType?.id === 2 ? Math.ceil(totalHours / weeks) : totalHours;
    const allHours = scheduleType?.id === 1 ? weeks * availabilityWeek : totalHours;

    const payload: any = {
      customerId: customer ? customer?.id : undefined,
      customerEmail: customer ? customer?.email : undefined,
      description: description || undefined,
      name: orderName,
      levelId: levelId || undefined,
      timeLine:
        searchEndDate || allHours || searchStartDate
          ? {
              startDate: searchStartDate || undefined,
              endDate: searchEndDate || undefined,
              allHours: allHours || undefined,
              availabilityWeek: availabilityWeek || undefined,
              flexibleHours: scheduleType?.id === 1 ? false : true,
            }
          : undefined,
      expertise: product
        ? {
            productId: product.id,
            modules: modules.map((item: any) => ({
              moduleId: item.id,
              isPrimary: modules.primaryModule === item.id,
            })),
            works: works.map((item: any) => ({
              workId: item.id,
              isPrimary: works.primaryWorkType === item.id,
            })),
          }
        : undefined,
      workerId: specialist?.id || undefined,
      customerRate: customerRate * 100 || undefined,
      specialistRate: specialistRate * 100 || undefined,
    };

    if (isOrderEdit) {
      dispatch(
        updateCuratedOrderDraft({
          id: isOrderEdit.id,
          ...payload,
        }),
      )
        .unwrap()
        .then(response => {
          if (response) {
            navigate(ROUTES.CURATED_ORDERS_LANDING);
          }
        });
    } else {
      dispatch(createCuratedOrderDraft(payload))
        .unwrap()
        .then(response => {
          if (response) {
            navigate(ROUTES.CURATED_ORDERS_LANDING);
          }
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <Box mt={isiPadOrMobile ? 1 : 2}>
        <Grid mb={isiPadOrMobile ? 0 : 4}>
          <Button
            color="secondary"
            onClick={() => navigate(ROUTES.CURATED_ORDERS_LANDING)}
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            variant="outlined"
          >
            Back to orders
          </Button>
          <IconButton
            onClick={() => navigate(ROUTES.CURATED_ORDERS_LANDING)}
            sx={{ display: { md: 'none' }, ml: -2.5 }}
          >
            <ArrowLeftIcon />
          </IconButton>
        </Grid>
        <Typography
          mb={{ xs: 2, md: 3 }}
          sx={{ textTransform: 'capitalize', fontSize: '40px', fontWeight: 500 }}
          alignSelf="center"
        >
          Create Draft Orders
        </Typography>
        <Typography
          sx={{ textTransform: 'capitalize', fontSize: '30px', fontWeight: 500 }}
          alignSelf="center"
        >
          Order Details
        </Typography>

        <CuratedOrderDetails />

        <Typography sx={{ fontSize: '30px', fontWeight: 500 }}>Scope</Typography>
        <Typography mb={2} sx={{ fontSize: isiPadOrMobile ? '14px' : '18px', fontWeight: 400 }}>
          Please define the expertise needed and time schedule
        </Typography>

        <CuratedOrdersScope isEdit={isOrderEdit ? true : false} />
      </Box>
      <Grid mt={4} mb={4} display={'flex'} justifyContent={'center'}>
        <LoadingButton
          disabled={!orderName}
          // loading={isSubmitting}
          color="secondary"
          fullWidth
          type="submit"
          variant="contained"
          data-test="save-sales-create-order"
          onClick={onSubmit}
          sx={{ width: isiPadOrMobile ? '100%' : '20%' }}
        >
          {isOrderEdit ? 'Update' : 'Create'}
        </LoadingButton>
      </Grid>
    </FormProvider>
  );
};

export default CuratedCreateOrder;
