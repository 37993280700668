import React, { FunctionComponent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ToastService } from 'services';

import { IEnterpriseUser } from 'store/types/enterprise';

import changeUserRole from 'store/query/enterprise/changeUserRole';
import changeUserStatus from 'store/query/enterprise/changeUserStatus';
import companyUsers from 'store/query/enterprise/companyUsers';
import resendInviteUser from 'store/query/enterprise/resendInviteUser';
import companyTeams from 'store/query/enterprise/companyTeams';
import removeTeamMember from 'store/query/enterprise/removeTeamMember';
import addTeamMember from 'store/query/enterprise/addTeamMember';
import {
  getEnterpriseUsersByPageSelector,
  hasMoreEnterpriseUsersSelector,
  loadingEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';

import PlusIcon from 'styles/icons/PlusIcon';

import FilterUsers from 'components/TeamComponents/FilterUsers';
import ModalWrapper from 'components/Modal/Modal';
import AddMember from 'components/TeamComponents/AddMember';
import ManageTable from 'components/TeamComponents/ManageTable';
import ManageListMobile from 'components/TeamComponents/ManageListMobile';
import ReviewAccessRequest from 'components/TeamComponents/ReviewAccessRequest';
import ToastMessage from 'components/Toast/ToastMessage';
import { outputQuery } from 'helpers/decorators';
import { LIMITS } from 'models/consts';
import { EUsersListModalsTypes } from './constants';
import { IExtendUserCustomer } from 'store/types/customer';
import ROUTES from 'router/constants';

const UsersList: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setModalOpen] = React.useState<string | undefined>(undefined);
  const [activeUser, setActiveUser] = React.useState<IExtendUserCustomer | undefined>(undefined);
  const [page, setPage] = React.useState(0);
  const [firstTimeOpen, setFirstTimeOpen] = React.useState<boolean>(true);

  const users = useAppSelector(getEnterpriseUsersByPageSelector(page + 1));
  const loading = useAppSelector(loadingEnterpriseUsersSelector);
  const hasMore = useAppSelector(hasMoreEnterpriseUsersSelector);

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      filterByEmail: undefined,
      filterByStatus: undefined,
      filterByRole: undefined,
      filterByTeam: undefined,
      property: undefined,
      type: 'asc',
    },
  });

  const { getValues, setValue, watch } = methods;
  const { filterByEmail, filterByRole, filterByStatus, filterByTeam, property, type } = getValues();

  useEffect(() => {
    dispatch(
      companyTeams({
        limit: LIMITS.team,
        offset: 0,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if ((firstTimeOpen || hasMore) && !users.length && !loading) {
      dispatch(
        companyUsers({
          filter: outputQuery({
            filterByEmail,
            filterByRole,
            filterByStatus,
            property,
            type,
          }),
          limit: LIMITS.team,
          offset: LIMITS.team * page,
        }),
      )
        .unwrap()
        .then(() => {
          setFirstTimeOpen(false);
        });
    }
  }, [
    dispatch,
    filterByEmail,
    filterByRole,
    filterByStatus,
    hasMore,
    loading,
    property,
    type,
    page,
    users?.length,
    firstTimeOpen,
  ]);

  const handleClickAddTeamMember = () => {
    setModalOpen(EUsersListModalsTypes.ADD_TEAM_MEMBER);
  };
  const handleReviewClick = (row: any) => {
    setModalOpen(EUsersListModalsTypes.REVIEW_ACCESS_REQUEST);
    setActiveUser(row);
  };

  const handleClose = () => {
    setModalOpen(undefined);
    activeUser && setActiveUser(undefined);
  };

  const handleChangeRole = (event: SelectChangeEvent, userId: number) => {
    dispatch(changeUserRole([{ roleId: parseInt(event.target.value), userId }]))
      .unwrap()
      .then(() => {
        ToastService.success(ToastMessage({ title: 'Success', body: 'Role is changed!' }), {
          toastId: userId,
        });
      });
  };

  const handleChangeTeam = async (
    event: SelectChangeEvent,
    { userId, oldTeamId }: { userId: number; oldTeamId?: number },
  ) => {
    // remove-members from old team, after add members in new team
    try {
      if (oldTeamId) {
        await dispatch(removeTeamMember([{ id: oldTeamId, userId }]));
      }
      await dispatch(addTeamMember([{ id: parseInt(event.target.value), userId }]));
    } catch (error: any) {
      // console.log(error);
    }
  };

  const handleChangeStatus = (user: IEnterpriseUser, status?: any) => {
    if (status !== 'refresh') {
      dispatch(changeUserStatus([{ userId: user.id }]))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Status is changed!' }), {
            toastId: user.id,
          });
        });
    } else {
      dispatch(resendInviteUser({ email: user.email, roleId: user.role.id }))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Resend invite!' }), {
            toastId: user.id,
          });
        });
    }
  };

  const onSearch = (searchTerm: any) => {
    dispatch(companyUsers({ filter: searchTerm, limit: LIMITS.team, offset: 0 }))
      .unwrap()
      .then(() => setPage(0));
  };

  const debouncedSearch = debounce(onSearch, 500);

  const handleSearch = (value: any, field: string) => {
    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      filterByTeam,
      property,
      type,
      [field]: value,
    };

    debouncedSearch(outputQuery(filter));
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = watch('property') === property && watch('type') === 'asc';

    setValue('property', property);
    setValue('type', isAsc ? 'desc' : 'asc');

    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      filterByTeam,
      property,
      type: isAsc ? 'desc' : 'asc',
    };

    dispatch(companyUsers({ filter: outputQuery(filter), limit: LIMITS.team, offset: 0 }))
      .unwrap()
      .then(() => setPage(0));
  };

  const handleChangePage = (newPage: number, limit: number, offset: number) => {
    const filter = {
      filterByEmail,
      filterByRole,
      filterByStatus,
      filterByTeam,
      property,
      type,
    };
    setPage(newPage);
    dispatch(companyUsers({ filter: outputQuery(filter), limit, offset }));
  };

  return (
    <Box data-test="team-page">
      <Box
        sx={{
          display: 'flex',
          mt: { xs: 3.5, md: 7 },
          mb: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1">Team Roster</Typography>
        <Grid item>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickAddTeamMember}
                startIcon={<PlusIcon />}
                size={(mobile && 'small') || 'medium'}
                data-test="add-team-member-button"
              >
                Add Team Member
              </Button>
            </Grid>
            {/*TODO: will be presented in next teams managing iteration*/}
            {/*<Grid item>*/}
            {/*  <Button*/}
            {/*    component={Link}*/}
            {/*    variant="contained"*/}
            {/*    color="secondary"*/}
            {/*    to={ROUTES.TEAMS}*/}
            {/*    size={(mobile && 'small') || 'medium'}*/}
            {/*    data-test="manage-teams-button"*/}
            {/*  >*/}
            {/*    Manage Teams*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>
        </Grid>
      </Box>

      <FormProvider {...methods}>
        <FilterUsers onSearchUsers={handleSearch} />
        {!mobile && (
          <ManageTable
            page={page}
            onChangeRole={handleChangeRole}
            onChangeStatus={handleChangeStatus}
            onChangeTeam={handleChangeTeam}
            onReviewClick={handleReviewClick}
            onChangePage={handleChangePage}
            onRequestSort={handleRequestSort}
          />
        )}
        {mobile && (
          <ManageListMobile
            page={page}
            onChangeRole={handleChangeRole}
            onChangeStatus={handleChangeStatus}
            onChangeTeam={handleChangeTeam}
            onReviewClick={handleReviewClick}
            onChangePage={handleChangePage}
          />
        )}
      </FormProvider>

      <ModalWrapper
        dialogProps={{
          open: open === EUsersListModalsTypes.ADD_TEAM_MEMBER,
          onClose: handleClose,
        }}
        title={{ title: 'Add Team Member' }}
      >
        <AddMember onClose={() => handleClose()} />
      </ModalWrapper>

      {activeUser && (
        <ModalWrapper
          dialogProps={{
            open: open === EUsersListModalsTypes.REVIEW_ACCESS_REQUEST,
            onClose: handleClose,
          }}
          title={{ title: 'Review Access Request' }}
        >
          <ReviewAccessRequest onClose={handleClose} user={activeUser} />
        </ModalWrapper>
      )}
    </Box>
  );
};

export default UsersList;
