import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

import { logPageViewEvent } from 'helpers/firebase-analytics';

import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';

/**
 * Custom hook that logs page view events whenever the location or user changes.
 *
 * It logs an event with the page path, user role, timestamp,
 * and platform whenever the location or user changes.
 *
 * @returns {void}
 */
function useAnalytics() {
  const location = useLocation();
  const user = useAppSelector(getMeSelector) as IBasicUser;
  //   const previousLocationRef = useRef(null);

  useEffect(() => {
    logPageViewEvent({
      page_path: location.pathname,
      user_role: user?.role?.name,
      timestamp: new Date().toISOString(),
      platform: Capacitor.getPlatform(),
    });

    // Store the current location in ref, which will be the previous location on the next render
    // previousLocationRef.current = location;
  }, [location, user]);
}

export default useAnalytics;
