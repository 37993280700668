import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Grid from '@mui/material/Unstable_Grid2';

import { byFilterTeamStatus, ERole, RolesForTeamManaging } from 'models/consts';

import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { getEnterpriseTeamsSelector } from 'store/selectors/getEnterpriseSelector';

import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { BootstrapInput } from 'components/CustomFields';
import { ITeam } from 'store/types/teams';

interface IFilterTeamUsers {
  onSearchUsers: (value: any, field: string) => void;
}

const FilterUsers: FunctionComponent<IFilterTeamUsers> = ({ onSearchUsers }) => {
  const me = useAppSelector(getMeSelector);

  const { control } = useFormContext();

  const teamsList: ITeam[] = useAppSelector(getEnterpriseTeamsSelector);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          name="filterByEmail"
          render={({ field: { ref, ...field } }) => (
            <BootstrapInput
              {...field}
              inputRef={ref}
              onChange={(event: any) => {
                onSearchUsers(event.target.value, 'filterByEmail');
                field.onChange(event);
              }}
              placeholder="Filter by Email"
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          name="filterByStatus"
          render={({ field }) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: undefined,
              }}
              field={field}
              options={byFilterTeamStatus}
              placeholder="Filter by Status"
              handleChange={value => onSearchUsers(value.id, 'filterByStatus')}
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          name="filterByRole"
          render={({ field }) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: undefined,
              }}
              field={field}
              options={[
                {
                  id: null,
                  name: 'All Roles',
                },
                ...RolesForTeamManaging,
              ]}
              placeholder="Filter by Role"
              handleChange={value => onSearchUsers(value.id, 'filterByRole')}
            />
          )}
        />
      </Grid>
      {me?.role.name === ERole.CUSTOMER_ADMIN && (
        <Grid xs={12} md={3}>
          <Controller
            control={control}
            name="filterByTeam"
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: undefined,
                }}
                field={field}
                options={[
                  {
                    id: null,
                    name: 'All Teams',
                  },
                  ...teamsList,
                ]}
                placeholder="Filter by Team"
                handleChange={value => onSearchUsers(value.id, 'filterByTeam')}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FilterUsers;
