import { useMemo } from 'react';

import flow from 'lodash/fp/flow';
import maxBy from 'lodash/fp/maxBy';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';

import { calculateExperiencesDuration } from '../utils/transformers';
import { experienceHumanized } from '../utils/dateFormatter';

import { IProductExperienceFull } from 'store/types/consultant';

const useMaxExperiencePeriod = (expertiseSummary: IProductExperienceFull[]) => {
  return useMemo(() => {
    let maxValue = 0;
    let experienceId = 0;
    let product = 0;

    const durations = expertiseSummary?.map(({ experiences, id }) => {
      return calculateExperiencesDuration(experiences, id);
    });

    maxValue = flow(maxBy('duration'), get('duration'))(durations as any[]);
    experienceId = flow(maxBy('duration'), get('experienceId'))(durations as any[]);
    product = flow(find({ id: experienceId }), get('product'))(expertiseSummary);

    return {
      maxValue: experienceHumanized(Math.floor(maxValue / 12) * 12),
      experienceId,
      product,
    } as any;
  }, [expertiseSummary]);
};

export default useMaxExperiencePeriod;
