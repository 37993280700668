import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';
import { IRole } from 'store/types/common';
import { IOrdersHistory } from 'store/types/order';
import { ERole } from 'models/consts';

interface IOrderHistoryProps {
  id: string;
  role: IRole;
  isPreOrder?: boolean;
}

const fetchPreOrderHistory = createAsyncThunk<IOrdersHistory[], IOrderHistoryProps>(
  EOrder.getPreOrderHistory,
  async ({ id, role, isPreOrder }) => {
    let url = `pre-orders/${id}/change-log`;
    if (
      isPreOrder &&
      [ERole.CUSTOMER, ERole.CUSTOMER_ADMIN, ERole.CUSTOMER_TEAM_ADMIN].includes(role.name)
    ) {
      url = `enterprise/pre-orders/${id}/change-log`;
    } else if (isPreOrder && [ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(role.name)) {
      url = `agency/pre-orders/${id}/change-log`;
    } else if (isPreOrder && role.name === ERole.LEAD_MANAGER) {
      url = `lead-managers/pre-orders/${id}/change-log`;
    }

    const { data } = await instance.get(url);

    return data;
  },
);

export default fetchPreOrderHistory;
