import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECompany } from 'store/constants';
import { EWithOut } from 'models/consts';

const searchCompany = createAsyncThunk(
  ECompany.searchCompany,
  async (searchParams: {
    search?: string;
    availableForCustomer?: boolean;
    searchByDomain?: string;
    withOut?: EWithOut;
  }) => {
    let query = ``;
    for (let key in searchParams) {
      // @ts-ignore
      if (!!searchParams[key]) {
        if (query !== '') {
          query = query + `&`;
        } // @ts-ignore
        query = query + `${key}=${searchParams[key]}`;
      }
    }
    const { data } = await instance.get(`companies/search/?${query}`);
    return data;
  },
);

export default searchCompany;
