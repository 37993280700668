import React, {
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import { useMediaQuery, useTheme } from '@mui/material';
import { Capacitor } from '@capacitor/core';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import updateCustomer from 'store/query/user/updateCustomer';
import updateCustomerUserProfile from 'store/query/user/updateCustomerUserProfile';
import updateCustomerCompany from 'store/query/user/updateCustomerCompany';
import createConsultantCompany from 'store/query/consultant/createConsultantCompany';
import { fetchMe } from 'store/query/user';
import logout from 'store/query/user/logout';
import searchCompany from 'store/query/company/searchCompany';
import teamAccessRequest from 'store/query/enterprise/teamAccessRequest';

import { rolesSelector } from 'store/selectors/getCommonSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { getBasicCustomerSelector } from 'store/selectors/getCustomerSelector';

import { customFilter } from 'helpers/customFilter';
import useSearchCompanyByName from 'hooks/useSearchCompanyByName';
import ROUTES from 'router/constants';

import { IBasicUser } from 'store/types/user';
import { ICompany } from 'store/types/company';

import fetchRoles from 'store/query/common/fetchRoles';

import { StyledActionGroup, StyledGrid, StyledH1, StyledRoot } from '../styled';

import { BootstrapInput, PhoneNumber, RadioGroupInput, Select } from 'components/CustomFields';
import LocationInput from 'components/CustomFields/LocationInput';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { CloseButton } from 'components/CustomFields/Select/styled';

import { EAccessRequestsType, EWithOut, NAME_REGEX } from 'models/consts';
import { ICustomerForm } from 'store/types/customer';

import CloseIcon from 'styles/icons/CloseIcon';

interface ICustomerProfileRegistration {
  isModal?: boolean;
  onChangeCustomerStep?: () => void;
}

const CustomerProfileRegistration: FunctionComponent<ICustomerProfileRegistration> = ({
  isModal,
  onChangeCustomerStep,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [domainCompanies, setDomainCompanies] = useState<ICompany[] | [] | undefined>(undefined);

  const {
    companies,
    setSearchTerm,
    isLoading: isCompaniesLoading,
    setLoading,
  } = useSearchCompanyByName({ availableForCustomer: true });

  const companyRolesList = useAppSelector(rolesSelector);

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const customer = useAppSelector(getBasicCustomerSelector(user?.id));
  const theme = useTheme();
  const roles = useAppSelector(rolesSelector);
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.down('xxl'));

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
    setValue,
    watch,
  } = useForm<ICustomerForm>({
    mode: 'onChange',
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      company: !!customer?.companies?.length
        ? customer?.companies[0]
        : !!domainCompanies?.length
          ? domainCompanies[0]
          : undefined,
      teamType:
        typeof customer?.approveAccess === 'boolean' &&
        !customer?.approveAccess &&
        customer?.accessRequests?.length
          ? EAccessRequestsType.JOIN_TEAM
          : undefined,
      requestText: !!customer?.accessRequests?.length
        ? customer?.accessRequests[0].requestText
        : '',
    },
  });
  const companyValue = watch('company');
  const teamTypeValue = watch('teamType');

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      navigate(`${ROUTES.SIGN_UP_CUSTOMER}`);
    } else if (customer && !!customer?.approveAccess) {
      navigate(ROUTES.ORDER_CREATION + '/1');
    } else {
      const domain = user.email.substring(user.email.indexOf('@') + 1);
      dispatch(searchCompany({ searchByDomain: domain, withOut: EWithOut.WITH_OUT_AGENCY }))
        .unwrap()
        .then(payload => {
          setDomainCompanies(payload);
          if (!companyValue && !!payload.length) {
            setValue('company', payload[0]);
          }
        })
        .catch(error => {
          setDomainCompanies([]);
        });
    }
  }, [user, customer, navigate, dispatch, companyValue, setValue]);

  const onLogout = useCallback(() => {
    dispatch(logout())
      .unwrap()
      .then(() => navigate(ROUTES.AUTH));
  }, [dispatch, navigate]);

  const cancelButton = useMemo(
    () => (
      <CloseButton variant="text" onClick={onLogout} color="secondary">
        <CloseIcon />
      </CloseButton>
    ),
    [onLogout],
  );

  const onSubmit: SubmitHandler<any> = async ({
    firstName,
    lastName,
    teamType,
    teamName,
    requestText,
    company,
    roleId,
    countryIso,
    description,
    location,
    mobilePhone,
    ...rest
  }) => {
    // 1 update customer profile
    // 2 update or create company
    await dispatch(
      updateCustomerUserProfile({
        firstName,
        lastName,
      }),
    )
      .unwrap()
      .then(async () => {
        if (company.ownerId === user.id) {
          await dispatch(
            updateCustomerCompany({
              userId: user.id,
              company: {
                name: company.name,
                description,
                location: location.label,
                roleId,
                mobilePhone: [
                  '+',
                  getCountryCallingCode(mobilePhone.country),
                  mobilePhone.nationalNumber,
                ].join(''),
                countryIso: countryIso,
                id: company.id,
              },
            }),
          );
        } else {
          if (
            description ||
            location?.label ||
            countryIso ||
            (mobilePhone?.country && mobilePhone?.nationalNumber)
          ) {
            await dispatch(
              updateCustomer({
                jobTitle: description,
                location: location?.label,
                mobilePhone: [
                  '+',
                  getCountryCallingCode(mobilePhone.country),
                  mobilePhone.nationalNumber,
                ].join(''),
                countryIso: countryIso,
              }),
            );
          }
        }
      })
      .then(async () => {
        // 3 access request
        if (company.ownerId !== user.id) {
          await dispatch(
            teamAccessRequest({
              type: teamType,
              teamName,
              requestText,
              companyId: company.id,
              userId: user.id,
            }),
          );
        }
      })
      .then(async () => {
        // refresh the user profile for downstream
        return await dispatch(fetchMe());
      })
      .then(data => {
        if (data?.payload?.customer?.approveAccess) {
          if (isModal) {
            onChangeCustomerStep && onChangeCustomerStep();
          } else {
            navigate(ROUTES.ORDER_CREATION + '/1');
          }
        }
      });
  };

  const handleCreateCompany = (company: ICompany) => {
    if (company?.create) {
      dispatch(
        createConsultantCompany({
          company: {
            name: company.name,
            roleId: roles?.find(({ name }) => name === 'Manager')?.id || 4,
            ownerId: user.id,
          },
        }),
      )
        .unwrap()
        .then(data => {
          setValue(`company`, {
            ...data[0],
            create: `Add new company: "${company.name}"`,
          });
          setValue(`companyId`, data[0].id);
        });
    }
    setValue(`companyId`, company?.id);
  };

  const handleCompanySearch = (event: SyntheticEvent, value: string) => {
    setLoading(true);
    setDomainCompanies(undefined);
    setSearchTerm(value);
  };

  return (
    <StyledRoot component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ height: '100%' }}>
        <StyledGrid xs={12} lg={isModal ? 12 : 6}>
          <Box flexGrow={1}>
            {!isModal && (
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <StyledH1 id="customer-register-header" variant="h1">
                  Tell us about your business
                </StyledH1>
                {Capacitor.isNativePlatform() && cancelButton}
              </Box>
            )}
            <FormGroup>
              <InputLabel>First name</InputLabel>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.firstName}
                    placeholder="Enter your first name"
                    helperText={errors.firstName?.message || 'Incorrect data'}
                    dataTest="firstName"
                  />
                )}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Too many spaces',
                  },
                  maxLength: {
                    message: 'The max count of characters is 100',
                    value: 100,
                  },
                }}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Last name</InputLabel>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.lastName}
                    placeholder="Enter your last name"
                    helperText={errors.lastName?.message || 'Incorrect data'}
                    dataTest="lastName"
                  />
                )}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Too many spaces',
                  },
                  maxLength: {
                    message: 'The max count of characters is 100',
                    value: 100,
                  },
                }}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Company</InputLabel>
              <Controller
                name="company"
                control={control}
                rules={{
                  required: 'This field is required',
                  validate: {
                    maxLength: (value: ICompany) => {
                      if (value.name.length > 100) {
                        return 'The max count of characters is 100';
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: undefined,
                      onInputChange: handleCompanySearch,
                    }}
                    inputProps={{
                      error: errors.company as any,
                      helperText: (errors.company as any)?.message || 'Incorrect data',
                    }}
                    field={field}
                    options={domainCompanies || companies || []}
                    placeholder="Type a company name"
                    customFilter={customFilter}
                    handleChange={handleCreateCompany}
                    isLoading={isCompaniesLoading}
                    disabledMobile
                    maxLength={100}
                  />
                )}
              />
              <FormHelperText>
                * We&apos;ve prefilled the company name based on your corporate email domain. If
                this isn&apos;t correct, feel free to update it.
              </FormHelperText>
            </FormGroup>

            {/*Team choosing*/}
            {companyValue?.id && companyValue?.ownerId !== user?.id && (
              <>
                <FormGroup sx={{ marginBottom: 0 }}>
                  <Grid xs={12}>
                    <InputLabel
                      sx={{ overflow: 'auto', whiteSpace: 'normal', textAlign: 'center' }}
                    >
                      It looks like {companyValue?.name} already has a Workerbee account. Would you
                      like to join the existing account or create a new team within this company?
                    </InputLabel>
                    <Controller
                      control={control}
                      name="teamType"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <RadioGroupInput
                          {...field}
                          ariaLabelledby="teamType"
                          values={[EAccessRequestsType.CREATED_TEAM, EAccessRequestsType.JOIN_TEAM]}
                          labels={{
                            [EAccessRequestsType.CREATED_TEAM]: {
                              subLabel1: 'Create New Team',
                              subLabel2: `Start a new team without shared orders/POs.`,
                            },
                            [EAccessRequestsType.JOIN_TEAM]: {
                              subLabel1: 'Request Access',
                              subLabel2: `Ask admin for access to existing orders and POs.`,
                            },
                          }}
                          onChange={event => field.onChange(event)}
                          isHorizontal
                          groupStyles={{ width: '100%' }}
                          sx={{
                            flex: '1 1',
                            flexBasis: isiPadOrMobile ? '100%' : 0,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </FormGroup>
                {teamTypeValue === EAccessRequestsType.CREATED_TEAM && (
                  <FormGroup>
                    <InputLabel>Team Name</InputLabel>
                    <Controller
                      control={control}
                      name="teamName"
                      render={({ field: { ref, ...field } }) => (
                        <BootstrapInput
                          {...field}
                          inputRef={ref}
                          placeholder="Enter team name"
                          dataTest="teamName"
                        />
                      )}
                      rules={{
                        required:
                          teamTypeValue === EAccessRequestsType.CREATED_TEAM &&
                          'This field is required',
                      }}
                    />
                    <FormHelperText>
                      This could be business unit, division or department within your company.
                    </FormHelperText>
                  </FormGroup>
                )}
                {teamTypeValue === EAccessRequestsType.JOIN_TEAM &&
                  !customer?.accessRequests?.length && (
                    <FormGroup>
                      <InputLabel>Message For Admin</InputLabel>
                      <Controller
                        control={control}
                        name="requestText"
                        render={({ field: { ref, ...field } }) => (
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            placeholder={`Hi I’d like to get access to ${companyValue.name}’s Workerbee account...`}
                            dataTest="requestText"
                          />
                        )}
                      />
                    </FormGroup>
                  )}
              </>
            )}
            {/*Team choosing*/}

            {!customer?.approveAccess && !customer?.accessRequests?.length && (
              <>
                <FormGroup>
                  <InputLabel>Job title</InputLabel>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { ref, ...field } }) => (
                      <BootstrapInput
                        {...field}
                        inputRef={ref}
                        placeholder="Title"
                        dataTest="description"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup data-test="location-control">
                  <InputLabel>Location</InputLabel>
                  <Controller
                    control={control}
                    name="location"
                    render={({ field: { ref, ...field } }) => (
                      <LocationInput
                        {...field}
                        inputRef={ref}
                        onChange={(val: any) => {
                          geocodeByPlaceId(val.value.place_id)
                            .then(results => {
                              const countryCode = results[0].address_components.find(region =>
                                region.types.find(type => type === 'country'),
                              );
                              setValue('countryIso', countryCode?.short_name);
                              setValue('mobilePhone.country', countryCode?.short_name || 'US');
                            })
                            .catch(error => console.error(error));
                          field.onChange(val);
                        }}
                        error={!!errors.location}
                        errorText={'This field is required'}
                      />
                    )}
                    rules={{ required: customer?.approveAccess && 'This field is required' }}
                  />
                </FormGroup>
                {companyValue?.id && companyValue?.ownerId === user?.id && (
                  <FormGroup>
                    <InputLabel>Current role</InputLabel>
                    <FormControl variant="filled">
                      <Controller
                        control={control}
                        name="roleId"
                        render={({ field: { ref, ...field } }) => (
                          <Select
                            {...field}
                            inputRef={ref}
                            options={companyRolesList || []}
                            label="Select role"
                            data-test="roleId"
                          />
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                )}
                <FormGroup sx={{ mb: 5 }}>
                  <InputLabel>Phone number</InputLabel>
                  <PhoneNumber
                    control={control}
                    defaultValue={{
                      country: 'US',
                    }}
                    name="mobilePhone"
                    rules={{
                      required: customer?.approveAccess && 'This field is required',
                    }}
                    error={!!errors.mobilePhone?.nationalNumber || !!errors.mobilePhone?.country}
                    errorText={
                      (errors.mobilePhone?.nationalNumber as any)?.message ||
                      (errors.mobilePhone?.country as any)?.message
                    }
                  />
                </FormGroup>
              </>
            )}

            {!customer?.approveAccess &&
              !!customer?.accessRequests?.length &&
              teamTypeValue === EAccessRequestsType.JOIN_TEAM && (
                <Typography variant="subtitle1" align="center" mb={2}>
                  Access has been requested and we’re waiting for the admin’s response.
                  Alternatively you can still switch to a new team creation.
                </Typography>
              )}
          </Box>
          {(!customer?.accessRequests?.length ||
            teamTypeValue === EAccessRequestsType.CREATED_TEAM) && (
            <StyledActionGroup>
              <LoadingButton
                data-test="next-button"
                color="secondary"
                sx={{ mb: 2 }}
                variant="contained"
                disabled={
                  !isValid ||
                  isSubmitting ||
                  (!customer?.approveAccess &&
                    !!customer?.accessRequests?.length &&
                    teamTypeValue === EAccessRequestsType.JOIN_TEAM)
                }
                type="submit"
                loading={isSubmitting}
              >
                Continue
              </LoadingButton>
            </StyledActionGroup>
          )}
        </StyledGrid>
      </Grid>
    </StyledRoot>
  );
};

export default CustomerProfileRegistration;
