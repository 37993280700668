import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';
import { ITeam } from 'store/types/teams';

interface ITeamsResponse {
  count: number;
  page: number;
  teams: ITeam[];
}

interface ITeamsRequestFilter {
  filterByName?: string;
  property?: string;
  type?: 'desc' | 'asc';
}

interface ITeamsRequest {
  limit: number;
  offset: number;
  filter?: ITeamsRequestFilter;
  key?: string;
}

const companyTeams = createAsyncThunk<ITeamsResponse, ITeamsRequest>(
  EEnterprise.fetchCompanyTeamsList,
  async ({ filter, key, ...params }) => {
    const { data } = await instance.get(`enterprise/companies/teams`, {
      params: { ...filter, ...params },
    });
    return data;
  },
);

export default companyTeams;
