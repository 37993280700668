const getEnterpriseCustomerStatus = (
  isInvited: boolean,
  isActive: boolean,
  isPendingApproval?: boolean,
) => {
  if (isInvited) return 'Pending Invitation';
  if (isPendingApproval) return 'Pending Access Approval';
  if (isActive) return 'Active';
  return 'Inactive';
};

const getEnterpriseCustomerClassName = (
  isInvited: boolean,
  isActive: boolean,
  isPendingApproval?: boolean,
) => {
  if (isInvited || isPendingApproval) return 'created';
  if (isActive) return 'active';
  return 'terminated';
};

export { getEnterpriseCustomerStatus, getEnterpriseCustomerClassName };
