import React from 'react';

import { RadioGroup, Typography, Box } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import { StyledFormControlLabel, StyledRadio } from './styled';

interface IRadioGroupInput<T> {
  name: string;
  values: T[];
  defaultValue?: T;
  field?: any;
  ariaLabelledby: string;
  isHorizontal?: boolean;
  labels?: any;
  disabled?: boolean;
  onChange?: (event: any) => void;
  sx?: any;
  groupStyles?: any;
  value?: string | null;
}

const RadioGroupInput: React.FunctionComponent<IRadioGroupInput<string | number>> = ({
  defaultValue,
  values,
  ariaLabelledby,
  isHorizontal = true,
  labels,
  disabled,
  sx,
  groupStyles,
  ...field
}) => (
  <RadioGroup
    {...field}
    value={field.value || null}
    aria-labelledby={ariaLabelledby}
    row={isHorizontal}
    defaultValue={defaultValue}
    sx={groupStyles}
  >
    {values.map((value: any) => (
      <StyledFormControlLabel
        data-test={`radio-button-${value}`}
        style={sx}
        value={value}
        key={value}
        control={<StyledRadio size="small" data-test="radio-button" disableRipple />}
        label={
          labels && labels[value] ? (
            <>
              {!!labels[value].subLabel1 && (
                <Typography color="inherit" variant="body2">
                  <strong>{labels[value].subLabel1}</strong>
                </Typography>
              )}
              {!!labels[value].subLabel2 && (
                <Typography color="inherit" variant="body2">
                  {labels[value].subLabel2}
                </Typography>
              )}
              {!!labels[value].children && (
                <Box sx={{ position: 'relative' }}>
                  {labels[value].children}
                  {!!labels[value].error && (
                    <FormHelperText error sx={{ bottom: 0, marginBottom: '-33px' }}>
                      {labels[value].helperText}
                    </FormHelperText>
                  )}
                </Box>
              )}
            </>
          ) : (
            <span>{value}</span>
          )
        }
        disabled={disabled}
      />
    ))}
  </RadioGroup>
);

export { RadioGroupInput as default, IRadioGroupInput };
