import React, { FunctionComponent, useState } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BootstrapInput from 'components/CustomFields/BootstrapInput';
import DateTextInput from 'components/CustomFields/DateTextInput';
import CalendarIcon from 'styles/icons/CalendarIcon';

import { useAppDispatch } from 'store/hooks';
import { IExtendUserCustomer } from 'store/types/customer';
import approveAccessRequest from 'store/query/enterprise/approveAccessRequest';
import declineAccessRequest from 'store/query/enterprise/declineAccessRequest';

interface IReviewAccessRequest {
  onClose: () => void;
  user: IExtendUserCustomer;
}

const ReviewAccessRequest: FunctionComponent<IReviewAccessRequest> = ({ onClose, user }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);

  const handleDeclineAccessRequest = async () => {
    if (user.customer?.accessRequests?.length) {
      setIsLoading(true);
      await dispatch(
        declineAccessRequest({
          accessRequestIds: [{ id: user.customer.accessRequests[0].id }],
          userId: user.id,
        }),
      )
        .unwrap()
        .then(() => {
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleApproveAccessRequest = async () => {
    if (user.customer?.accessRequests?.length) {
      setIsLoading(true);
      await dispatch(
        approveAccessRequest({
          accessRequestIds: [{ id: user.customer.accessRequests[0].id }],
          userId: user.id,
        }),
      )
        .unwrap()
        .then(() => {
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const companyName = !!user?.customer?.companies?.length
    ? user.customer.companies[0].name
    : 'Company';

  return (
    <>
      <DialogContent>
        <FormGroup>
          <Grid container spacing={{ xs: 0, md: 2 }} alignItems="center">
            <Grid item xs={12} md={3}>
              <InputLabel sx={{ mb: 0 }}>Requestor Email</InputLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <BootstrapInput
                placeholder="Enter your email"
                type="email"
                value={user.email}
                disabled
              />
            </Grid>
          </Grid>
        </FormGroup>
        <FormGroup>
          <Grid container spacing={{ xs: 0, md: 2 }} alignItems="center">
            <Grid item xs={12} md={3}>
              <InputLabel sx={{ mb: 0 }}>Name</InputLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <BootstrapInput
                placeholder="Enter your name"
                type="text"
                value={`${user.firstName} ${user.lastName}`}
                disabled
              />
            </Grid>
          </Grid>
        </FormGroup>
        <FormGroup>
          <Grid container spacing={{ xs: 0, md: 2 }} alignItems="center">
            <Grid item xs={12} md={3}>
              <InputLabel sx={{ mb: 0 }}>Job Title</InputLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <BootstrapInput
                placeholder="Job Title"
                type="text"
                value={user.customer.jobTitle}
                disabled
              />
            </Grid>
          </Grid>
        </FormGroup>
        <FormGroup>
          <Grid container spacing={{ xs: 0, md: 2 }} alignItems="flex-start">
            <Grid item xs={12} md={3}>
              <InputLabel sx={{ mb: 0, mt: 1 }}>Message</InputLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <BootstrapInput
                placeholder={`Hi I’d like to get access to ${companyName}’s Workerbee account...`}
                type="text"
                value={
                  user.customer?.accessRequests?.length &&
                  user.customer.accessRequests[0].requestText
                }
                InputProps={{ multiline: true }}
                disabled
              />
            </Grid>
          </Grid>
        </FormGroup>
        <FormGroup>
          <Grid container spacing={{ xs: 0, md: 2 }} alignItems="center">
            <Grid item xs={12} md={3}>
              <InputLabel sx={{ mb: 0 }}>Request Time</InputLabel>
            </Grid>
            <Grid item xs={12} md={9}>
              <DateTextInput
                className="text-custom-input"
                onReset={() => undefined}
                icon={<CalendarIcon />}
                dataTest="agencyReadinessDate"
                formatDate="MM/DD/YYYY hh:mm a"
                value={
                  !!user.customer?.accessRequests?.length
                    ? user.customer.accessRequests[0].createdAt
                    : null
                }
                disabled
              />
            </Grid>
          </Grid>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          color="tertiary"
          fullWidth
          type="button"
          variant="contained"
          data-test="reject-access-request"
          onClick={handleDeclineAccessRequest}
          disabled={isLoading}
        >
          Reject
        </Button>
        <Button
          color="secondary"
          fullWidth
          type="button"
          variant="contained"
          data-test="approve-access-request"
          onClick={handleApproveAccessRequest}
          disabled={isLoading}
        >
          Approve
        </Button>
      </DialogActions>
    </>
  );
};

export default ReviewAccessRequest;
