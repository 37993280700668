import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';

import { EOrder } from '../../constants';

const fetchCuratedOrdersList = createAsyncThunk(EOrder.fetchCuratedOrderDraft, async () => {
  const { data } = await instance.get('draft-orders');

  return data;
});

export default fetchCuratedOrdersList;
