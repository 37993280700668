import React, { forwardRef, FunctionComponent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { onTypeaheadItemChange } from 'utils/orderCreationHelpers';
import { activeSort, filterIsDeprecated } from 'helpers/decorators';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { expertiseLevelsSelector } from 'store/selectors/getCommonSelector';

import {
  categoryProductsSelector,
  modulesSelector,
  workTypesSelector,
} from 'store/selectors/getCommonSelector';

import useSafeAreaView from 'components/SafeAreaView';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { StyledMasonry } from 'components/CustomFields/CustomAutocomplete/styled';
import { Select } from 'components/CustomFields';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import { ProductSelect } from 'components/ConsultantComponents/Products/Common';
import { StyledBoxImpersonate } from 'components/Header/styled';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface IProductsModulesWorkProps {
  disabledProductEdit?: boolean;
  isModal?: boolean;
}

const ProductsModulesWork: FunctionComponent<IProductsModulesWorkProps> = ({
  disabledProductEdit,
  isModal,
}) => {
  const dispatch = useAppDispatch();
  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);
  const seniorities = useAppSelector(expertiseLevelsSelector);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!categoriesWithProductsList?.length || !modulesList?.length || !worksList.length) {
      dispatch(fetchProductsWithModulesWorks());
    }
  }, [categoriesWithProductsList, modulesList, worksList, dispatch]);

  const { safeAreaPadding } = useSafeAreaView();

  const {
    control,
    formState: { errors },
    resetField,
    setValue,
    watch,
  } = useFormContext();

  const currentProduct = watch('product');
  const primaryModule = watch('primaryModule');
  const primaryWorkType = watch('primaryWorkType');

  const modulesListByProduct = filterIsDeprecated(modulesList, currentProduct?.id);
  const worksListByProduct = filterIsDeprecated(worksList, currentProduct?.id);

  const { product } = watch();

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid xs={12} md={6} lg={isModal ? 6 : 12}>
        <InputLabel>Product</InputLabel>
        <Controller
          control={control}
          name="product"
          render={({ field }) => (
            <ProductSelect
              control={control}
              flatList={activeSort(categoriesWithProductsList)}
              name={`product`}
              handleChange={(product: any) => {
                setValue(`product`, product);
                resetField('modules', { defaultValue: [] });
                resetField('works', { defaultValue: [] });
              }}
              showAddProduct={false}
              selectedOptions={[]}
              hideLabel
              mobilesx={{ marginTop: safeAreaPadding.top }}
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} lg={isModal ? 6 : 5}>
        <InputLabel>Modules</InputLabel>
        <Controller
          control={control}
          name="modules"
          render={({ field }) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: true,
              }}
              inputProps={{
                readOnly: true,
                error: !!errors.modules,
                helperText: (errors.modules as any)?.message || 'Incorrect data',
                sx: {
                  '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                },
              }}
              field={field}
              handleChange={(event, fieldName) => onTypeaheadItemChange(event, fieldName, setValue)}
              isMainTag
              options={modulesListByProduct || []}
              placeholder={!product ? 'No Product Selected' : 'Select a Module'}
              primaryItem={primaryModule}
              onPrimaryItemChange={id => setValue('primaryModule', id)}
              disabled={!product}
            />
          )}
          rules={{ required: 'This field is required' }}
        />
      </Grid>
      <Grid xs={12} md={6} lg={2}>
        <InputLabel>Seniority Level</InputLabel>
        <Controller
          control={control}
          name="levelId"
          render={({ field: { ref, ...field } }) => (
            <Select
              {...field}
              inputRef={ref}
              label={!product ? 'No Product Selected' : 'Select Level'}
              options={seniorities || []}
              labelMenuItem="name"
              arrowIconSx={{ right: '10px' }}
              disabled={!product}
              isCuratedOrder={true}
              isProductSelected={!product}
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} lg={isModal ? 6 : 5}>
        <InputLabel>Type of Work</InputLabel>
        <Controller
          control={control}
          name="works"
          render={({ field }) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: true,
              }}
              inputProps={{
                readOnly: true,
                error: !!errors.works,
                helperText: (errors.works as any)?.message || 'Incorrect data',
                sx: {
                  '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                },
              }}
              field={field}
              isMainTag
              options={worksListByProduct || []}
              handleChange={(event, fieldName) => onTypeaheadItemChange(event, fieldName, setValue)}
              placeholder={!product ? 'No Product Selected' : 'Select a Type of Work'}
              primaryItem={primaryWorkType}
              onPrimaryItemChange={id => setValue('primaryWorkType', id)}
              disabled={!product}
            />
          )}
          rules={{ required: 'This field is required' }}
        />
      </Grid>
    </Grid>
  );
};

export default ProductsModulesWork;
