import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { IExtendHours } from '../../types/order';
import { IRole } from '../../types/common';
import { ERole } from '../../../models/consts';

interface IRequestParams {
  orderId: number;
  extendHours: IExtendHours;
  role: IRole;
  isPurchase?: boolean;
}

const extendHours = createAsyncThunk<IExtendHours, IRequestParams>(
  EOrder.extendHours,
  async ({ orderId, extendHours, role, isPurchase }) => {
    let url = `orders/${orderId}/extend-hours`;
    if (
      role.name === ERole.CUSTOMER_ADMIN ||
      role.name === ERole.CUSTOMER ||
      role.name === ERole.CUSTOMER_TEAM_ADMIN
    ) {
      url = isPurchase
        ? `enterprise/orders/${orderId}/extend-hours`
        : `orders/${orderId}/extend-hours`;
    }
    const { data } = await instance.post(url, extendHours);

    return data;
  },
);

export default extendHours;
