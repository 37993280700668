import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const StyledPaper = styled(Paper)(({ theme }) =>
  theme.unstable_sx({
    border: '1px solid #F5F5F5',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
    padding: 2,
    margin: 1,
  }),
);

const StyledItemWrap = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pb: 2.5,
    width: '100%',
    position: 'relative',
  }),
);

const StyledItemHeader = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: {
      md: 69,
    },
    minHeight: {
      md: 30,
    },
  }),
);

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.secondary.main,
  fontSize: '1.125rem',
  fontWeight: 700,
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  lineHeight: 'normal',
}));

const StyledOrdersBlock = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mt: 1,
    mb: 2,
    ml: 2,
  }),
);
const StyledTitleOrdersBlock = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

const StyledTimelineWrap = styled(Box)({
  backgroundColor: '#F9F9F9',
  borderRadius: 2,
});

const StyledColaborator = styled(Typography)({
  fontSize: '.875rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  textWrap: 'nowrap',
});

export {
  StyledItemWrap,
  StyledPaper,
  StyledItemHeader,
  StyledLink,
  StyledOrdersBlock,
  StyledTitleOrdersBlock,
  StyledTimelineWrap,
  StyledColaborator,
};
