import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';

import { IEnterpriseUserRequest } from '../../types/enterprise';

const searchUsersTeam = createAsyncThunk<IEnterpriseUserRequest, any>(
  EEnterprise.searchUsersList,
  async params => {
    const { data } = await instance.get<IEnterpriseUserRequest>('enterprise/companies/users', {
      params,
    });

    return data;
  },
);

export default searchUsersTeam;
