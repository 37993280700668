import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Autocomplete, InputAdornment, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { TextField } from '@mui/material';
import { BootstrapInput } from 'components/CustomFields';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';

import { useAppDispatch } from 'store/hooks';
import { outputQuery } from 'helpers/decorators';
import { getNameByRoleId, LIMITS } from 'models/consts';
import getCustomerList from 'store/query/user/getCustomerList';
import getConsultantList from 'store/query/user/getConsultantList';

const CuratedOrderDetails: FunctionComponent<any> = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const [customerData, setCustomerData] = useState<any[]>([]);
  const [specialistData, setSpecialistData] = useState<any[]>([]);

  const {
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { customer, specialist, orderName, description } = watch();

  useEffect(() => {
    dispatch(getConsultantList({ withAgency: 'false', isActive: true })).then((data: any) => {
      setSpecialistData(data?.payload?.consultants || []);
    });
    dispatch(getCustomerList({ limit: LIMITS.team, offset: 0, type: 'asc' })).then((data: any) => {
      setCustomerData(data?.payload?.customers || []);
    });
  }, [dispatch]);

  const debounced = debounce((value: any, searchField: string) => {
    if (value && searchField) {
      if (searchField === 'customer') {
        dispatch(
          getCustomerList({
            limit: LIMITS.team,
            offset: 0,
            type: 'asc',
            search: value,
          }),
        ).then((data: any) => {
          setCustomerData(data?.payload?.customers || []);
        });
      }
      if (searchField === 'specialist') {
        dispatch(getConsultantList({ withAgency: 'false', isActive: true, search: value })).then(
          (data: any) => {
            setSpecialistData(data?.payload?.consultants || []);
          },
        );
      }
    }
  }, 500);

  const searchTerm = (value: string, searchField: string) => {
    debounced(value, searchField);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid
        xs={12}
        md={12}
        lg={6}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <InputLabel
          sx={{
            width: 140,
            marginTop: isiPadOrMobile ? 0 : 1,
            fontSize: '14px',
            fontWeight: 400,
            color: '#5D5D5E',
          }}
        >
          Order Name{' '}
          <Typography component="span" color="red">
            *
          </Typography>
        </InputLabel>
        <Controller
          control={control}
          name="orderName"
          render={({ field: { ref, ...field } }) => (
            <BootstrapInput
              {...field}
              type="text"
              placeholder="Type your order name here..."
              sx={{
                width: isiPadOrMobile ? '100%' : '83%',
                marginLeft: isiPadOrMobile ? undefined : 1,
              }}
              onChange={(event: any) => {
                const value = event.target.value;
                field.onChange(value);
                setValue('orderName', value);
              }}
              autoComplete="off"
            />
          )}
          rules={{
            required: 'This field is required',
          }}
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        lg={6}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <InputLabel
          sx={{
            width: 120,
            marginTop: isiPadOrMobile ? 0 : 1,
            fontSize: '14px',
            fontWeight: 400,
            color: '#5D5D5E',
          }}
        >
          Customer
        </InputLabel>
        <Controller
          control={control}
          name="customer"
          render={({ field: { ref, ...field } }) => (
            <Autocomplete
              {...field}
              onInputChange={(_, value) => {
                searchTerm(value, 'customer');
              }}
              onChange={(_, newValue) => {
                setValue('customer', newValue);
                field.onChange(newValue);
              }}
              options={customerData}
              getOptionLabel={option => {
                return option
                  ? `${option.firstName || ''} ${option.lastName || option.email} ${
                      option?.customer?.companies[0]?.name
                        ? ` - ${option.customer.companies[0].name}`
                        : ''
                    }`
                  : '';
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => <BootstrapInput {...params} placeholder="Select an option" />}
              sx={{ width: isiPadOrMobile ? '100%' : '69%' }}
            />
          )}
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        lg={6}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <InputLabel
          sx={{
            width: 120,
            marginTop: isiPadOrMobile ? 0 : 1,
            fontSize: '14px',
            fontWeight: 400,
            color: '#5D5D5E',
          }}
        >
          Specialist
        </InputLabel>
        <Controller
          control={control}
          name="specialist"
          render={({ field: { ref, ...field } }) => (
            <Autocomplete
              {...field}
              onInputChange={(_, value) => {
                searchTerm(value, 'specialist');
              }}
              onChange={(_, newValue) => {
                setValue('specialist', newValue);
                field.onChange(newValue);
              }}
              options={specialistData}
              getOptionLabel={option => {
                return option ? `${option.firstName || ''} ${option.lastName || option.email}` : '';
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => <BootstrapInput {...params} placeholder="Select an option" />}
              sx={{ width: isiPadOrMobile ? '100%' : '69%' }}
            />
          )}
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        lg={6}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <InputLabel
          sx={{
            width: 140,
            marginTop: isiPadOrMobile ? 0 : 1,
            fontSize: '14px',
            fontWeight: 400,
            color: '#5D5D5E',
          }}
        >
          Description
        </InputLabel>
        <Controller
          control={control}
          name="description"
          render={({ field: { ref, ...field } }) => (
            <BootstrapInput
              {...field}
              type="text"
              multiline={true}
              rows={isiPadOrMobile ? 1.5 : 3}
              placeholder="Type your order description here..."
              sx={{
                width: '100%',
                marginLeft: 0.5,
              }}
              onChange={(event: any) => {
                const value = event.target.value;
                field.onChange(value);
                setValue('description', value);
              }}
              autoComplete="off"
            />
          )}
          rules={{
            required: 'This field is required',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CuratedOrderDetails;
