import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EAgency } from '../../constants/agency';

const updateUsersTeamAvailability = createAsyncThunk(
  EAgency.updateUsersTeamAvailability,
  async (payload: any) => {
    const response = await instance.patch('agency/users/availabilities', payload);
    return response.data;
  },
);

export default updateUsersTeamAvailability;
