import { EChangeAction, EOrderFilter, EPreOrderStatus, ERole } from 'models/consts';

export default [
  // {
  //   //   label: 'Preorder',
  //   //   roles: [ ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN ],
  //   //   value: EOrderFilter.NEED_ACTION,
  //   // }, {
  //   label: 'Payment Authorizations',
  //   roles: [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER],
  //   value: EOrderFilter.PURCHASE_ORDERS,
  // },
  {
    label: 'Unassigned',
    roles: [ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN],
    value: EOrderFilter.UNASSIGNED,
  },
  {
    label: 'Needs Action',
    roles: [ERole.LEAD_MANAGER],
    value: EOrderFilter.NEED_ACTION,
  },
  {
    label: 'Pending',
    roles: [ERole.LEAD_MANAGER],
    value: EOrderFilter.IN_PROGRESS,
  },
  {
    label: 'Current',
    roles: [
      ERole.CUSTOMER_ADMIN,
      ERole.ADMIN,
      ERole.AGENCY_ADMIN,
      ERole.CONSULTANT,
      ERole.SELF_CUSTOMER,
      ERole.CUSTOMER,
      ERole.RESOURCE_MANAGER,
      ERole.LEAD_MANAGER,
      ERole.CUSTOMER_TEAM_ADMIN,
    ],
    value: EOrderFilter.CURRENT,
  },
  {
    label: 'Past Orders',
    roles: [
      ERole.CUSTOMER_ADMIN,
      ERole.ADMIN,
      ERole.AGENCY_ADMIN,
      ERole.CONSULTANT,
      ERole.SELF_CUSTOMER,
      ERole.CUSTOMER,
      ERole.RESOURCE_MANAGER,
      ERole.CUSTOMER_TEAM_ADMIN,
    ],
    value: EOrderFilter.PAST_ORDERS,
  },
  {
    label: 'Unrealized Orders',
    roles: [
      ERole.ADMIN,
      ERole.AGENCY_ADMIN,
      ERole.CONSULTANT,
      ERole.SELF_CUSTOMER,
      ERole.RESOURCE_MANAGER,
      ERole.CUSTOMER_ADMIN,
      ERole.CUSTOMER,
      ERole.CUSTOMER_TEAM_ADMIN,
    ],
    value: EOrderFilter.UNREALIZED_ORDERS,
  },
  {
    //   label: 'Drafts',
    //   roles: [ ERole.CONSULTANT, ERole.CUSTOMER ],
    //   value: EOrderFilter.DRAFT_ORDERS,
    // }, {
    //   label: 'Needs Action',
    //   roles: [ ERole.LEAD_MANAGER ],
    //   value: EOrderFilter.NEED_ACTION,
    // }, {
    //   label: 'Pending',
    //   roles: [ ERole.LEAD_MANAGER ],
    //   value: EOrderFilter.IN_PROGRESS,
    // }, {
    label: 'Finished',
    roles: [ERole.LEAD_MANAGER],
    value: EOrderFilter.FINISH_ORDERS,
    // }, {
    //   label: 'Cancelled',
    //   roles: [ ERole.LEAD_MANAGER ],
    //   value: EOrderFilter.CANCEL_ORDERS,
  },
];

export const purchaseTabs = [
  {
    label: 'Current',
    roles: [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER, ERole.CUSTOMER_TEAM_ADMIN],
    value: EOrderFilter.CURRENT_PURCHASE_ORDERS,
  },
  {
    label: 'Past',
    roles: [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER, ERole.CUSTOMER_TEAM_ADMIN],
    value: EOrderFilter.PAST_PURCHASE_ORDERS,
  },
  {
    label: 'Unrealized Orders',
    roles: [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER, ERole.CUSTOMER_TEAM_ADMIN],
    value: EOrderFilter.UNREALIZED_ORDERS,
  },
];

export const subTabsForLeadManager: any = {
  [EOrderFilter.NEED_ACTION]: [
    {
      key: 'qualifyLead',
      label: 'Qualify Lead',
      filter: [EPreOrderStatus.NEW, EPreOrderStatus.IN_REVISION, EPreOrderStatus.DECLINED_PREORDER],
    },
    {
      key: 'inviteCustomer',
      label: 'Invite Customer',
      filter: [EPreOrderStatus.PENDING_INTRODUCTION],
    },
    {
      key: 'finalizePaperwork',
      label: 'Finalize Paperwork',
      filter: [EPreOrderStatus.PENDING_BILLING],
    },
  ],
  [EOrderFilter.IN_PROGRESS]: [
    {
      key: 'pendingAgency',
      label: 'Pending Agency',
      filter: [EPreOrderStatus.PENDING_AGENCY],
    },
    {
      key: 'pendingCustomer',
      label: 'Pending Customer',
      filter: [EPreOrderStatus.PENDING_CUSTOMER],
    },
    {
      key: 'pendingApproval',
      label: 'Pending Approval',
      filter: [EPreOrderStatus.PENDING_APPROVAL],
    },
  ],
  [EOrderFilter.CURRENT]: [
    {
      key: EOrderFilter.CURRENT,
      label: 'Ready to Start',
      filter: [EChangeAction.READY_TO_START],
    },
    {
      key: 'activeOrders',
      label: 'Active',
      filter: [EChangeAction.ACTIVE_ORDER],
    },
    {
      key: 'onHoldOrders',
      label: 'On Hold',
      filter: [EChangeAction.ON_HOLD],
    },
  ],
  [EOrderFilter.FINISH_ORDERS]: [
    {
      key: 'completedOrders',
      label: 'Completed',
      filter: ['completed'],
    },
    {
      key: EOrderFilter.CANCEL_ORDERS,
      label: 'Cancelled',
      filter: ['canceled'],
    },
  ],
};

export const subTabsForAgency: any = [
  {
    key: 'needActions',
    label: 'Need Actions',
    filter: [EPreOrderStatus.PENDING_APPROVAL, EPreOrderStatus.PENDING_AGENCY],
  },
  {
    key: 'finalizeOrder',
    label: 'Finalize Order',
    filter: [EPreOrderStatus.PENDING_CUSTOMER, EPreOrderStatus.PENDING_INTRODUCTION],
  },
];

export const subTabsForUsers: any = [
  {
    key: 'needActions',
    label: 'Need Actions',
    filter: [
      EPreOrderStatus.PENDING_APPROVAL,
      EPreOrderStatus.PENDING_AGENCY,
      EPreOrderStatus.FINALIZE_PAPERWORK,
    ],
  },
  {
    key: 'finalizeOrder',
    label: 'Finalize Order',
    filter: [EPreOrderStatus.PENDING_BILLING],
  },
];

export const subTabsForSalesOrTalent: any = [
  {
    key: 'pending',
    label: 'Pending',
    role: [ERole.SALES, ERole.TALENT],
    value: EOrderFilter.PENDING,
  },
  {
    key: 'finalized',
    label: 'Finalized',
    role: [ERole.SALES, ERole.TALENT],
    value: EOrderFilter.FINALIZED,
  },
];
