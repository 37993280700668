import { Box, Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';

import SalesOrTalentOrderItem from '../CuratedOrderItem';
import { StyledOrdersBlock } from '../CuratedOrderItem/styled';

import Loader from 'components/Loader/Loader';

import { fetchCuretedOrderLoadingSelector } from 'store/selectors/getCuratedOrdersSelector';
import { useAppSelector } from 'store/hooks';

interface ISalesOrTalentPendingOrdersTabProps {
  orderList: any;
}

const CuratedPendingOrdersTab: FunctionComponent<ISalesOrTalentPendingOrdersTabProps> = ({
  orderList,
}) => {
  const curatedOrderListLoading = useAppSelector(fetchCuretedOrderLoadingSelector);

  return (
    <Box sx={{ height: '100%' }}>
      <StyledOrdersBlock>
        {!!orderList.length && (
          <Grid container spacing={3} sx={{ mt: { xs: 0.5 } }}>
            {orderList?.map((order: any) => (
              <Grid lg={6} sm={6} xxl={3} xs={12} key={`order-${order.id}`}>
                <SalesOrTalentOrderItem order={order} />
              </Grid>
            ))}
          </Grid>
        )}
        {curatedOrderListLoading && (
          <Box height={499}>
            <Loader />
          </Box>
        )}
        {!orderList.length && !curatedOrderListLoading && (
          <Box mt={6}>
            <Typography color={'#5D5D5E'} fontSize={'18px'} fontWeight={400}>
              No pending orders
            </Typography>
          </Box>
        )}
      </StyledOrdersBlock>
    </Box>
  );
};

export default CuratedPendingOrdersTab;
