import { createAsyncThunk } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { Capacitor } from '@capacitor/core';

import instance from '../../middlewares/api';
import { EAgency } from '../../constants';
import { IAgency, IAgencyCreateDto } from '../../types/agency';
import { ELocalStoreKeys, defaultTokenCookieOptions } from '../../../models/consts';

import fetchMe from '../user/fetchMe';

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });
const isMobileNative = Capacitor.isNativePlatform();

interface IResponse {
  agency: IAgency;
}
const createAgency = createAsyncThunk<IResponse, IAgencyCreateDto>(
  EAgency.createAgency,
  async (values, thunkAPI) => {
    const response = await instance.post('agency', values);

    const {
      data: { accessToken, refreshToken },
    } = response;

    const cookieOptions = isMobileNative ? defaultTokenCookieOptions : {};

    cookies.set(ELocalStoreKeys.ACCESS_TOKEN, accessToken, cookieOptions);
    cookies.set(ELocalStoreKeys.REFRESH_TOKEN, refreshToken, cookieOptions);

    await thunkAPI.dispatch(fetchMe());
    return response.data;
  },
);

export default createAgency;
