import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import { EnterpriseState } from '../reducers/enterprise';
import { EOrderFilter, LIMITS, orderTabStatuses } from '../../models/consts';
import getOr from 'lodash/fp/getOr';
import flowRight from 'lodash/fp/flowRight';
import compact from 'lodash/fp/compact';
import map from 'lodash/fp/map';
import { IEnterpriseUser, IInvoice, IPurchaseOrder } from 'store/types/enterprise';
import { ITeam } from 'store/types/teams';

const enterpriseState = (state: DefaultRootState) => state.enterprise;
const usersById = (state: DefaultRootState) => state.enterprise.usersById;
const purchaseOrdersById = (state: DefaultRootState) => state.enterprise.purchaseOrdersById;
const invoicesById = (state: DefaultRootState) => state.enterprise.invoicesById;
const getUsers = (usersById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && usersById[id]),
  );
const getPurchaseOrders = (purchaseOrdersById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && purchaseOrdersById[id]),
  );
const getInvoices = (invoicesById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && invoicesById[id]),
  );
/**
 *  Get Users List
 */
export const selectUsersIds = createSelector(
  enterpriseState,
  state => state.users[state.usersFilter]?.ids,
);
export const getEnterpriseUsersByPageSelector = (page: number) =>
  createSelector(
    selectUsersIds,
    usersById,
    (ids, userById) =>
      getUsers(userById)(
        ids?.slice((page - 1) * LIMITS.team, page * LIMITS.team),
      ) as IEnterpriseUser[],
  );
export const getEnterpriseUsersSelector = createSelector(
  selectUsersIds,
  usersById,
  (ids, userById) => getUsers(userById)(ids) as IEnterpriseUser[],
);
export const loadingEnterpriseUsersSelector = createSelector(enterpriseState, state =>
  getOr(false)(['users', state.usersFilter, 'loading'])(state as any),
);
export const hasMoreEnterpriseUsersSelector = createSelector(enterpriseState, state =>
  getOr(true)(['users', state.usersFilter, 'hasMore'])(state as any),
);
export const totalEnterpriseUsersSelector = createSelector(
  enterpriseState,
  state => state.users[state.usersFilter]?.countAll || 0,
);
export const getSearchEnterpriseUsersSelector = (search: string) =>
  createSelector(enterpriseState, ({ searchUsers }: EnterpriseState) => searchUsers[search]);

/**
 *  Get Payment Authorization (Purchase Order) List
 */
export const selectPurchaseOrderIds = createSelector(
  enterpriseState,
  state => state.purchaseOrders[state.purchaseOrdersFilter]?.ids,
);
export const getPurchaseOrdersSelector = createSelector(
  selectPurchaseOrderIds,
  purchaseOrdersById,
  (ids, purchaseOrderById) => getPurchaseOrders(purchaseOrderById)(ids) as IPurchaseOrder[],
);
export const getFilteredPurchaseOrdersSelector = (filter: EOrderFilter) =>
  createSelector(selectPurchaseOrderIds, purchaseOrdersById, (ids, purchaseOrderById) => {
    const purchaseOrders = getPurchaseOrders(purchaseOrderById)(ids) as IPurchaseOrder[];

    return purchaseOrders.filter(purchaseOrder =>
      purchaseOrder.orders.some(order => orderTabStatuses[filter]?.includes(order.status)),
    );
  });
export const getPurchaseOrdersByPageSelector = (page: number) =>
  createSelector(
    selectPurchaseOrderIds,
    purchaseOrdersById,
    (ids, purchaseOrderById) =>
      getPurchaseOrders(purchaseOrderById)(
        ids?.slice((page - 1) * LIMITS.team, page * LIMITS.team),
      ) as IPurchaseOrder[],
  );

export const loadingPurchaseOrdersSelector = createSelector(enterpriseState, state =>
  getOr(false)(['purchaseOrders', state.purchaseOrdersFilter, 'loading'])(state as any),
);
export const loadedPurchaseOrdersSelector = createSelector(enterpriseState, state =>
  getOr(false)(['purchaseOrders', state.purchaseOrdersFilter, 'loaded'])(state as any),
);
export const hasMorePurchaseOrdersSelector = createSelector(enterpriseState, state =>
  getOr(true)(['purchaseOrders', state.purchaseOrdersFilter, 'hasMore'])(state as any),
);
export const totalPurchaseOrdersSelector = createSelector(
  enterpriseState,
  state => state.purchaseOrders[state.purchaseOrdersFilter]?.countAll || 0,
);

export const hasCurrentPurchaseOrdersSelector = createSelector(purchaseOrdersById, state =>
  Object.values(state).some(purchaseOrder =>
    purchaseOrder.orders.some(order =>
      orderTabStatuses[EOrderFilter.CURRENT_PURCHASE_ORDERS]?.includes(order.status),
    ),
  ),
);

export const hasPastPurchaseOrdersSelector = createSelector(purchaseOrdersById, state =>
  Object.values(state).some(purchaseOrder =>
    purchaseOrder.orders.some(order =>
      orderTabStatuses[EOrderFilter.PAST_PURCHASE_ORDERS]?.includes(order.status),
    ),
  ),
);

export const selectInvoiceIds = createSelector(
  enterpriseState,
  state => state.invoices[state.invoiceFilter]?.ids,
);

export const getInvoicesByPageSelector = (page: number) =>
  createSelector(
    selectInvoiceIds,
    invoicesById,
    (ids, invoicesById) =>
      getInvoices(invoicesById)(
        ids?.slice((page - 1) * LIMITS.team, page * LIMITS.team),
      ) as IInvoice[],
  );

export const loadingInvoicesSelector = createSelector(enterpriseState, state =>
  getOr(false)(['invoices', state.invoiceFilter, 'loading'])(state as any),
);
export const hasMoreInvoicesSelector = createSelector(enterpriseState, state =>
  getOr(true)(['invoices', state.invoiceFilter, 'hasMore'])(state as any),
);
export const totalInvoicesSelector = createSelector(
  enterpriseState,
  state => state.invoices[state.invoiceFilter]?.countAll || 0,
);

/**
 *  Refactored 12.12
 */
export const purchaseOrderSelector = (id: number | string = -1) =>
  createSelector(enterpriseState, ({ purchaseOrdersById }: EnterpriseState) =>
    id in purchaseOrdersById ? purchaseOrdersById[id] : undefined,
  );

/**
 *  Teams
 */
const teamsById = (state: DefaultRootState) => state.enterprise.teamsById;
const getTeams = (teamById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && teamById[id]),
  );
export const selectTeamsIds = createSelector(
  enterpriseState,
  state => state.teams[state.teamsFilter]?.ids,
);
export const getEnterpriseTeamsSelector = createSelector(
  selectTeamsIds,
  teamsById,
  (ids, teamById) => getTeams(teamById)(ids) as ITeam[],
);
export const getCompanyTeamsByPageSelector = (page: number) =>
  createSelector(
    selectTeamsIds,
    teamsById,
    (ids, teamById) =>
      getTeams(teamById)(ids?.slice((page - 1) * LIMITS.team, page * LIMITS.team)) as ITeam[],
  );
export const loadingCompanyTeamsSelector = createSelector(enterpriseState, state =>
  getOr(false)(['teams', state.teamsFilter, 'loading'])(state as any),
);
export const hasMoreCompanyTeamsSelector = createSelector(enterpriseState, state =>
  getOr(true)(['teams', state.teamsFilter, 'hasMore'])(state as any),
);
export const totalCompanyTeamsSelector = createSelector(
  enterpriseState,
  state => state.teams[state.teamsFilter]?.countAll || 0,
);
