import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';

interface IDeleteCurateOrdersProps {
  ids: any[];
}

const deleteCuratedOrder = createAsyncThunk<any, IDeleteCurateOrdersProps>(
  EOrder.deleteCuratedOrderDraft,
  async ({ ids }) => {
    const { data } = await instance.delete(`draft-orders`, { data: { ids } });

    return { ...data, ids };
  },
);

export default deleteCuratedOrder;
