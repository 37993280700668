import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';

export interface ITeamMemberRemoving {
  id: number;
  userId: number;
}

const removeTeamMember = createAsyncThunk<any, ITeamMemberRemoving[]>(
  EEnterprise.removeTeamMember,
  async payload => {
    const { data } = await instance.post(`enterprise/companies/teams/remove-members`, payload);
    return data;
  },
);

export default removeTeamMember;
