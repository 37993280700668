import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { debounce, find } from 'lodash';

import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import { Autocomplete, Box } from '@mui/material';

import { BootstrapInput, RadioGroupInput } from 'components/CustomFields';
import { outputQuery } from 'helpers/decorators';

import { useAppDispatch } from 'store/hooks';
import impersonateUser from 'store/query/user/impersonateUser';
import getCustomerList from 'store/query/user/getCustomerList';
import { LIMITS, getNameByRoleId } from 'models/consts';
import getAgencyList from 'store/query/user/getAgencyList';
import getAgencyTeamRoasterList from 'store/query/user/getAgencyTeamRoasterList';
import getConsultantList from 'store/query/user/getConsultantList';

const Impersonation: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    watch,
    setValue,
  } = useForm({ mode: 'onChange' });
  const { searchType, agencyName, filteredUser, agencyUserName } = watch();
  const [searchValue, setSearchValue] = useState<any[]>([]);
  const [agencyTeamRoaster, setAgencyTeamRoaster] = useState<any[]>([]);

  const filterOptions = outputQuery({
    filterByEmail: undefined,
    filterByStatus: undefined,
    filterByRole: undefined,
    property: undefined,
    type: 'asc',
  });

  useEffect(() => {
    if (agencyName) {
      dispatch(
        getAgencyTeamRoasterList({
          id: agencyName.id,
          filter: filterOptions,
          limit: LIMITS.team,
          offset: 0,
        }),
      ).then((data: any) => {
        setAgencyTeamRoaster(data?.payload?.users || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyName]);

  const handleInputChange = debounce(async inputValue => {
    if (!inputValue) {
      setSearchValue([]);
      return;
    }
    if (searchType === 'consultant') {
      dispatch(
        getConsultantList({
          filter: filterOptions,
          limit: LIMITS.team,
          offset: 0,
          search: inputValue,
        }),
      ).then((data: any) => {
        setSearchValue(data?.payload?.consultants || []);
      });
    } else if (searchType === 'customer') {
      dispatch(
        getCustomerList({
          filter: filterOptions,
          limit: LIMITS.team,
          offset: 0,
          search: inputValue,
        }),
      ).then((data: any) => {
        setSearchValue(data?.payload?.customers || []);
      });
    } else {
      dispatch(
        getAgencyList({
          filter: filterOptions,
          limit: LIMITS.team,
          offset: 0,
          search: inputValue,
        }),
      ).then((data: any) => {
        setSearchValue(data?.payload?.companies || []);
      });
    }
  }, 600);

  const onSubmit: SubmitHandler<any> = async () => {
    if (searchType === 'agency') {
      setValue('agencyUserName', []);
      setAgencyTeamRoaster([]);
      await dispatch(impersonateUser(agencyUserName?.id));
    } else {
      await dispatch(impersonateUser(filteredUser?.id));
    }
  };

  const getRoleName = (user: any) => {
    return (find({ id: user?.role.id })(getNameByRoleId) as any)?.name;
  };

  return (
    <>
      <DialogContent>
        <form id="impersonation" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup sx={{ marginBottom: 0 }}>
            <InputLabel>Search Account Type</InputLabel>
            <Controller
              control={control}
              name="searchType"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <RadioGroupInput
                  ariaLabelledby="search-type"
                  values={['customer', 'consultant', 'agency']}
                  labels={{
                    ['customer']: {
                      subLabel1: 'Customer',
                      subLabel2: `Impersonate as Customer`,
                    },
                    ['consultant']: {
                      subLabel1: 'Specialist',
                      subLabel2: `Impersonate as Specialist`,
                    },
                    ['agency']: {
                      subLabel1: 'Agency',
                      subLabel2: `Impersonate as Agency`,
                    },
                  }}
                  {...field}
                  isHorizontal
                />
              )}
            />
          </FormGroup>
          {searchType === 'customer' || searchType === 'consultant' ? (
            <Box>
              <FormGroup>
                <InputLabel>Enter {searchType} name or email</InputLabel>
                <Controller
                  control={control}
                  name={'filteredUser'}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onInputChange={(_, value) => {
                        handleInputChange(value);
                      }}
                      onChange={(_, newValue) => {
                        field.onChange(newValue);
                      }}
                      options={searchValue}
                      getOptionLabel={option => option.email}
                      renderInput={params => (
                        <BootstrapInput {...params} placeholder="Type to search..." />
                      )}
                    />
                  )}
                  rules={{ required: 'This field is required' }}
                />
              </FormGroup>
            </Box>
          ) : null}

          {searchType === 'agency' && (
            <Box>
              <FormGroup>
                <InputLabel>Enter Agency name</InputLabel>
                <Controller
                  control={control}
                  name="agencyName"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onInputChange={(_, value) => {
                        handleInputChange(value);
                      }}
                      onChange={(_, newValue) => {
                        field.onChange(newValue);
                      }}
                      options={searchValue}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <BootstrapInput {...params} placeholder="Type to search..." />
                      )}
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </FormGroup>
              {agencyName && (
                <FormGroup>
                  <InputLabel>Enter Agency user name or email</InputLabel>
                  <Controller
                    control={control}
                    name="agencyUserName"
                    render={({ field: { ref, ...field } }) => (
                      <Autocomplete
                        {...field}
                        onInputChange={(_, value) => {
                          handleInputChange(value);
                        }}
                        onChange={(_, newValue) => {
                          field.onChange(newValue);
                        }}
                        options={agencyTeamRoaster}
                        getOptionLabel={option => {
                          const role = getNameByRoleId.find(item => item.id === option?.role.id);
                          return role ? `${option.email} - ${role.name}` : option.email;
                        }}
                        renderInput={params => (
                          <BootstrapInput {...params} placeholder="Select an option" />
                        )}
                      />
                    )}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </FormGroup>
              )}
            </Box>
          )}
        </form>
        <DialogActions>
          <LoadingButton
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            color="secondary"
            form="impersonation"
            fullWidth
            type="submit"
            variant="contained"
          >
            Impersonate
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default Impersonation;
