import React, { FunctionComponent } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { BriefcaseIcon } from 'styles/icons/BriefcaseIcon';

import { IProductExperienceFull } from 'store/types/consultant';
import useMaxExperiencePeriod from '../../../hooks/useMaxExperiencePeriod';

interface IExperiencePeriodProps {
  expertiseSummary: IProductExperienceFull[];
}

const ConsultantExperiencePeriod: FunctionComponent<IExperiencePeriodProps> = ({
  expertiseSummary,
}) => {
  const maxExperiencePeriod = useMaxExperiencePeriod(expertiseSummary);

  return (
    <>
      {maxExperiencePeriod && (
        <Box display="flex" alignItems="start" mb={1}>
          <Box sx={{ width: 24, mr: 0.6, display: 'flex' }}>
            <BriefcaseIcon />
          </Box>
          <Typography component="div" alignSelf="end">
            <strong>{maxExperiencePeriod.maxValue}</strong> in {maxExperiencePeriod?.product?.name}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ConsultantExperiencePeriod;
