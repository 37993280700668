import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrder } from 'store/constants';
import { IModuleCreateDto, IWorkCreateDto } from 'models';

export type CreateCuratedOrderDraftProps = {
  description?: string;
  name: string;
  taxes?: number;
  customerEmail?: string;
  customerId?: number;
  levelId?: number;
  timeLine?: {
    allHours?: number;
    startDate?: string;
    endDate?: string;
    availabilityWeek?: number;
    flexibleHours?: boolean;
  };
  expertise?: {
    productId?: number;
    modules?: IModuleCreateDto[];
    works?: IWorkCreateDto[];
  };
};

const createCuratedOrderDraft = createAsyncThunk(
  EOrder.createCuratedOrderDraft,
  async (payload: CreateCuratedOrderDraftProps) => {
    const { data } = await instance.post('draft-orders', payload);

    return data;
  },
);

export default createCuratedOrderDraft;
