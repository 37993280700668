import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';

export interface ITeamMemberAdding {
  id: number;
  userId: number;
}

const addTeamMember = createAsyncThunk<any, ITeamMemberAdding[]>(
  EEnterprise.addTeamMember,
  async payload => {
    const { data } = await instance.post(`enterprise/companies/teams/add-members`, payload);
    return data;
  },
);

export default addTeamMember;
