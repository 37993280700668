import { createAsyncThunk } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { AxiosResponse } from 'axios';

import instance from 'store/middlewares/api';

import { EAgency } from '../../constants';
import { ELocalStoreKeys } from '../../../models/consts';

import fetchMe from '../user/fetchMe';

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });

const impersonateToken = createAsyncThunk<AxiosResponse, number>(
  EAgency.impersonateToken,
  async (id, thunkAPI) => {
    const { data } = await instance.get(`agency/users/user/${id}`);

    cookies.set(ELocalStoreKeys.IMPERSONATE_TOKEN, data.accessToken);
    cookies.set(ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN, data.refreshToken);

    await thunkAPI.dispatch(fetchMe());
    return data;
  },
);

export default impersonateToken;
