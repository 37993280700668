import { logEvent } from '@firebase/analytics';
import { firebaseAnalytics } from '../firebase';

type FBPageViewAnalyticsEvent = {
  page_path: string;
  user_role: string;
  timestamp: string;
  platform: string;
};

/**
 * Logs an event to Firebase Analytics.
 *
 * @param eventName - The name of the event to log
 * @param eventParams - Additional (optional) event params
 * @returns void
 */
const logFirebaseAnalyticsEvent = (eventName: string, eventParams?: any): void => {
  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, eventName, eventParams);
  }
};

/**
 * Logs a page view event to Firebase Analytics
 *
 * @param event
 */
export const logPageViewEvent = (event: FBPageViewAnalyticsEvent): void => {
  logFirebaseAnalyticsEvent('page_view', event);
};
