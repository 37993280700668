import React, { FunctionComponent, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  hasMorePurchaseOrdersSelector,
  getPurchaseOrdersSelector,
  loadingPurchaseOrdersSelector,
  getFilteredPurchaseOrdersSelector,
} from 'store/selectors/getEnterpriseSelector';
import getPurchaseOrders from 'store/query/enterprise/getPurchaseOrders';

import PurchaseItem from 'components/PurchaseListComponents/PurchaseItem';
import Loader from 'components/Loader/Loader';
import EmptyList from 'components/Order/OrderList/EmptyList';
import LazyComponent from '../../../components/LazyComponents';
import { EOrderFilter, orderTabStatuses, TOrderStatus } from '../../../models/consts';

interface IPurchaseListPagination {
  filter: EOrderFilter;
}

const PurchaseListPagination: FunctionComponent<IPurchaseListPagination> = ({ filter }) => {
  const dispatch = useAppDispatch();
  const hasMore = useAppSelector(hasMorePurchaseOrdersSelector);
  const purchaseOrders = useAppSelector(getFilteredPurchaseOrdersSelector(filter));
  const purchaseOrdersLoading = useAppSelector(loadingPurchaseOrdersSelector);

  useEffect(() => {
    dispatch(getPurchaseOrders({ limit: 10, key: 'purchaseList' }));
  }, [dispatch]);

  const loadMorePurchaseOrders = () => {
    if (!purchaseOrdersLoading) {
      dispatch(
        getPurchaseOrders({
          limit: 10,
          offset: purchaseOrders.length,
          key: 'purchaseList',
        }),
      );
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      {!!purchaseOrders.length ? (
        <Box>
          {purchaseOrders.map(
            purchase =>
              !!purchase.orders.length && (
                <LazyComponent key={purchase.id}>
                  <PurchaseItem purchase={purchase} filter={filter} />
                </LazyComponent>
              ),
          )}
          {hasMore && (
            <Box display="flex" width="100%" justifyContent="center" sx={{ py: 2 }}>
              <Button color="secondary" variant="outlined" onClick={loadMorePurchaseOrders}>
                Load more
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <>{!purchaseOrdersLoading ? <EmptyList /> : <Loader />}</>
      )}
    </Box>
  );
};

export default PurchaseListPagination;
