import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';

import config from './app.config';

const firebaseConfigJson = config.FIREBASE_CONFIG;

let firebaseApp: FirebaseApp | null = null;
let firebaseAnalytics: Analytics | null = null;

// Initialize Firebase
if (firebaseConfigJson) {
  try {
    const firebaseConfig = JSON.parse(firebaseConfigJson);

    // Initialize Firebase
    firebaseApp = initializeApp(firebaseConfig);

    // Initialize Analytics and get a reference to the service
    firebaseAnalytics = getAnalytics(firebaseApp);
  } catch (e) {
    console.error('Error initializing Firebase:', e);
  }
}

export {
  firebaseApp, // Firebase app instance
  firebaseAnalytics, // Firebase Analytics service instance
};
