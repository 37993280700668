import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

import { SelectChangeEvent } from '@mui/material/Select';

import { useAppSelector } from 'store/hooks';
import {
  getEnterpriseUsersByPageSelector,
  totalEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';

import TableContainer from 'components/TableCustomized/TableContainer';
import EnhancedTableHeadSort from 'components/TableCustomized/EnhancedTableHeadSort';
import { enterpriseUsersHeadCells, teamUsersHeadCells } from 'components/TableCustomized/constants';
import ManageTableWrapper from 'components/TableCustomized/ManageTableWrapper';
import EnterpriseUsersRow from './EnterpriseUsersRow';
import { IBasicUser } from 'store/types/user';
import { IEnterpriseUser } from 'store/types/enterprise';
import { ERole, LIMITS } from 'models/consts';

interface IManageTable {
  onChangeRole: (event: SelectChangeEvent, id: number) => void;
  onChangeTeam?: (
    event: SelectChangeEvent,
    { userId, oldTeamId }: { userId: number; oldTeamId?: number },
  ) => void;
  onReviewClick?: (row: any) => void;
  onChangeStatus: (row: IEnterpriseUser, status: string) => void;
  onChangePage: (newPage: number, limit: number, offset: number) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  page: number;
}

const ManageTable: FunctionComponent<IManageTable> = ({
  onChangeRole,
  onChangeTeam,
  onReviewClick,
  onChangeStatus,
  onChangePage,
  onRequestSort,
  page,
}) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;
  const users = useAppSelector(getEnterpriseUsersByPageSelector(page + 1));
  const totalUsers = useAppSelector(totalEnterpriseUsersSelector);

  const { watch } = useFormContext();

  return (
    <TableContainer sx={{ mb: 3 }}>
      <EnhancedTableHeadSort
        order={watch('type')}
        orderBy={watch('property')}
        headCells={
          me?.role.name === ERole.CUSTOMER_ADMIN ? enterpriseUsersHeadCells : teamUsersHeadCells
        }
        onRequestSort={onRequestSort}
      />
      <ManageTableWrapper
        page={page}
        total={totalUsers}
        defaultLimit={LIMITS.team}
        count={users.length}
        onPageChange={onChangePage}
        TableContent={
          <>
            {users.map(row => (
              <EnterpriseUsersRow
                key={row.email}
                row={row}
                onChangeStatus={onChangeStatus}
                onChangeRole={onChangeRole}
                onChangeTeam={onChangeTeam}
                onReviewClick={onReviewClick}
              />
            ))}
          </>
        }
      />
    </TableContainer>
  );
};

export default ManageTable;
