import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import { CuratedOrdersState } from 'store/reducers/curated-order';

const GetCuratedOrdersState = (state: DefaultRootState) => state.curatedDraftOrders;

export const curatedOrdersSelector = createSelector(
  GetCuratedOrdersState,
  ({ curatedOrders }: CuratedOrdersState) => curatedOrders.items,
);

export const curatedOrdersLoadingSelector = createSelector(
  GetCuratedOrdersState,
  ({ curatedOrders }: CuratedOrdersState) => curatedOrders.loading,
);

export const fetchCuretedOrderLoadingSelector = createSelector(
  GetCuratedOrdersState,
  ({ curatedOrders }: CuratedOrdersState) => curatedOrders.loading,
);

export const getCuratedOrderSelector = createSelector(
  GetCuratedOrdersState,
  ({ curatedOrders }: CuratedOrdersState) => curatedOrders.items,
);

export const deleteCuratedOrderSelector = createSelector(
  GetCuratedOrdersState,
  ({ curatedOrders }: CuratedOrdersState) => curatedOrders.items,
);
