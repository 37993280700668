import { createSlice } from '@reduxjs/toolkit';

import companyUsers from 'store/query/enterprise/companyUsers';
import changeUserStatus from 'store/query/enterprise/changeUserStatus';
import changeUserRole from 'store/query/enterprise/changeUserRole';
import getPurchaseOrders from 'store/query/enterprise/getPurchaseOrders';
import fetchPurchaseOrder from 'store/query/enterprise/fetchPurchaseOrder';
import inviteUser from 'store/query/enterprise/inviteUser';
import acceptUsersOrders from 'store/query/enterprise/acceptUsersOrders';
import searchUsersTeam from 'store/query/enterprise/searchUsersTeam';
import getInvoices from 'store/query/enterprise/getInvoices';
import companyTeams from 'store/query/enterprise/companyTeams';
import approveAccessRequest from 'store/query/enterprise/approveAccessRequest';
import declineAccessRequest from 'store/query/enterprise/declineAccessRequest';
import removeTeamMember from 'store/query/enterprise/removeTeamMember';
import addTeamMember from 'store/query/enterprise/addTeamMember';

import { IDynamicKey, IEntitiesIds } from 'store/types/common';
import { IEnterpriseUser, IInvoice, IPurchaseOrder } from 'store/types/enterprise';
import { ITeam } from 'store/types/teams';
import { DEFAULT_ENTITY_IDS, LIMITS } from 'models/consts';

const addUsers = (users: IEnterpriseUser[]) => (idToUserMap: IDynamicKey<IEnterpriseUser>) =>
  users.reduce((userMap, user) => {
    userMap[user.id.toString()] = user;
    return userMap;
  }, idToUserMap);

const addTeams = (teams: ITeam[]) => (idToTeamMap: IDynamicKey<ITeam>) =>
  teams.reduce((teamMap, team) => {
    teamMap[team.id.toString()] = team;
    return teamMap;
  }, idToTeamMap);

const addPurchaseOrders =
  (purchaseOrders: IPurchaseOrder[]) => (idToPurchaseOrderMap: IDynamicKey<IPurchaseOrder>) =>
    purchaseOrders.reduce((purchaseOrderMap, purchaseOrder) => {
      if (!purchaseOrderMap[purchaseOrder.id.toString()]) {
        purchaseOrderMap[purchaseOrder.id.toString()] = purchaseOrder;
      }
      return purchaseOrderMap;
    }, idToPurchaseOrderMap);

const addInvoices = (invoices: IInvoice[]) => (idToPurchaseInvoiceMap: IDynamicKey<IInvoice>) =>
  invoices.reduce((invoiceMap, invoice) => {
    if (!invoiceMap[invoice.id.toString()]) {
      invoiceMap[invoice.id.toString()] = invoice;
    }
    return invoiceMap;
  }, idToPurchaseInvoiceMap);

export interface EnterpriseState {
  isFetchingInvite: boolean;
  loading: boolean;
  loaded: boolean;
  purchaseOrders: IDynamicKey<IEntitiesIds>;
  purchaseOrdersById: IDynamicKey<IPurchaseOrder>;
  purchaseOrdersFilter: string;
  searchUsers: IDynamicKey<IEnterpriseUser[]>;
  users: IDynamicKey<IEntitiesIds>;
  usersById: IDynamicKey<IEnterpriseUser>;
  usersFilter: string;
  invoices: IDynamicKey<IEntitiesIds>;
  invoicesById: IDynamicKey<IInvoice>;
  invoiceFilter: string;
  teams: IDynamicKey<IEntitiesIds>;
  teamsById: IDynamicKey<ITeam>;
  teamsFilter: string;
}

const initialState: EnterpriseState = {
  isFetchingInvite: false,
  loading: false,
  loaded: false,
  purchaseOrders: {},
  purchaseOrdersById: {},
  purchaseOrdersFilter: '',
  searchUsers: {},
  users: {},
  usersById: {},
  usersFilter: '',
  invoices: {},
  invoicesById: {},
  invoiceFilter: '',
  teams: {},
  teamsById: {},
  teamsFilter: '',
};

const { reducer } = createSlice({
  name: 'enterprise',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(acceptUsersOrders.pending, state => {
        state.loading = true;
      })
      .addCase(acceptUsersOrders.rejected, state => {
        state.loading = false;
      })
      .addCase(acceptUsersOrders.fulfilled, (state, { payload, meta }) => {
        const [orderId] = meta.arg;
        if (state.purchaseOrdersById[orderId]) {
          state.purchaseOrdersById[orderId].acceptedUsers = payload;
        }
        state.loading = false;
      })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        const { meta } = action;
        meta.arg.forEach(change => {
          const user = state.usersById[change.userId];
          if (user) {
            user.role = {
              ...user.role,
              id: change.roleId,
            };
          }
        });
      })
      .addCase(changeUserStatus.fulfilled, (state, action) => {
        const { meta } = action;

        meta.arg.forEach(change => {
          const user = state.usersById[change.userId];
          if (user) {
            user.isActive = !user.isActive;
          }
        });
      })
      .addCase(getPurchaseOrders.pending, (state, action) => {
        const { meta } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);
        if (state.purchaseOrders[key]) {
          state.purchaseOrders[key].loaded = false;
          state.purchaseOrders[key].loading = true;
        }
        if (!state.purchaseOrders[key]) {
          state.purchaseOrders[key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(getPurchaseOrders.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);

        state.purchaseOrdersFilter = key;
        if (!state.purchaseOrders[key]?.ids.length) {
          state.purchaseOrders[key].ids = Array(payload.count).fill(null);
        }

        if (payload.purchaseOrders.length) {
          state.purchaseOrders[key].ids.splice(
            meta.arg.offset,
            LIMITS.team,
            ...payload.purchaseOrders.map((purchaseOrder: IPurchaseOrder) => purchaseOrder.id),
          );
          addPurchaseOrders(payload.purchaseOrders)(state.purchaseOrdersById);
        }

        state.purchaseOrders[key].page = payload.page;
        state.purchaseOrders[key].countAll = payload.count;
        state.purchaseOrders[key].hasMore = payload.count > meta.arg.limit * payload.page;
        state.purchaseOrders[key].loaded = true;
        state.purchaseOrders[key].loading = false;
      })
      .addCase(getPurchaseOrders.rejected, state => {
        Object.keys(state.purchaseOrders).forEach(key => {
          state.purchaseOrders[key].loaded = true;
          state.purchaseOrders[key].loading = false;
        });
      })
      .addCase(inviteUser.pending, state => {
        state.isFetchingInvite = true;
      })
      .addCase(inviteUser.rejected, state => {
        state.isFetchingInvite = false;
      })
      .addCase(inviteUser.fulfilled, (state, { payload }) => {
        state.isFetchingInvite = false;
        payload.forEach(({ team, company, ...user }) => {
          state.users[state.usersFilter].ids.push(user.id);
          state.users[state.usersFilter].countAll = state.users[state.usersFilter].countAll + 1;
          state.usersById[user.id] = {
            ...user,
            isActive: null,
            // @ts-ignore
            customer: {
              companies: [company],
              teams: [team],
            },
          };
        });
      })
      .addCase(searchUsersTeam.pending, (state, action) => {
        const { meta } = action;

        state.loading = true;
        state.searchUsers[meta.arg.filterByEmail] = [];
      })
      .addCase(searchUsersTeam.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.loading = false;
        if (payload.users) {
          state.searchUsers[meta.arg.filterByEmail].push(...payload.users);
        }
      })
      .addCase(companyUsers.pending, (state, action) => {
        const { meta } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);
        if (state.users[key]) {
          state.users[key].loaded = false;
          state.users[key].loading = true;
        }
        if (!state.users[key]) {
          state.users[key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(companyUsers.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);

        state.usersFilter = key;
        if (!state.users[key]?.ids.length) {
          state.users[key].ids = Array(payload.count).fill(null);
        }

        if (payload.users.length) {
          state.users[key].ids.splice(
            meta.arg.offset,
            LIMITS.team,
            ...payload.users.map((user: IEnterpriseUser) => user.id),
          );
          addUsers(payload.users)(state.usersById);
        }

        state.users[key].page = meta.arg.offset / meta.arg.limit + 1;
        state.users[key].countAll = payload.count;
        state.users[key].hasMore = !!payload.users.length && state.users[key].ids.some(id => !id); //payload.users.length >= (meta.arg?.limit || LIMITS.team);
        state.users[key].loaded = true;
        state.users[key].loading = false;
      })
      // teams
      .addCase(companyTeams.pending, (state, action) => {
        const { meta } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);
        if (state.teams[key]) {
          state.teams[key].loaded = false;
          state.teams[key].loading = true;
        }
        if (!state.teams[key]) {
          state.teams[key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(companyTeams.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);

        state.teamsFilter = key;
        if (!state.teams[key]?.ids.length) {
          state.teams[key].ids = Array(payload.count).fill(null);
        }

        if (payload.teams.length) {
          state.teams[key].ids.splice(
            meta.arg.offset,
            LIMITS.team,
            ...payload.teams.map((team: ITeam) => team.id),
          );
          addTeams(payload.teams)(state.teamsById);
        }

        state.teams[key].page = meta.arg.offset / meta.arg.limit + 1;
        state.teams[key].countAll = payload.count;
        state.teams[key].hasMore = !!payload.teams?.length && state.teams[key].ids.some(id => !id);
        state.teams[key].loaded = true;
        state.teams[key].loading = false;
      })

      .addCase(removeTeamMember.fulfilled, (state, action) => {
        const { meta } = action;
        meta.arg?.forEach(deleteItem => {
          // @ts-ignore
          if (state.usersById[deleteItem.userId]?.customer) {
            state.usersById[deleteItem.userId] = {
              ...state.usersById[deleteItem.userId],
              // @ts-ignore
              customer: {
                // @ts-ignore
                ...state.usersById[deleteItem.userId].customer,
                teams:
                  // @ts-ignore
                  state.usersById[deleteItem.userId]?.customer?.teams?.filter(
                    (teamItem: any) => teamItem.id !== deleteItem.id,
                  ) || [],
              },
            };
          }
        });
      })

      .addCase(addTeamMember.fulfilled, (state, action) => {
        const { meta, payload } = action;
        meta.arg?.forEach(addedItem => {
          state.usersById[addedItem.userId] = {
            ...state.usersById[addedItem.userId],
            // @ts-ignore
            customer: {
              // @ts-ignore
              ...state.usersById[addedItem.userId].customer,
              teams: [...payload],
            },
          };
        });
      })

      .addCase(approveAccessRequest.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.usersById[payload.userId] = {
          ...state.usersById[payload.userId],
          // @ts-ignore
          customer: {
            // @ts-ignore
            ...state.usersById[payload.userId].customer,
            approveAccess: true,
            accessRequests: [],
          },
        };
      })
      .addCase(declineAccessRequest.fulfilled, (state, action) => {
        const { meta, payload } = action;
        if (state.usersById[payload.userId]) {
          delete state.usersById[payload.userId];
        }
        for (const property in state.users) {
          state.users[property].ids = state.users[property].ids.filter(id => id !== payload.userId);
        }
      })
      // teams

      .addCase(getInvoices.pending, (state, action) => {
        const { meta } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);
        if (state.invoices[key]) {
          state.invoices[key].loaded = false;
          state.invoices[key].loading = true;
        }
        if (!state.invoices[key]) {
          state.invoices[key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);

        state.invoiceFilter = key;
        if (!state.invoices[key]?.ids.length) {
          state.invoices[key].ids = Array(payload.count).fill(null);
        }

        if (payload.purchaseOrderInvoices.length) {
          state.invoices[key].ids.splice(
            meta.arg.offset,
            LIMITS.team,
            ...payload.purchaseOrderInvoices.map((invoice: IInvoice) => invoice.id),
          );
          addInvoices(payload.purchaseOrderInvoices)(state.invoicesById);
        }

        state.invoices[key].page = meta.arg.offset / meta.arg.limit + 1;
        state.invoices[key].countAll = payload.count;
        state.invoices[key].hasMore =
          !!payload.purchaseOrderInvoices.length && state.invoices[key].ids.some(id => !id);
        state.invoices[key].loaded = true;
        state.invoices[key].loading = false;
      })

      /**
       *  Refactored 12.12
       */

      /**
       *  get Payment Authorization (Purchase Order) by Id
       */
      .addCase(fetchPurchaseOrder.pending, (state, action) => {
        const { meta } = action;
        state.loading = true;
        state.purchaseOrdersById[meta.arg] = {
          ...state.purchaseOrdersById[meta.arg],
        };
      })
      .addCase(fetchPurchaseOrder.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.purchaseOrdersById[meta.arg] = {
          ...state.purchaseOrdersById[meta.arg],
          ...payload,
        };
        state.loading = false;
      })
      .addCase(fetchPurchaseOrder.rejected, (state, action) => {
        state.loading = false;
      }),
});

export default reducer;
