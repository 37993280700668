/**
 *  Refactor 18-12
 */
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import find from 'lodash/fp/find';
import flowRight from 'lodash/fp/flowRight';
import compact from 'lodash/fp/compact';
import map from 'lodash/fp/map';
import getOr from 'lodash/fp/getOr';

import { IFullOrder, IFullOrders } from '../types/order';

const getOrders = (orderById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && orderById[id].order),
  );

const orderState = (state: DefaultRootState) => state.order;
const ordersById = (state: DefaultRootState) => state.order.ordersById;
const preOrdersById = (state: DefaultRootState) => state.order.preOrdersById;
const customersState = (state: DefaultRootState) => state.order.customersSearch;

/**
 *  Get Order By Id
 */
export const currentOrderSelector = (id: string | number) =>
  createSelector(ordersById, getOr(undefined)([id, 'order']));
export const currentOrderTimelineSelector = (id: string) =>
  createSelector(ordersById, getOr(null)([id, 'order', 'timeLine']));
export const currentOrderLoadedSelector = (id: string) =>
  createSelector(ordersById, getOr(false)([id, 'order', 'loaded']));

/**
 *  Get Order By Hash
 */
export const currentOrderByHashSelector = (hash: string) =>
  createSelector(
    ordersById,
    state =>
      find((el: IFullOrder) => el?.sharedLinks && el?.sharedLinks.items[0]?.hashCode === hash)(
        state,
      )?.order,
  );

/**
 *  Get Order Shared Link
 */
export const orderSharedLinksSelector = (id: string) =>
  createSelector(ordersById, getOr(undefined)([id, 'sharedLinks', 'items']));
export const currentOrderSharedLinkSelector = (id: string | number) =>
  createSelector(ordersById, getOr(undefined)([id, 'sharedLinks', 'items', 0]));

/**
 *  Get Orders List
 */
export const selectOrderIds = (key: string) =>
  createSelector(orderState, getOr([])(['orderList', key, 'ids']));
export const orderListSelector = (key: string) =>
  createSelector(
    selectOrderIds(key),
    ordersById,
    (ids, orderById) => getOrders(orderById)(ids) as IFullOrders[],
  );
export const isOrdersLoadedSelector = (key: string) =>
  createSelector(orderState, getOr(false)(['orderList', key, 'loaded']));
export const isOrdersLoadingSelector = (key: string) =>
  createSelector(orderState, getOr(false)(['orderList', key, 'loading']));
export const hasMoreSelectorSelector = (key: string) =>
  createSelector(orderState, getOr(true)(['orderList', key, 'hasMore']));

/**
 *  Get PreOrders List
 */
export const selectPreOrderIds = (key: string) =>
  createSelector(orderState, getOr([])(['preOrderList', key, 'ids']));
export const preorderListSelector = (key: string) =>
  createSelector(
    selectPreOrderIds(key),
    preOrdersById,
    (ids, preOrderById) => getOrders(preOrderById)(ids) as IFullOrders[],
  );
export const isPreOrdersLoadedSelector = (key: string) =>
  createSelector(orderState, getOr(false)(['preOrderList', key, 'loaded']));
export const isPreOrdersLoadingSelector = (key: string) =>
  createSelector(orderState, getOr(false)(['preOrderList', key, 'loading']));
export const hasMorePreOrderSelector = (key: string) =>
  createSelector(orderState, getOr(true)(['preOrderList', key, 'hasMore']));

/**
 *  Fetch Order Feedback
 */
export const orderFeedbackSelector = (id: string | number) =>
  createSelector(ordersById, getOr(null)([id, 'feedback']));
export const orderFeedbackLoadedSelector = (id: string | number) =>
  createSelector(ordersById, getOr(false)([id, 'feedback', 'loaded']));
export const orderFeedbackLoadingSelector = (id: string | number) =>
  createSelector(ordersById, getOr(false)([id, 'feedback', 'loading']));

/**
 *  Weekly Billings Selector
 */
export const currentWeeklyBillingsOrderSelector = (id: string | number) =>
  createSelector(ordersById, getOr([])([id, 'weeklyBillings', 'items']));
export const currentWeeklyBillingsLoadingSelector = (id: string | number) =>
  createSelector(ordersById, getOr(false)([id, 'weeklyBillings', 'loading']));
export const currentWeeklyBillingsLoadedSelector = (id: string) =>
  createSelector(ordersById, getOr(false)([id, 'weeklyBillings', 'loaded']));
export const weeklyBillingByIndexSelector = (orderId: string, index: number) =>
  createSelector(ordersById, getOr(undefined)([orderId, 'weeklyBillings', 'items', index]));

/**
 *  Get Order Balance
 */
export const currentOrderBalanceSelector = (id: string | number) =>
  createSelector(ordersById, getOr(undefined)([id, 'orderBalance']));

/**
 *  Fetch Order History
 */
export const orderHistorySelector = (id: string) =>
  createSelector(ordersById, getOr([])([id, 'history', 'items']));
export const orderHistoryLoadedSelector = (id: string) =>
  createSelector(ordersById, getOr(false)([id, 'history', 'loaded']));
export const orderHistoryLoadingSelector = (id: string) =>
  createSelector(ordersById, getOr(false)([id, 'history', 'loading']));

/**
 *  Select ExtendHours
 */
export const currentOrderExtendHoursSelector = (id: string | number) =>
  createSelector(ordersById, getOr(undefined)([id, 'extendHours', 'items', 0]));
export const currentOrderExtendHoursLoaderSelector = (id: string | number) =>
  createSelector(ordersById, getOr(false)([id, 'extendHours', 'loading']));

/**
 *  Search Seniority
 */
export const searchedSeniorities = createSelector(
  orderState,
  ({ searchSeniority }) => searchSeniority.items,
);
export const loadingSeniorities = createSelector(
  orderState,
  ({ searchSeniority }) => searchSeniority.loading,
);
export const loadedSeniorities = createSelector(
  orderState,
  ({ searchSeniority }) => searchSeniority.loaded,
);

/**
 *  get Seniority Counts
 */
export const getSenioritiesCount = createSelector(
  orderState,
  ({ getSeniorityCounts }) => getSeniorityCounts.items,
);
export const loadingSenioritiesCount = createSelector(
  orderState,
  ({ getSeniorityCounts }) => getSeniorityCounts.loading,
);
export const loadedSenioritiesCount = createSelector(
  orderState,
  ({ getSeniorityCounts }) => getSeniorityCounts.loaded,
);

/**
 *  Search Customers for Order
 */
export const customersSearchSelector = (key: string) =>
  createSelector(customersState, getOr([])([key, 'items']));
