import React, { FunctionComponent, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import {
  categoryProductsSelector,
  categoryProductsWithoutDeprecatedSelector,
  expertiseLevelsSelector,
  modulesSelector,
  productPricesSelector,
  workTypesSelector,
} from 'store/selectors/getCommonSelector';
import { TIMELINE_HOURS_NEEDED } from 'models/consts';

import { Autocomplete, Box, Grid, InputAdornment, InputLabel, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ProductsModulesWork from 'components/CustomFields/ProductsModulesWork';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import { BootstrapInput } from 'components/CustomFields';
import DelayedPopper from 'components/OrderCreationComponents/Timeline/DelayedPopper';
import { getEndDate, isFriday, isMonday } from 'utils/dateFormatter';
import { handlerTimeLineChange } from 'utils/orderCreationHelpers';
import DateTextInput from 'components/CustomFields/DateTextInput';
import { Select } from 'components/CustomFields';

import CalendarIcon from 'styles/icons/CalendarIcon';
import fetchExpertiseLevels from 'store/query/common/fetchExpertiseLevels';
import CustomTooltip from 'components/CustomTooltip';
import fetchProductPrices from 'store/query/common/fetchProductPrices';
import { TAXES } from 'store/constants';

const CuratedOrdersScope: FunctionComponent<any> = ({ isEdit }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const isMobileNative = Capacitor.isNativePlatform();

  const {
    clearErrors,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();
  const {
    product,
    totalHours,
    searchEndDate,
    searchStartDate,
    scheduleType,
    customerRate,
    specialistRate,
    levelId,
  } = watch();

  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);

  const seniorities = useAppSelector(expertiseLevelsSelector);
  const productPrices = useAppSelector(productPricesSelector(product?.id));

  useEffect(() => {
    if (!categoriesWithProductsList?.length || !modulesList?.length || !worksList.length) {
      dispatch(fetchProductsWithModulesWorks());
    }
    dispatch(fetchExpertiseLevels());
  }, [categoriesWithProductsList, modulesList, worksList, dispatch]);

  useEffect(() => {
    if (product) {
      dispatch(fetchProductPrices(product?.id));
    }
  }, [product, dispatch]);

  useEffect(() => {
    if (isEdit) return;
    if (!productPrices) return;
    const updateRates = (priceData: any) => {
      const coefficient = (100 - TAXES) / 100;
      const specialistPrice = Math.round((priceData.stripePriceCents / 100) * coefficient);

      setValue('customerRate', priceData.stripePriceCents / 100);
      setValue('specialistRate', specialistPrice);
    };
    const defaultPriceData = productPrices.find(price => price.levelId === 2);
    if (defaultPriceData) {
      updateRates(defaultPriceData);
    }
    if (levelId) {
      const levelSpecificPriceData = productPrices.find(price => price.levelId === levelId);
      if (levelSpecificPriceData) {
        updateRates(levelSpecificPriceData);
      }
    }
  }, [productPrices, levelId, setValue, isEdit]);

  useEffect(() => {
    if (isEdit) return;
    if (product) {
      setValue('levelId', 2);
    }
  }, [product, setValue, isEdit]);

  const setDate = (value: Date | null, fieldName: any, field: any) => {
    field?.onChange(value ? dayjs(value || null).format('YYYY-MM-DD') : undefined);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 2, display: 'flex', flexDirection: 'column' }}>
      <Grid width={'100%'} maxWidth={'97%'} ml={2}>
        <ProductsModulesWork />
      </Grid>
      <Grid
        width={'100%'}
        maxWidth={'97%'}
        ml={2}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <Grid
          xs={12}
          md={6}
          lg={4}
          mr={!isiPadOrMobile ? 1 : undefined}
          mt={isiPadOrMobile ? -1 : undefined}
        >
          <InputLabel>Schedule Type</InputLabel>
          <Controller
            control={control}
            name="scheduleType"
            defaultValue={undefined}
            render={({ field }) => (
              <CustomAutocomplete
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                onOpen={() => setValue('scheduleType', '')}
                field={field}
                handleChange={event => setValue('scheduleType', event)}
                // isMainTag
                options={[
                  { id: 1, name: 'Weekly Commitment' },
                  { id: 2, name: 'Number of Hours' },
                ]}
                placeholder="Select Schedule Type"
                // primaryItem={languages}
                // onPrimaryItemChange={(id) => setValue('languages', id)}
              />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={4}
          mr={!isiPadOrMobile ? 1.5 : undefined}
          mt={isiPadOrMobile ? 2 : undefined}
        >
          <InputLabel>Total Hours</InputLabel>
          <Controller
            control={control}
            name="totalHours"
            render={({ field }) => (
              <Autocomplete
                {...field}
                filterOptions={options => options}
                freeSolo
                getOptionLabel={option => String(option)}
                onChange={(_, value) => {
                  field.onChange(parseInt(value));
                  setValue('totalHours', value);
                }}
                options={TIMELINE_HOURS_NEEDED}
                PopperComponent={(isMobileNative && DelayedPopper) || undefined}
                renderInput={({
                  InputLabelProps,
                  inputProps: { onChange, ...inputProps },
                  ...params
                }) => (
                  <BootstrapInput
                    {...params}
                    inputProps={inputProps}
                    label="Select"
                    value={totalHours}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      const isValidInput = /^[1-9]\d*$/.test(value);
                      if ((isValidInput || value === '') && value <= 5000) {
                        field.onChange(parseInt(value));
                        setValue('totalHours', parseInt(value));
                      } else {
                        setError('totalHours', { message: 'Value cannot be more than 5000' });
                      }
                    }}
                    data-test="weeklyHoursField"
                    error={!!errors.allHours}
                    helperText={errors.allHours?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4} mt={isiPadOrMobile ? 2 : undefined}>
          <InputLabel>Seniority Level</InputLabel>
          <Controller
            control={control}
            name="levelId"
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                inputRef={ref}
                label="Select Level"
                options={seniorities || []}
                labelMenuItem="name"
                arrowIconSx={{ right: '10px' }}
                isCuratedOrder={true}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        width={'100%'}
        maxWidth={'97%'}
        ml={2}
        mt={3}
        display={'flex'}
        flexDirection={isiPadOrMobile ? 'column' : 'row'}
      >
        <Grid xs={12} md={6} lg={4} mr={!isiPadOrMobile ? 1 : undefined}>
          <InputLabel>Start date</InputLabel>
          <Controller
            name="searchStartDate"
            control={control}
            rules={{
              validate: {
                beforeEnd: date => {
                  if (
                    !date ||
                    (searchEndDate &&
                      dayjs(date).isSameOrAfter(getEndDate(date, totalHours, 40) || searchEndDate))
                  ) {
                    return 'Incorrect date range';
                  }
                  return undefined;
                },
              },
            }}
            render={({ field }) => (
              <DatePicker
                selected={!!searchStartDate ? dayjs(searchStartDate).toDate() : null}
                onChange={(date: Date) => {
                  if (date) {
                    handlerTimeLineChange(
                      totalHours,
                      40,
                      dayjs(date).format('YYYY-MM-DD'),
                      setValue,
                      clearErrors,
                    );
                  }
                  setDate(date, 'startDate', field);
                }}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => setDate(null, 'startDate', field)}
                    icon={<CalendarIcon />}
                    data-test="startDateField"
                  />
                }
                isClearable
                showPopperArrow={false}
                filterDate={isMonday}
                minDate={dayjs().add(1, 'days').toDate()}
                placeholderText="MM/DD/YYYY"
                withPortal={xs}
                dayClassName={date => (isMonday(date) && 'available-start-date') || null}
                popperPlacement="bottom-start"
              />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={4}
          mt={isiPadOrMobile ? 2 : undefined}
          mr={!isiPadOrMobile ? 1 : undefined}
        >
          <InputLabel>End date</InputLabel>
          <Controller
            name="searchEndDate"
            control={control}
            rules={{
              validate: {
                afterStart: date =>
                  !date ||
                  !searchStartDate ||
                  dayjs(searchStartDate).isSameOrBefore(date) ||
                  'Incorrect date range',
              },
            }}
            render={({ field }) => (
              <DatePicker
                selected={!!searchEndDate ? dayjs(searchEndDate).toDate() : null}
                onChange={(date: Date) => {
                  setDate(date, 'searchStartDate', field);
                  if (searchStartDate && dayjs(searchStartDate).isSameOrBefore(date)) {
                    clearErrors('searchStartDate');
                  }
                  // setValue('availabilityWeek', undefined);
                  // setValue('flexibleHours', true);
                }}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => setDate(null, 'searchStartDate', field)}
                    icon={<CalendarIcon />}
                    data-test="endDateField"
                  />
                }
                showPopperArrow={false}
                minDate={searchStartDate ? dayjs(searchStartDate).toDate() : dayjs().toDate()}
                filterDate={isFriday}
                isClearable
                placeholderText="MM/DD/YYYY"
                withPortal={xs}
                dayClassName={date => (isFriday(date) && 'available-end-date') || null}
                popperPlacement="bottom-start"
              />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={4}
          mt={isiPadOrMobile ? 2 : undefined}
          mr={!isiPadOrMobile ? 1 : undefined}
        >
          <InputLabel>
            Customer Rate
            <CustomTooltip
              placement="bottom"
              title="Updating Product or Expertise level will reset these amounts"
              sx={{ p: '3px', width: '10px', height: '10px', ml: 1 }}
            />
          </InputLabel>
          <Controller
            control={control}
            name="customerRate"
            render={({ field: { ref, ...field } }) => (
              <BootstrapInput
                {...field}
                type="number"
                placeholder="Enter Customer Rate"
                startAdornment={
                  <InputAdornment position="start">
                    <Typography
                      ml={2}
                      color={'secondary'}
                      sx={{ display: 'flex', marginRight: '-20px', marginTop: 0.2 }}
                    >
                      {customerRate ? '$' : undefined}
                    </Typography>
                  </InputAdornment>
                }
                sx={{
                  // width: isiPadOrMobile ? '100%' : '84%',
                  marginLeft: 0.5,
                }}
                onChange={(event: any) => {
                  const value = event.target.value;
                  field.onChange(parseInt(value));
                  setValue('customerRate', parseInt(value));
                }}
                autoComplete="off"
              />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={4}
          mt={isiPadOrMobile ? 2 : undefined}
          mr={!isiPadOrMobile ? 1 : undefined}
        >
          <InputLabel>Specialist Rate</InputLabel>
          <Controller
            control={control}
            name="specialistRate"
            render={({ field: { ref, ...field } }) => (
              <BootstrapInput
                {...field}
                type="number"
                placeholder="Enter Specialist Rate"
                startAdornment={
                  <InputAdornment position="start">
                    <Typography
                      ml={2}
                      color={'secondary'}
                      sx={{ display: 'flex', marginRight: '-20px', marginTop: 0.2 }}
                    >
                      {specialistRate ? '$' : undefined}
                    </Typography>
                  </InputAdornment>
                }
                sx={{
                  // width: isiPadOrMobile ? '100%' : '84%',
                  marginLeft: 0.5,
                }}
                onChange={(event: any) => {
                  const value = event.target.value;
                  field.onChange(parseInt(value));
                  setValue('specialistRate', parseInt(value));
                }}
                autoComplete="off"
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CuratedOrdersScope;
