import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from 'store/middlewares/api';

import { EEnterprise } from 'store/constants/enterprise';

interface IChangeUserRole {
  roleId: number;
  userId: number;
}

const changeUserRole = createAsyncThunk<AxiosResponse, IChangeUserRole[]>(
  EEnterprise.changeUserRole,
  async values => {
    const { data } = await instance.post('/enterprise/companies/users/change-users-role', {
      data: values,
    });

    return data;
  },
);

export default changeUserRole;
