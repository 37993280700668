import React, { FunctionComponent, useState, SyntheticEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import dayjs from 'utils/dayjs';
import DatePicker from 'react-datepicker';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { BootstrapInput, LocationInput, Select } from 'components/CustomFields';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import DelayedPopper from 'components/OrderCreationComponents/Timeline/DelayedPopper';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { expertiseLevelsSelector, languagesSelector } from 'store/selectors/getCommonSelector';
import { getSearchTagsSelector } from 'store/selectors/getTagsSelector';
import { tagsFilter } from 'helpers/tagsFilter';
import searchConsultantTags from 'store/query/tags/searchConsultantTags';
import { TIMELINE_HOURS_NEEDED } from 'models/consts';

import { convertToCamelCase, onTypeaheadItemChange } from 'utils/searchTalent';
import { StyledTalentFilterWrap } from '../styled';
import { getEndDate, isFriday, isMonday } from 'utils/dateFormatter';
import DateTextInput from 'components/CustomFields/DateTextInput';
import { handlerTimeLineChange } from 'utils/orderCreationHelpers';
import CalendarIcon from 'styles/icons/CalendarIcon';
import AvailableFilters from 'views/ChatAssistantPage/AvailableFilters';
import {
  matchedTalentLoadingSelector,
  matchedTalentsSelector,
} from 'store/selectors/getTalentSearchSelector';
import ProductAutoComplete from '../ProductAutoComplete';
import ProductsModulesWorkForTalent from './ProductsModulesWorkForTalent';

interface ITalentSearchProps {
  setOpenFilter?: (open: boolean) => void;
  onSubmit?: () => void;
  onAnonymousMode?: (isAnonymous: boolean) => void;
}

const TalentFilter: FunctionComponent<ITalentSearchProps> = ({
  setOpenFilter,
  onSubmit,
  onAnonymousMode,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  const isMobileNative = Capacitor.isNativePlatform();

  const [tagsText, setTagsText] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const langs = useAppSelector(languagesSelector);
  const seniorities = useAppSelector(expertiseLevelsSelector);
  const options = useAppSelector(getSearchTagsSelector(tagsText));
  const loadingResults = useAppSelector(matchedTalentLoadingSelector);
  const results = useAppSelector(matchedTalentsSelector);

  const {
    clearErrors,
    control,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useFormContext();

  const {
    langIds,
    location,
    weeklyHours,
    searchEndDate,
    searchStartDate,
    product,
    heroSearch,
    tagIds,
    showAgencies,
    scheduleType,
  } = watch();

  const { minumumScore } = getValues();

  useEffect(() => {
    if (loadingResults && results.length === 0) {
      if (isiPadOrMobile) {
        setShowAdvancedFilters(false);
      }
    }
  }, [loadingResults, results, isiPadOrMobile]);

  const debounced = debounce(value => {
    if (value) {
      dispatch(searchConsultantTags(value));
    }
    setTagsText(value);
  }, 500);

  const searchTerm = (value: string) => {
    debounced(value);
  };

  const setDate = (value: Date | null, fieldName: any, field: any) => {
    field?.onChange(value ? dayjs(value || null).format('YYYY-MM-DD') : undefined);
  };

  const handleSlider = (sliderValue: number) => {
    setValue('minumumScore', sliderValue);
  };

  const handleSwitchChange = (event: any) => {
    const newValue = event.target.checked;
    onAnonymousMode?.(newValue);
  };

  const filter = Object.fromEntries(
    Object.entries({
      levelId: getValues()?.levelId,
      searchStartDate: getValues()?.searchStartDate ? getValues()?.searchStartDate : undefined,
      searchEndDate: getValues()?.searchEndDate ? getValues()?.searchEndDate : undefined,
      weeklyHours: getValues()?.weeklyHours || undefined,
      scheduleType: getValues()?.scheduleType?.name,
      works: getValues()?.works?.map((work: any) => work),
      modules: getValues()?.modules?.map((module: any) => module),
      product: getValues()?.product?.name,
      heroSearch: getValues()?.heroSearch ? getValues()?.heroSearch : undefined,
      location: getValues()?.location?.label,
      langIds: getValues()?.langIds?.map((lang: any) => lang),
      tagIds: getValues()?.tagIds.map((tag: any) => tag),
      includeAgency: getValues()?.showAgencies,
      includeIndependent: getValues()?.showContractors,
      //   includeUnvetted: getValues()?.showUnvetted,
      certifiedOnly: getValues()?.showCertifiedOnly,
    }).filter(([_, value]) =>
      Array.isArray(value) ? value.length : value !== undefined && value !== null,
    ),
  );

  const onRemoveFilter = (key: string, value?: any) => {
    if (key && value) {
      const arrayName = getValues()?.[key];
      if (Array.isArray(arrayName)) {
        const newArray = arrayName.filter((obj: any) => obj.id !== value.id);
        setValue(key, newArray);
      } else {
        setValue(key, '');
      }
    } else {
      if (key === 'product') {
        setValue('modules', []);
        setValue('works', []);
        setValue('levelId', '');
      }
      setValue(key, '');
    }
    onSubmit?.();
  };

  return (
    <StyledTalentFilterWrap>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box>
          <Typography variant={mobile ? 'h3' : 'h1'} mt={!mobile ? 3 : 2}>
            Workerbee Directory
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography fontSize={'12px'} fontWeight={500} color={'#929292'}>
            Anonymous{' '}
          </Typography>
          <Switch
            onChange={handleSwitchChange}
            color="default"
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: '#D4D4D4',
              },
              '& .MuiSwitch-track': {
                backgroundColor: '#E7E2E2',
              },
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 3, mb: 2 }}>
        <Grid xs={12} md={12} lg={9}>
          <InputLabel>Search</InputLabel>
          <Controller
            control={control}
            name="heroSearch"
            render={({ field: { ref, ...field } }) => (
              <ProductAutoComplete
                searchValue={field.value}
                setSearchValue={(newValue: any) => {
                  if (typeof newValue === 'string') {
                    setValue('heroSearch', newValue);
                    field.onChange(newValue);
                  } else if (newValue === null && typeof newValue === 'object') {
                    setValue('heroSearch', '');
                    onSubmit?.();
                  } else {
                    onSubmit?.();
                  }
                }}
                onSearch={() => {
                  onSubmit?.();

                  if (isMobileNative) {
                    Keyboard.hide();
                  }
                }}
                onClear={() => {
                  onSubmit?.();
                }}
                placeholderText={`Search by name, email, product, ${mobile ? '' : 'types of work, '}modules, etc.`}
                sx={{ width: '100%' }}
              />
            )}
          />
        </Grid>
        {!isiPadOrMobile && (
          <Grid xs={12} md={6} lg={3}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: '100%', marginTop: 4 }}
              onClick={onSubmit}
            >
              Search
            </Button>
          </Grid>
        )}
      </Grid>

      <AvailableFilters
        filter={filter}
        onFilterRemove={(key: string, value?: any) => onRemoveFilter(key, value)}
      />

      {/* {!isiPadOrMobile && ( */}
      <Grid mt={1} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          sx={{ width: 'auto' }}
        >
          <Typography fontSize={'18px'} fontWeight={500} sx={{ borderBottom: '1px dotted #000' }}>
            {showAdvancedFilters ? 'Hide advanced filters' : 'Show advanced filters'}
          </Typography>
          {showAdvancedFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Grid>

      {/* )} */}
      <Grid
        container
        spacing={2}
        sx={{ mt: 1 }}
        display={!showAdvancedFilters ? 'none' : 'visible'}
      >
        <Grid width={'100%'} mb={-3}>
          <ProductsModulesWorkForTalent />
        </Grid>
        <Grid xs={12} md={6} lg={4}>
          <InputLabel>Schedule Type</InputLabel>
          <Controller
            control={control}
            name="scheduleType"
            defaultValue={undefined}
            render={({ field }) => (
              <CustomAutocomplete
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                onOpen={() => setValue('scheduleType', '')}
                field={field}
                handleChange={event => setValue('scheduleType', event)}
                // isMainTag
                options={[
                  { id: 1, name: 'Weekly Commitment' },
                  { id: 2, name: 'Number of Hours' },
                ]}
                placeholder="Select Schedule Type"
                // primaryItem={languages}
                // onPrimaryItemChange={(id) => setValue('languages', id)}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6} lg={2}>
          <InputLabel>Total Hours</InputLabel>
          <Controller
            control={control}
            name="weeklyHours"
            render={({ field }) => (
              <Autocomplete
                {...field}
                filterOptions={options => options}
                freeSolo
                getOptionLabel={option => String(option)}
                onChange={(_, value) => {
                  field.onChange(parseInt(value));
                  setValue('weeklyHours', value);
                }}
                options={TIMELINE_HOURS_NEEDED}
                PopperComponent={(isMobileNative && DelayedPopper) || undefined}
                renderInput={({
                  InputLabelProps,
                  inputProps: { onChange, ...inputProps },
                  ...params
                }) => (
                  <BootstrapInput
                    {...params}
                    inputProps={inputProps}
                    label="Select"
                    value={weeklyHours}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      const isValidInput = /^[1-9]\d*$/.test(value);
                      if ((isValidInput || value === '') && value <= 5000) {
                        field.onChange(parseInt(value));
                        setValue('weeklyHours', parseInt(value));
                      } else {
                        setError('weeklyHours', { message: 'Value cannot be more than 5000' });
                      }
                    }}
                    data-test="weeklyHoursField"
                    error={!!errors.allHours}
                    helperText={errors.allHours?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <InputLabel>Start date</InputLabel>
          <Controller
            name="searchStartDate"
            control={control}
            rules={{
              // required: 'This field is required',
              validate: {
                beforeEnd: date => {
                  if (
                    !date ||
                    (searchEndDate &&
                      dayjs(date).isSameOrAfter(getEndDate(date, weeklyHours, 40) || searchEndDate))
                  ) {
                    return 'Incorrect date range';
                  }
                  return undefined;
                },
              },
            }}
            render={({ field }) => (
              <DatePicker
                selected={!!searchStartDate ? dayjs(searchStartDate).toDate() : null}
                onChange={(date: Date) => {
                  if (date) {
                    handlerTimeLineChange(
                      weeklyHours,
                      40,
                      dayjs(date).format('YYYY-MM-DD'),
                      setValue,
                      clearErrors,
                    );
                  }
                  setDate(date, 'startDate', field);
                }}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => setDate(null, 'startDate', field)}
                    icon={<CalendarIcon />}
                    data-test="startDateField"
                  />
                }
                isClearable
                showPopperArrow={false}
                filterDate={isMonday}
                minDate={dayjs().add(1, 'days').toDate()}
                placeholderText="MM/DD/YYYY"
                withPortal={xs}
                dayClassName={date => (isMonday(date) && 'available-start-date') || null}
                popperPlacement="bottom-start"
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <InputLabel>End date</InputLabel>
          <Controller
            name="searchEndDate"
            control={control}
            rules={{
              required: 'This field is required',
              validate: {
                afterStart: date =>
                  !date ||
                  !searchStartDate ||
                  dayjs(searchStartDate).isSameOrBefore(date) ||
                  'Incorrect date range',
              },
            }}
            render={({ field }) => (
              <DatePicker
                selected={!!searchEndDate ? dayjs(searchEndDate).toDate() : null}
                onChange={(date: Date) => {
                  setDate(date, 'searchStartDate', field);
                  if (searchStartDate && dayjs(searchStartDate).isSameOrBefore(date)) {
                    clearErrors('searchStartDate');
                  }
                  // setValue('availabilityWeek', undefined);
                  // setValue('flexibleHours', true);
                }}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => setDate(null, 'searchStartDate', field)}
                    icon={<CalendarIcon />}
                    data-test="endDateField"
                  />
                }
                showPopperArrow={false}
                minDate={searchStartDate ? dayjs(searchStartDate).toDate() : dayjs().toDate()}
                filterDate={isFriday}
                isClearable
                placeholderText="MM/DD/YYYY"
                withPortal={xs}
                dayClassName={date => (isFriday(date) && 'available-end-date') || null}
                popperPlacement="bottom-start"
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <InputLabel>Location</InputLabel>
          <Controller
            control={control}
            name="location"
            render={({ field: { ref, value, ...field } }) => (
              <LocationInput
                {...field}
                inputRef={ref}
                value={value}
                onChange={(val: any) => {
                  setValue('location', val);
                  field.onChange(val);
                }}
                arrowIconSx={{ marginRight: '10px' }}
                error={!!errors.location}
                errorText={'This field is required'}
                dataTest="consultant-location"
              />
            )}
            rules={{ required: 'This field is required' }}
          />
          {errors.location && (
            <Typography color="error" variant="caption">
              {errors.location.message}
            </Typography>
          )}
        </Grid>
        <Grid xs={12} md={6} lg={4}>
          <InputLabel>Language</InputLabel>
          <Controller
            control={control}
            name="langIds"
            render={({ field: { ref, ...field } }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                }}
                inputProps={{
                  readOnly: true,
                  error: !!errors.modules,
                  helperText: (errors.modules as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                field={field}
                handleChange={(event, fieldName) =>
                  onTypeaheadItemChange(event, fieldName, setValue)
                }
                isMainTag
                options={convertToCamelCase(langs) || []}
                placeholder="Select Languages"
                primaryItem={langIds}
                onPrimaryItemChange={id => setValue('langIds', id)}
              />
            )}
          />
          {errors.langIds && (
            <Typography color="error" variant="caption">
              {errors.langIds.message}
            </Typography>
          )}
        </Grid>
        {/* <Grid xs={12} md={6} lg={3}>
                    <InputLabel>Matching Score Minimum</InputLabel>
                    <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Controller
                            control={control}
                            name="minumumScore"
                            render={({ field: { ref, ...field } }) => (
                                <CustomSlider sliderPercentage={(value) => handleSlider(value)} />
                            )}
                        />
                        <Grid ml={3} sx={{ backgroundColor: '#F5F5F5', width: '85px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography fontSize={'18px'}>{minumumScore}%</Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
        <Grid xs={12} md={6} lg={4}>
          <InputLabel>Tags</InputLabel>
          <Controller
            control={control}
            name="tagIds"
            defaultValue={undefined}
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                  autoHighlight: true,
                  onInputChange: (event: SyntheticEvent, value: string) => {
                    searchTerm(value);
                    return true;
                  },
                }}
                inputProps={{
                  error: !!errors.tags,
                  helperText: (errors.tags as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': {
                      minWidth: '0px !important',
                      padding: 0,
                    },
                  },
                }}
                isSortable
                field={field}
                options={options}
                placeholder="Start typing to add tags (e.g., 'JIRA', 'Training')..."
                customFilter={tagsFilter}
                freeSolo
              />
            )}
          />
        </Grid>
        <Grid display={'flex'} flexDirection={!mobile ? 'row' : 'column'}>
          <Grid xs={12} mt={mobile ? 1 : undefined}>
            <Controller
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={event => {
                        field.onChange(event.target.checked);
                      }}
                      size="small"
                      checkedIcon={<span />}
                      icon={<span />}
                    />
                  }
                  label={<Typography component="span">Show Agencies</Typography>}
                  sx={{ ml: 0.125 }}
                />
              )}
              name="showAgencies"
            />
          </Grid>
          <Grid xs={12} mt={mobile ? 2 : undefined}>
            <Controller
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={event => {
                        field.onChange(event.target.checked);
                      }}
                      size="small"
                      checkedIcon={<span />}
                      icon={<span />}
                    />
                  }
                  label={<Typography component="span">Show Independent Contractors</Typography>}
                  sx={{ ml: 0.125 }}
                />
              )}
              name="showContractors"
            />
          </Grid>
          {/* <Grid xs={12} mt={mobile ? 2 : undefined}>
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={event => {
                                                field.onChange(event.target.checked);
                                            }}
                                            size="small"
                                            checkedIcon={<span />}
                                            icon={<span />}
                                        />
                                    }
                                    label={<Typography component="span">Show Unvetted</Typography>}
                                    sx={{ ml: 0.125 }}
                                />
                            )}
                            name="showUnvetted"
                        />
                    </Grid> */}
          {product && (
            <Grid xs={12} mt={mobile ? 2 : undefined}>
              <Controller
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={event => {
                          field.onChange(event.target.checked);
                        }}
                        size="small"
                        checkedIcon={<span />}
                        icon={<span />}
                      />
                    }
                    label={<Typography component="span">Show Certified Only</Typography>}
                    sx={{ ml: 0.125 }}
                  />
                )}
                name="showCertifiedOnly"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isiPadOrMobile && showAdvancedFilters && (
        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: '25%', marginTop: 4 }}
            onClick={onSubmit}
          >
            Search
          </Button>
        </Grid>
      )}
    </StyledTalentFilterWrap>
  );
};

export default TalentFilter;
