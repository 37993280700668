import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { EOrder } from 'store/constants';
import { EOrderFilter, ERole } from 'models/consts';
import { IBasicOrders } from '../../types/order';
import { IRole } from '../../types/common';

interface IParams {
  id: number;
  key: string;
  name: string;
  filter: EOrderFilter;
  role: IRole;
  isPurchase: boolean;
}

const orderChangeName = createAsyncThunk<IBasicOrders, IParams>(
  EOrder.changeName,
  async ({ id, name, role, isPurchase }) => {
    let url = 'orders';
    if (
      role.name === ERole.CUSTOMER_ADMIN ||
      role.name === ERole.CUSTOMER ||
      role.name === ERole.CUSTOMER_TEAM_ADMIN
    ) {
      url = isPurchase ? 'enterprise/orders' : 'orders';
    }
    const { data } = await instance.patch(url, [{ id, name }]);

    return { ...data, id, name };
  },
);

export default orderChangeName;
