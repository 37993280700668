import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';

import { EAgency } from '../../constants';

interface IDeleteAgencyUsers {
  userId: number;
}
interface IResponse {
  entityId: number;
}

const deleteAgencyUsers = createAsyncThunk<IResponse[], IDeleteAgencyUsers[]>(
  EAgency.deleteAgencyUsers,
  async values => {
    const { data } = await instance.post('/agency/users/delete-users', { data: values });

    return data;
  },
);

export default deleteAgencyUsers;
