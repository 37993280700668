import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from 'store/middlewares/api';
import { EEnterprise } from 'store/constants/enterprise';

export interface IAccessRequestIds {
  id: number;
}

export interface IApproveAccessRequest {
  accessRequestIds: IAccessRequestIds[];
  userId: number;
}

const approveAccessRequest = createAsyncThunk<any, IApproveAccessRequest>(
  EEnterprise.approveAccessRequest,
  async ({ accessRequestIds, userId }) => {
    const { data } = await instance.post(`access-requests/approve`, accessRequestIds);
    return { data, userId };
  },
);

export default approveAccessRequest;
