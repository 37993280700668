import React, { FunctionComponent, useEffect, useState } from 'react';

import Box from '@mui/material/Box';

const LottiePlayer = React.lazy(() =>
  import('@lottiefiles/react-lottie-player').then(module => ({
    default: module.Player,
  })),
);

const SplashScreenAnimation: FunctionComponent<any> = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Box>
        <LottiePlayer src="/assets/splash.json" className="player" autoplay background="black" />
      </Box>
    </React.Suspense>
  );
};

export default SplashScreenAnimation;
