import React, { FunctionComponent } from 'react';

import Chip from '@mui/material/Chip';
import MuiLink from '@mui/material/Link';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import PauseCircleIcon from '@mui/icons-material/PauseCircleOutline';
import RefreshIcon from '@mui/icons-material/Autorenew';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import { useAppSelector } from 'store/hooks';
import {
  getEnterpriseTeamsSelector,
  getEnterpriseUsersSelector,
  hasMoreEnterpriseUsersSelector,
  loadingEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import {
  getEnterpriseCustomerStatus,
  getEnterpriseCustomerClassName,
} from 'helpers/getEnterpriseCustomerStatus';
import isCustomerInvited from 'helpers/isCustomerInvited';

import BootstrapInput from 'components/CustomFields/BootstrapInput';
import StackItem from 'components/StackList/StackItem';
import StackRow from 'components/StackList/StackRow';
import StyledContainer from 'components/StackList/Container';
import Loader from 'components/Loader/Loader';
import TypographyEmail from 'components/StackList/TypographyEmail';
import { StyledLabel } from './styled';

import { ERole, LIMITS, RolesForTeamManaging } from 'models/consts';
import { IEnterpriseUser } from 'store/types/enterprise';
import { ITeam } from 'store/types/teams';

interface IManageListMobile {
  onChangeRole: (event: SelectChangeEvent, id: number) => void;
  onChangeStatus: (row: IEnterpriseUser, status: string) => void;
  onChangePage: (newPage: number, limit: number, offset: number) => void;
  onChangeTeam?: (
    event: SelectChangeEvent,
    { userId, oldTeamId }: { userId: number; oldTeamId?: number },
  ) => void;
  onReviewClick?: (row: any) => void;
  page: number;
}

const ManageListMobile: FunctionComponent<IManageListMobile> = ({
  onChangeRole,
  onChangeStatus,
  onChangePage,
  onChangeTeam,
  onReviewClick,
  page,
}) => {
  const users = useAppSelector(getEnterpriseUsersSelector);
  const hasMore = useAppSelector(hasMoreEnterpriseUsersSelector);
  const loading = useAppSelector(loadingEnterpriseUsersSelector);
  const teamsList: ITeam[] = useAppSelector(getEnterpriseTeamsSelector);

  const me = useAppSelector(getMeSelector);

  const handleChangePage = () => {
    if (hasMore && users?.length > 0 && !loading) {
      const newPage = page + 1;
      onChangePage(newPage, LIMITS.team, LIMITS.team * newPage);
    }
  };

  const observerTarget = useInfiniteScroll(handleChangePage, { threshold: 1 });

  return (
    <StyledContainer>
      {users.map((row: any) => {
        const isInvited = isCustomerInvited(row);
        return (
          <StackItem key={row.email} spacing={2}>
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Email</StyledLabel>
              <TypographyEmail>{row.email}</TypographyEmail>
            </StackRow>
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Status</StyledLabel>
              <Chip
                className={getEnterpriseCustomerClassName(
                  isInvited,
                  row.isActive,
                  !row.customer?.approveAccess && row.customer?.accessRequests?.length,
                )}
                label={getEnterpriseCustomerStatus(
                  isInvited,
                  row.isActive,
                  !row.customer?.approveAccess && row.customer?.accessRequests?.length,
                )}
                variant="filled"
              />
            </StackRow>
            {me?.role.name === ERole.CUSTOMER_ADMIN && (
              <StackRow direction="row">
                <StyledLabel color="textSecondary">Team</StyledLabel>
                <MuiSelect
                  displayEmpty
                  label="Select team"
                  labelId="select-team"
                  input={<BootstrapInput />}
                  value={(row.customer?.teams?.length && String(row.customer?.teams[0].id)) || ''}
                  sx={{ height: 42, fontSize: '.875rem' }}
                  onChange={event => {
                    onChangeTeam &&
                      onChangeTeam(event, {
                        userId: row.id,
                        oldTeamId: row.customer?.teams?.length
                          ? row.customer.teams[0].id
                          : undefined,
                      });
                  }}
                >
                  {teamsList?.map(({ id, name }: { id: number; name: string }) => (
                    <MenuItem key={name} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </StackRow>
            )}
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Role</StyledLabel>
              {row.role.name === ERole.CUSTOMER_ADMIN ? (
                <Typography>Customer Admin</Typography>
              ) : (
                <MuiSelect
                  displayEmpty
                  label="Select role"
                  labelId="select-role"
                  input={<BootstrapInput />}
                  value={String(row.role.id) || ''}
                  sx={{ textTransform: 'capitalize', height: 42, fontSize: '.875rem' }}
                  onChange={event => {
                    onChangeRole(event, row.id);
                  }}
                  disabled={me.id === row.id || row.isActive === null}
                >
                  {RolesForTeamManaging?.map(({ id, name }: { id: number; name: string }) => (
                    <MenuItem key={name} sx={{ textTransform: 'capitalize' }} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              )}
            </StackRow>
            {me.id !== row.id && (
              <StackRow direction="row">
                <StyledLabel color="textSecondary">Action</StyledLabel>
                {onReviewClick && !!row.customer?.accessRequests?.length && me.id !== row.id && (
                  <MuiLink
                    color="secondary"
                    onClick={() => onReviewClick(row)}
                    sx={{ display: 'inline-flex' }}
                    underline="none"
                  >
                    <PlayArrowOutlinedIcon />
                    <Typography component="span" lineHeight="20px" ml={1}>
                      Review
                    </Typography>
                  </MuiLink>
                )}
                {!row.customer?.accessRequests?.length &&
                  !isInvited &&
                  row.isActive &&
                  me.id !== row.id &&
                  (me.role.name === ERole.CUSTOMER_ADMIN ||
                    (me.role.name === ERole.CUSTOMER_TEAM_ADMIN &&
                      row.role.name !== ERole.CUSTOMER_ADMIN)) && (
                    <MuiLink
                      color="secondary"
                      onClick={() => onChangeStatus(row, 'active')}
                      sx={{ display: 'inline-flex' }}
                      underline="none"
                    >
                      <PauseCircleIcon />
                      <Typography component="span" lineHeight="20px" ml={1}>
                        Pause
                      </Typography>
                    </MuiLink>
                  )}
                {!isInvited &&
                  !row.isActive &&
                  me.id !== row.id &&
                  (me.role.name === ERole.CUSTOMER_ADMIN ||
                    (me.role.name === ERole.CUSTOMER_TEAM_ADMIN &&
                      row.role.name !== ERole.CUSTOMER_ADMIN)) && (
                    <MuiLink
                      color="secondary"
                      onClick={() => onChangeStatus(row, 'deactive')}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        stroke: 'none !important',
                      }}
                      underline="none"
                    >
                      <PowerIcon />
                      <Typography component="span" lineHeight="20px" ml={1}>
                        Activate
                      </Typography>
                    </MuiLink>
                  )}
                {isInvited &&
                  me.id !== row.id &&
                  (me.role.name === ERole.CUSTOMER_ADMIN ||
                    (me.role.name === ERole.CUSTOMER_TEAM_ADMIN &&
                      row.role.name !== ERole.CUSTOMER_ADMIN)) && (
                    <MuiLink
                      color="secondary"
                      onClick={() => onChangeStatus(row, 'refresh')}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        stroke: 'none !important',
                      }}
                      underline="none"
                    >
                      <RefreshIcon />
                      <Typography component="span" lineHeight="normal" ml={1}>
                        Resend
                      </Typography>
                    </MuiLink>
                  )}
              </StackRow>
            )}
          </StackItem>
        );
      })}
      {hasMore && (
        <>
          <Box sx={{ height: 128 }}>
            <Loader />
          </Box>
          <Box sx={{ height: 10 }} ref={observerTarget}></Box>
        </>
      )}
    </StyledContainer>
  );
};

export default ManageListMobile;
