import React, { useCallback, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Button, CircularProgress, Divider, Drawer, Grid, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { categoryProductsWithoutDeprecatedSelector } from 'store/selectors/getCommonSelector';
import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import fetchExpertiseLevels from 'store/query/common/fetchExpertiseLevels';
import getLanguages from 'store/query/common/getLanguages';

import TalentFilter from './TalentFilter';

import { EMatchingResultType } from 'models/consts';
import { getEndDate, getNextMonday } from 'utils/dateFormatter';
import RecommendationCard from 'components/OrderCreationComponents/Recommendation/RecommendationCard';
import { StyledCloseIcon, StyledDrawerBtn } from './styled';
import CloseIcon from 'styles/icons/CloseIcon';
import RecommendationPreviewModal from 'components/OrderCreationComponents/Recommendation/RecommendationPreviewModal';
import useSafeAreaView from 'components/SafeAreaView';
import searchTalent from 'store/query/talent-search/searchTalent';
import {
  getMatchedTalentCardSelector,
  matchedTalentLoadingSelector,
  matchedTalentResultsHasMoreSelector,
  matchedTalentsSelector,
  matchedTotalTalentSelector,
} from 'store/selectors/getTalentSearchSelector';

const TalentSearch = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isiPadOrMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const { safeAreaPadding } = useSafeAreaView();

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [onAnonymousMode, setOnAnonymousMode] = useState(false);

  const [previewCardIndex, setPreviewCardIndex] = useState<{ cardType: string; id: number | null }>(
    { cardType: '', id: null },
  );

  const results = useAppSelector(matchedTalentsSelector);
  const totalResults = useAppSelector(matchedTotalTalentSelector);
  const matchedCard = useAppSelector(getMatchedTalentCardSelector(previewCardIndex));

  const loadingResults = useAppSelector(matchedTalentLoadingSelector);
  const hasMore = useAppSelector(matchedTalentResultsHasMoreSelector);

  useEffect(() => {
    dispatch(fetchExpertiseLevels());
    dispatch(getLanguages());
  }, [dispatch]);

  const methods: any = useForm({
    mode: 'onChange',
    defaultValues: {
      heroSearch: state?.searchString || '',
      nameOrEmail: '',
      location: '',
      langIds: [],
      modules: [],
      works: [],
      product: state?.searchProduct || '',
      tagIds: [],
      levelId: '',
      scheduleType: '',
      weeklyHours: 0,
      searchStartDate: '',
      searchEndDate: '',
      showAgencies: false,
      showContractors: true,
      // showUnvetted: false,
      showCertifiedOnly: false,
    },
  });

  const { watch, getValues, setValue } = methods;

  const toggleDrawer = (open: boolean, selector: { cardType: string; id: number | null }) => {
    setOpen(open);
    setPreviewCardIndex(selector);
  };

  const loadTalentInfo = useCallback(
    (offset: number = 0) => {
      const params: any = {
        offset: offset || 0,
        limit: 10,
        levelId: getValues()?.levelId || undefined,
        startDateOrder: getValues()?.searchStartDate ? getValues()?.searchStartDate : undefined,
        endDateOrder: getValues()?.searchEndDate ? getValues()?.searchEndDate : undefined,
        availabilityWeek: getValues()?.weeklyHours || 10,
        flexible: getValues()?.scheduleType?.id === 1 ? false : true,
        workIds:
          getValues()?.works.length > 0
            ? getValues()?.works?.map((work: any) => work.id)
            : undefined,
        moduleIds:
          getValues()?.modules.length > 0
            ? getValues()?.modules?.map((module: any) => module.id)
            : undefined,
        productId: getValues()?.product?.id || 0,
        search: getValues()?.heroSearch || '',
        location: getValues()?.location?.label || '',
        langIds:
          getValues()?.langIds.length > 0
            ? getValues()?.langIds?.map((lang: any) => lang.id)
            : undefined,
        tagIds:
          getValues()?.tagIds.length > 0
            ? getValues()?.tagIds.map((tag: any) => tag.id)
            : undefined,
        includeAgency: getValues()?.showAgencies,
        includeIndependent: getValues()?.showContractors,
        // includeUnvetted: getValues()?.showUnvetted,
        certifiedOnly: getValues()?.showCertifiedOnly,
      };
      dispatch(
        searchTalent({
          ...(params as any),
        }),
      );
    },
    [dispatch, getValues],
  );

  useEffect(() => {
    loadTalentInfo(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (mobile) {
      setOpenFilter(!openFilter);
    }
    loadTalentInfo(undefined);
  };

  const handleAnonymousMode = (isAnonymous: boolean) => {
    setOnAnonymousMode(isAnonymous);
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <TalentFilter onSubmit={onSubmit} onAnonymousMode={handleAnonymousMode} />
        <Divider />
        {!!results?.length && (
          <Box>
            <Typography variant={!mobile ? 'h6' : 'body1'} mt={2} mb={2}>
              {totalResults} Workerbee Matches:
            </Typography>
            <Box sx={{ paddingBottom: isiPadOrMobile ? (!hasMore ? 10 : undefined) : undefined }}>
              {results.map((card: any, index: number) => (
                <RecommendationCard
                  key={`${card.cardType}-preview-${card.id}-${index}`}
                  matchedCard={card}
                  onOpenPreview={() => {
                    var selection = window.getSelection();
                    if (selection == null || selection.toString().length === 0) {
                      //We'll open the drawer only if the user isn't trying to select text from the card preview
                      toggleDrawer(true, { cardType: card.cardType, id: card.id });
                    }
                  }}
                  onSelectPreview={worker => {}}
                  isAIEnabled
                  isCommunitySearch={true}
                  onAnonymousMode={onAnonymousMode}
                />
              ))}
            </Box>
            {hasMore && (
              <Box display="flex" justifyContent="center" mt={2.5} mb={isiPadOrMobile ? 14 : 4}>
                <LoadingButton
                  loading={loadingResults}
                  color="secondary"
                  onClick={() => loadTalentInfo(results?.length)}
                  variant="outlined"
                >
                  Show me more workerbees
                </LoadingButton>
              </Box>
            )}
          </Box>
        )}
        {loadingResults && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: { xs: '15%', md: '5%' },
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {results.length === 0 && !loadingResults && (
          <Typography mt={8}>
            Use the Search Bar above to find the right expertise you need
          </Typography>
        )}
        {isiPadOrMobile && (
          <Grid
            sx={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              width: '100%',
              height: `${58 + safeAreaPadding.bottom}px`,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: '100%',
                height: '100%',
                paddingBottom: `${safeAreaPadding.bottom || 6}px`,
              }}
              onClick={onSubmit}
            >
              Search
            </Button>
          </Grid>
        )}
        <Drawer
          anchor={(tablet && 'bottom') || 'right'}
          open={open}
          onClose={() => toggleDrawer(false, { cardType: '', id: null })}
          PaperProps={{
            sx: {
              width: { lg: '65%' },
              height: '100%',
              top: 0,
            },
          }}
        >
          {matchedCard && (
            <Box
              sx={{
                p: matchedCard.cardType === EMatchingResultType.INDIVIDUAL ? { xs: 2, md: 5 } : 0,
              }}
              role="presentation"
              mt={safeAreaPadding.top}
            >
              <StyledDrawerBtn>
                <StyledCloseIcon
                  aria-label="close-presentation"
                  onClick={() => toggleDrawer(false, { cardType: '', id: null })}
                  onMouseDown={() => toggleDrawer(false, { cardType: '', id: null })}
                >
                  <CloseIcon />
                </StyledCloseIcon>
                {/* <Button
                                    // onClick={() => hire(matchedCard)}
                                    sx={{ display: { sm: 'none' } }}
                                    variant="contained"
                                    disabled={true}
                                >
                                    Select
                                </Button> */}
              </StyledDrawerBtn>

              <RecommendationPreviewModal
                onSelectPreview={worker => {}}
                matchedCard={matchedCard}
                isSalesOrTalent={true}
              />
            </Box>
          )}
        </Drawer>
      </Box>
    </FormProvider>
  );
};

export default TalentSearch;
