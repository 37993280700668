import { createSlice } from '@reduxjs/toolkit';

import { ERole } from 'models/consts';

import { IDynamicKey } from '../types/common';
import { IExtendUserCustomer, IFullCustomer } from '../types/customer';

import fetchMe from '../query/user/fetchMe';
import addCustomerCompany from '../query/user/addCustomerCompany';
import updateCustomerCompany from '../query/user/updateCustomerCompany';
import getCustomerOrders from '../query/agency/getCustomerOrders';
import setUserFromSSR from '../query/user/setUserFromSSR';

export interface CustomerState {
  customerById: IDynamicKey<IFullCustomer>;
}

const initialState: CustomerState = {
  customerById: {},
};

const addCustomers =
  (customers: IExtendUserCustomer[]) => (customersMap: IDynamicKey<IFullCustomer>) =>
    customers.reduce((orderMap, { customer, ...user }) => {
      if (!orderMap[user.id.toString()]) {
        orderMap[user.id.toString()] = {
          ...orderMap[user.id.toString()],
          customer,
          user,
          loading: false,
          loaded: true,
        };
      }
      return orderMap;
    }, customersMap);

const { actions, reducer } = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      const {
        payload: { customer, ...user },
      } = action;

      if (
        user.role.name === ERole.SELF_CUSTOMER ||
        user.role.name === ERole.CUSTOMER_ADMIN ||
        user.role.name === ERole.CUSTOMER ||
        user.role.name === ERole.CUSTOMER_TEAM_ADMIN
      ) {
        state.customerById[user.id] = {
          ...state.customerById[user.id],
          customer,
          user,
          loading: false,
          loaded: true,
        };
      }
    },
  },
  extraReducers: builder =>
    builder
      /**
       *  Fetch Customer By getMe
       */
      .addCase(fetchMe.fulfilled, (state, action) => {
        const {
          payload: { customer, ...user },
        } = action;

        if (
          user.role.name === ERole.SELF_CUSTOMER ||
          user.role.name === ERole.CUSTOMER_ADMIN ||
          user.role.name === ERole.CUSTOMER ||
          user.role.name === ERole.CUSTOMER_TEAM_ADMIN
        ) {
          state.customerById[user.id] = {
            ...state.customerById[user.id],
            customer,
            user,
            loading: false,
            loaded: true,
          };
        }
      })
      .addCase(setUserFromSSR.fulfilled, (state, action) => {
        const {
          payload: { customer, ...user },
        } = action;

        if (
          user.role.name === ERole.SELF_CUSTOMER ||
          user.role.name === ERole.CUSTOMER_ADMIN ||
          user.role.name === ERole.CUSTOMER ||
          user.role.name === ERole.CUSTOMER_TEAM_ADMIN
        ) {
          state.customerById[user.id] = {
            ...state.customerById[user.id],
            customer,
            user,
            loading: false,
            loaded: true,
          };
        }
      })

      /**
       *  Update Customer company
       */
      .addCase(addCustomerCompany.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.customerById[meta.arg.userId].customer = {
          ...state.customerById[meta.arg.userId].customer,
          companies: payload,
        };
      })
      .addCase(updateCustomerCompany.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.customerById[meta.arg.userId].customer = {
          ...state.customerById[meta.arg.userId].customer,
          companies: payload,
        };
      })

      /**
       *  Customer for Agency
       */
      .addCase(getCustomerOrders.fulfilled, (state, action) => {
        const { payload } = action;
        addCustomers(payload)(state.customerById);
      }),
});

export const { setCustomer } = actions;
export default reducer;
